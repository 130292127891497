import { Component, OnInit, Input, EventEmitter, Output, ChangeDetectorRef } from '@angular/core';
import { Router } from '@angular/router';
import { LayoutService } from 'app/@core/utils';
import * as moment from 'moment';
import { interval, Subscription } from 'rxjs';
import { NotificaitonAlert } from '../notifications-sidenav/notifications.model';
import { TimeAgoPipe } from '../time-ago.pipe';

@Component({
  selector: 'ngx-notification-bar',
  templateUrl: './notification-bar.component.html',
  styleUrls: ['./notification-bar.component.scss']
})
export class NotificationBarComponent implements OnInit {

  @Input() bar: NotificaitonAlert = new NotificaitonAlert();
  @Input() i: number;
  @Input() j: number;
  @Input() showHideIcon: boolean ;
  @Output() closeAlert: EventEmitter<any> = new EventEmitter();
  time: any = '';
  subscription: Subscription;

  constructor(
    private layoutService: LayoutService,
    private timeAgoPipe: TimeAgoPipe,
    private router: Router) {
    }

  ngOnInit(): void {
    this.bar.CreatedDate = this.bar.CreatedDate ? this.bar.CreatedDate.toLocaleString(undefined, {timeZone: "Etc/GMT-1"}) : new Date().toLocaleString(undefined, {timeZone: "Etc/GMT-1"});
    this.checkTime();
    const source = interval(10000);
    this.subscription = source.subscribe(val => this.checkTime());
  }

  checkTime() {
    let dateToUse;
    if (this.bar.fromApi) {
      this.bar.timeAgo = this.timeAgoPipe.transform(this.bar.CreatedDate);
    } else {
      console.log('0. bar - timeAgoIso', this.bar.timeAgoIso);
      if (this.bar.timeAgoIso && moment(this.bar.timeAgoIso, moment.ISO_8601, true).isValid()) {
        dateToUse = moment(this.bar.timeAgoIso);
        console.log('1. bar - dateToUse', dateToUse);
      } else {
        dateToUse = moment();
        console.log('2. bar - dateToUse', dateToUse);
      }
  
      if (!dateToUse.isValid()) {
        this.bar.timeAgo = 'Invalid date';
        console.log('3. bar - timeAgoIso', this.bar.timeAgoIso);
      } else {
        this.bar.timeAgo = dateToUse.fromNow();
        console.log('4. bar - dateToUse', this.bar.timeAgo);
      }
    }
  }

  closeBar() {
    this.closeAlert.emit(
      {  
        eventIndex: this.j,
        groupIndex: this.i
      }
    );
  }

  barBg() {
    switch(this.bar.typeName) {
      case 'Event Processor': {
        return '#ffffff';
      }
      case 'Docusign': {
        return '#3366ff';
      }
      default: {
        return '#ffffff';
      }
    }
  }

  dateColor() {
    switch(this.bar.typeName) {
      case 'Event Processor': {
        return '#808080';
      }
      case 'Docusign': {
        return '#ffffff';
      }
      default: {
        return '#808080';
      }
    }
  }

  iconBg() {
    switch(this.bar.statusName) {
      case 'In Progress': {
         return '#ffffff';
      }
      case 'Completed': {
        return '#62a207';
     }
      case 'Failed': {
        return '#f45d52';
     }
     case 'Sent Failed': {
       return '#f45d52';
     }
      case 'Proposal Fees Missing': {
        return '#f45d52';
     }
   }
  }

  iconColor() {
    switch(this.bar.statusName) {
      case 'In Progress': {
         return '#565656';
      }
      case 'Completed': {
        return '#ffffff';
     }
      case 'Failed': {
        return '#ffffff';
     }
     case 'Sent Failed': {
       return '#ffffff';
     }
     case 'Proposal Fees Missing': {
        return '#ffffff';
     }
   }
  }

  viewSignedDocument(bar: any) {
    this.router.navigate([`pages/${bar.leadTypeName == 'Lead' ? 'company' : 'customer'}/${bar.leadId}/${bar.documentType == 'Proposal' ? 'manage' : 'forms'}`],
                          { queryParams: {rowId: bar.tableRowId}});

    this.layoutService.toggleNotificationSidenav.next();
  }

  updateEmailPassword(bar: any) {
    this.router.navigate([`pages/settings/email`],
                          { queryParams: {rowId: bar.tableRowId}});

    this.closeBar()
    if(bar.hide) {
      this.layoutService.toggleNotificationSidenav.next();
    }
  }

  viewProposal(bar: any) {
    this.router.navigate([`pages/${bar.leadTypeName == 'Lead' ? 'company' : 'customer'}/${bar.leadId}/${bar.documentType == 'Proposal' ? 'manage' : 'forms'}`],
                          { queryParams: {rowId: bar.tableRowId}});

    this.closeBar()
    if(bar.hide) {
      this.layoutService.toggleNotificationSidenav.next();
    }
  }

}
