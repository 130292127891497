import { HttpClient } from '@angular/common/http'
import { Component, OnInit } from '@angular/core'
import { Router } from '@angular/router'
import { BaseComponent } from 'app/@core/base/base.component'
import { EntityType } from 'app/@core/enums/entity-type'
import { TranslateService } from 'app/@core/services'
import { environment } from 'environments/environment'

@Component({
  selector: 'ngx-search',
  templateUrl: './search.component.html',
  styleUrls: ['./search.component.scss'],
})
export class SearchComponent extends BaseComponent implements OnInit {
  resultGroups: any = null
  filter: string
  isLoading: boolean
  isEmpty: boolean

  constructor(private router: Router, private http: HttpClient, translateService: TranslateService) {
    super()
  }

  ngOnInit(): void {}

  search() {
    if ((this.filter || '').length < 3) return

    this.resultGroups == null
    this.isLoading = true
    this.http.get(`${environment.baseApiUrl}/search/${this.filter}`).subscribe((result: any) => {
      this.isEmpty = result.length == 0
      this.resultGroups = result.reduce((acc, x) => {
        let key = this.getTypeFromId(x.Type)
        acc[key] = acc[key] || []
        acc[key].push(x)
        return acc
      }, {})

      this.isLoading = false
    })
  }

  onClick(item) {
    switch (item.Type) {
      case EntityType.Lead:
        this.router.navigateByUrl(`/pages/company/${item.Reference}`)
        break
      case EntityType.Customer:
        this.router.navigateByUrl(`/pages/customer/${item.Reference}`)
        break
    }

    this.reset()
  }

  onOutsideClick(event) {
    this.reset()
  }

  private reset() {
    this.resultGroups = null
    this.filter = null
  }

  private getTypeFromId(id: EntityType) {
    switch (id) {
      case EntityType.Lead:
      case EntityType.UnmappedLead:
        return this.translate('MAINMENU_LEADS')
      case EntityType.Customer:
        return this.translate('MAINMENU_CUSTOMERS')
      default:
        return 'Misc'
    }
  }
}
