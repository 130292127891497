import { NbMenuItem } from "@nebular/theme";

export class UploadedFileObject {
  constructor(
    public Id?: number | null,
    public FileName?: string | null,
    public LeadId?: number| null,
    public Name?: string | null,
    public CreatedDate?: string | Date | null,
    public Description?: string | null,
    public DocumentUrl?: string | null,
    public IsProposalDocument?: boolean,
    public Type?: string | null,
    public UploaderId?: number | null,
    public UploaderName?: string | null,
  ){}
}

export class StorageAreaFileObject extends UploadedFileObject{
  public contextMenu: Array<NbMenuItem> = new Array<NbMenuItem>()
}

export class FileObject {
  constructor(
    public FileName: string | null = null,
    public RawData: Array<number> | null = null,
    public ContentType: string | null = null,
  ){}
}

export class UploadedFileListObject {
  constructor(
    public id: number | null = null,
    public name: string | null = null,
    public url: string | null = null,
    public type: string | null = null,
  ){}
}

export class UploadDocumentObject {
  constructor(
    public id: number | null = 0,
    public LeadId: number | null = 0,
    public Description: string | null = "",
    public Type: string | null = "",
  ) { }
}
