export class Contact {
  constructor(
    public Company ?: string | null,
    public ContactInfo ?: Array<ContactInfro> | null,
    public CreatedDate ?: string | null,
    public CrmId ?: string | null,
    public FirstName ?: string | null,
    public FullName ?: string | null,
    public Id ?: number | null,
    public LastName ?: string | null,
    public LeadId ?: number | null,
    public Position ?: string | null,
    public editMode ?: boolean | null, // for internal use
    public editFirstName ?: string | null, // for internal use
    public editLastName ?: string | null, // for internal use
    public Email ?: string | null,
    public IsSelected ?: boolean | null,
    public IsSigner ?: boolean | null,
    public isCustom ?: boolean | null,
    public PhoneNumber ?: string | null,
  ){}
}

export class contactSaveObj {
  constructor(
    public Id ?: any | null,
    public LeadId ?: number | null,
    public FirstName ?: string | null,
    public LastName ?: string | null,
    public Position ?: string | null,
    public Company ?: string | null,
    public CrmId ?: string | null,
    public Email ?: string | null,
    public IsSelected ?: boolean | null,
    public IsSigner ?: boolean | null,
    public IsCustom ?: boolean | null,
    public PhoneNumber ?: string | null,
    public WorkPhone ?: string | null,
    public ContactInfo ?: Array<ContactInfro> | null,
  ){}
}

export class contactFormFields {
  constructor(
    public Title ?: any | null,
    public Value ?: any | null,
    public editMode ?: boolean | null,
    public isNew ?: boolean | null
  ){}
}

export class contactFormActive {
  constructor(
    public id ?: any | null,
    // public fullName ?: any | null,
    public firstName ?: any | null,
    public lastName ?: any | null,
    public position ?: any | null,
    public email ?: any | null,
    public phone ?: any | null,
    public editfullName ?: any | null,
    public editposition ?: any | null,
    public editemail ?: any | null,
    public editphone ?: any | null,
    public typeContextMenu ?: any | null,
  ){}
}

export class ContactInfro {
  constructor(
    public Title ?: string | null,
    public Type ?: number | null,
    public Uid ?: any | null,
    public Value ?: string | null
  ){}
}
