<nb-card>
    <nb-card-header>Select Properties<nb-icon class="close-icon" icon="close" (click)="closeDialog()"></nb-icon></nb-card-header>
    <nb-card-body [nbSpinner]="isLoading">
        <table class="table" *ngIf="!isLoading">
            <tr>
                <th><input type="checkbox" [(ngModel)]="selectAllChecked" [disabled]="!validateAllRowsSelection()" (change)="selectAll($event)"></th>
                <th *ngFor="let col of fileCoumns; let i = index" [ngClass]="{'d-none' : hideOnCustomer(col)}">
                    <span>{{ col }}</span>
                </th>
            </tr>

            <tr *ngFor="let site of fileDataSites; let i = index">
                <td><input type="checkbox" [(ngModel)]="fileDataSites[i][0].isSelected" (change)="onSelectionChanged(fileDataSites[i][0].isSelected, i)" [disabled]="!validateSingleRowSelection(i)"></td>
                <td *ngFor="let column of site | slice:2; let j = index;"
                [ngClass]="{
                    'editable': true,
                    'invalid-input': !isFieldValid(i, j),
                    'modified': isModified(column, i, j),
                    'd-none' : hideOnCustomer(fileCoumns[j])
                }"
                (click)="onCellEdit($event, fileDataSites[i][j], i, j)"
                [nbTooltip]="getTooltipMessage(fileDataSites[i][j], site, i, j)"
                nbTooltipPlacement="top"
                [nbTooltipDisabled]="fileCoumns[j] !== 'Zip Code' && fileCoumns[j] !== 'Billing Zip Code' && fileCoumns[j] !== 'Account Number'">

                    <span *ngIf="fileDataSites[i][j].edit == false">{{ setPreviewValue(fileDataSites[i][j].value, fileDataSites[i][j].columnName) }}</span>

                    <div *ngIf="fileDataSites[i][j].edit == true">
                        <input
                        #editor
                        id="text"
                        *ngIf="showTextInputField(i, j)"
                        class="selected-input"
                        [(ngModel)]="fileDataSites[i][j].value"
                        (keyup)="isDirty = true"
                        (keypress)="validateInput(fileDataSites[i][j], i, $event, fileDataSites[i])"
                        (paste)="validatePaste(fileDataSites[i][j], $event, site, i)"
                        (input)="checkInputValue(fileDataSites[i][j], $event, site, i)"
                        [attr.maxlength]="fileDataSites[i][j].columnName === 'ZipCode' ? 5 : null"/>

                        <nb-datepicker #dateTimePicker (dateChange)="onDateTimeInputChange($event, i, j)"></nb-datepicker>
                        <input
                        #dateInput
                        id="datepicker"
                        *ngIf="fileDataSites[i][j].Format == 3"
                        type="text"
                        nbInput
                        fieldSize="tiny"
                        [nbDatepicker]="dateTimePicker"
                        [ngModel]="fileDataSites[i][j].value"/>

                        <mat-slide-toggle appearance="outline" (change)="toggleChange($event, i, j)" *ngIf="fileDataSites[i][j].Format == 9" [(ngModel)]="fileDataSites[i][j].value" class="w-100"></mat-slide-toggle>

                        <custom-select (change)="onSelectChange($event, i, j)" *ngIf="fileDataSites[i][j].Format == 5" [(ngModel)]="fileDataSites[i][j].value" [items]="fileDataSites[i][j].additionalParameters" [useItemAsValue]="true" ></custom-select>

                        <numeric-input
                        #editor
                        id="numeric"
                        *ngIf="fileDataSites[i][j].Format == 1"
                        [ngModel]="fileDataSites[i][j].value"
                        (keyPress)="onNumericInputChange($event, fileDataSites[i][j].value, i, j)"
                        (input)="validateNumericInput($event, i, j)"
                        ></numeric-input>

                        <numeric-input
                        #editor
                        id="numericPrefix"
                        *ngIf="fileDataSites[i][j].Format == 2"
                        [ngModel]="fileDataSites[i][j].value"
                        (keyPress)="onNumericInputChange($event, fileDataSites[i][j].value, i, j)"
                        prefix="$"
                      ></numeric-input>
                    </div>
                </td>
            </tr>
        </table>
    </nb-card-body>
    <nb-card-footer>
      <div>
        <button class="cancel" size="tiny" nbButton status="danger" (click)="closeDialog()">Cancel</button>

        <button
        [disabled]="!anySelected || !isBillingZipCodeValid"
        nbButton
        size="tiny"
        status="success"
        (click)="submit()"
        class="ml-1">
        Submit
        </button>
      </div>

    </nb-card-footer>
  </nb-card>
