import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'environments/environment';
import { throwError, Observable } from 'rxjs';
import { map, catchError } from 'rxjs/operators';
import { CreateUserRequestObject, UpdateUserRequestObject, UserResponseObject } from '../models/user.model';

@Injectable({
  providedIn: 'root'
})
export class UserService {
  /**
   * Private variables
   */
  private _apiUrl: string = `${environment.baseApiUrl}/user`

  constructor(private _httpClient: HttpClient) {}

  // -----------------------------------------------------------------------------------------------------
  // @ Private methods
  // -----------------------------------------------------------------------------------------------------

  /**
   * Function to handle error
   * @param error
   * @returns
   */
  private _handleError(error: HttpErrorResponse) {
    if (error.error instanceof ErrorEvent) {
      // A client-side or network error occurred. Handle it accordingly.
      console.error('An error occurred:', error.error.message)
    } else {
      // The backend returned an unsuccessful response code. The response body may contain clues as to what went wrong,
      console.error(`Backend returned code ${error.status}, ` + `body was: ${error.error}`)
    }
    // return an observable with a user-facing error message
    return throwError(error)
  }

  // -----------------------------------------------------------------------------------------------------
  // @ Public methods
  // -----------------------------------------------------------------------------------------------------

  /**
   * Function to get users
   */
  public getUsers(): Observable<Array<UserResponseObject>> {
    return this._httpClient.get<Array<UserResponseObject>>(`${this._apiUrl}/list`).pipe(
      map((data: Array<UserResponseObject>) => data),
      catchError(this._handleError)
    )
  }

  /**
   * Function to create user
   */
  public createUser(request: CreateUserRequestObject): Observable<string> {
    return this._httpClient.post<string>(`${this._apiUrl}`, request).pipe(
      map((data: string) => data),
      catchError(this._handleError)
    )
  }

  /**
   * Function to update user
   */
  public updateUser(request: UpdateUserRequestObject): Observable<string> {
    return this._httpClient.put<string>(`${this._apiUrl}`, request).pipe(
      map((data: string) => data),
      catchError(this._handleError)
    )
  }

  /**
   * Function to delete user
   */
  public deleteUser(userId): Observable<string> {
    return this._httpClient.delete<string>(`${this._apiUrl}/${userId}`).pipe(
      map((data: string) => data),
      catchError(this._handleError)
  )}

    /**
   * Function to get current user
   */
    public getCurrentUser(): Observable<UserResponseObject> {
      return this._httpClient.get<UserResponseObject>(`${this._apiUrl}/current`).pipe(
        map((data: UserResponseObject) => data),
        catchError(this._handleError)
      )
    }

}
