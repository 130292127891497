<nb-card>
  <nb-card-header
    >{{ title }}<nb-icon class="close-icon" icon="close" (click)="waitingForConfirmation ? dialogRef.close() : closeDialog()"></nb-icon>
    <nb-icon
      *ngIf="!readOnly && !waitingForConfirmation && !includeCustomFields"
      class="copy-icon"
      icon="question-mark-circle-outline"
      status="primary"
      nbTooltip="By check marking or selecting a site location below, the selected site location(s) will be included inside the related vendor for pricing and vendor contract. By not selecting a site location below, the unselected site location(s) will not be included the related vendor pricing and vendor contract."
      nbTooltipPlacement="bottom"
      nbTooltipClass="custom-tooltip-class"
    >
    </nb-icon>
    <span *ngIf="isNewProposal && !ifAnySiteIsSelected()" class="ml-2 text-danger">You must select one entry in order to process an estimate request!</span>
    <button
      *ngIf="includeCustomFields"
      nbButton
      size="tiny"
      status="default"
      class="pull-right"
      (click)="toggleCustomFieldVisibility()"
    >
      {{ showCustomFields ? 'Hide' : 'Show' }} {{translate('SITE_SITEDETAILS')}}
    </button>

    <div *ngIf="waitingForConfirmation" class="warning mt-2">
      <nb-icon icon="alert-circle-outline" status="danger"> </nb-icon>
      <strong class="text-danger">All other vendor estimates and signed documents will be deleted for this customer once this specific Order Confirmation has been processed in the system.</strong>
    </div>
  </nb-card-header>
  <nb-card-body [nbSpinner]="loading" nbSpinnerStatus="primary">

    <!-- DEFAULT VIEW -->
    <div *ngIf="!showSitesInTwoTables">
      <!-- SECTION VIEW WHEN CREATING PROPOSAL AND SHOW ALL DETAILS IS TRUE START -->
      <table class="table" #table *ngIf="!hideCell">
        <thead>
          <tr>
            <th *ngIf="!readOnly">
              <input type="checkbox" (change)="selectAll($event)" [(ngModel)]="selectAllChecked" />
            </th>
            <th *ngFor="let column of schemaColumns; index as index" (click)="onFilterClick(index)">
              {{ column.Title }}
            </th>
          </tr>
        </thead>
        <tbody>
          <tr *ngFor="let site of displayedSites">
            <td *ngIf="!readOnly">
              <input type="checkbox" (change)="onSiteSelectionChanged()" [(ngModel)]="site.isSelected" />
            </td>
            <td
              *ngFor="let column of schemaColumns"
              [ngClass]="{
                editable: isEditable(site, column),
                modified: isModified(site, column),
                'under-edit': site.edit === column.PropertyName,
                'invalid-input': ((column.PropertyName === 'AccountNumber' || column.PropertyName === 'ZipCode') && !isValidInputNumber(site, column))}"
              (click)="onCellEdit($event, site, column)"
              [nbTooltip]="getTooltipMessage(column, site)"
              nbTooltipPlacement="right"
              [nbTooltipDisabled]="column.PropertyName !== 'ZipCode' && column.PropertyName !== 'AccountNumber'"
            >
              <span class="displayed-value" *ngIf="site.edit !== column.PropertyName">{{
                formatterService.getColumnValue(site, column)
              }}</span>
              <div
                *ngIf="site.edit === column.PropertyName"
                (clickOutside)="onClickOutsideEditedCell($event, site, column)"
              >
                <input
                  #editor
                  *ngIf="column.Format == 0"
                  class="selected-input"
                  [(ngModel)]="editedValue"
                  (keyup)="isDirty = true"
                  (keypress)="validateInput(column, $event, site)"
                  (paste)="validatePaste(column, $event, site)"
                  (ngModelChange)="onInputChange($event)"
                  (blur)="checkInputValue(column, site)"
                  [attr.maxlength]="column.PropertyName === 'ZipCode' ? 5 : null"
                />
                <numeric-input
                  #editor
                  *ngIf="column.Format == 1"
                  [ngModel]="editedValue"
                  (keyPress)="onNumericInputChange($event)"
                  (input)="validateNumericInput($event, null, null)"
                ></numeric-input>
                <numeric-input
                  #editor
                  *ngIf="column.Format == 2"
                  [ngModel]="editedValue"
                  (keyPress)="onNumericInputChange($event)"
                  prefix="$"
                ></numeric-input>
                <input
                  #editor
                  *ngIf="column.Format == 3"
                  type="text"
                  nbInput
                  fieldSize="tiny"
                  [nbDatepicker]="dateTimePicker"
                  [ngModel]="editedValue"
                />
                <nb-datepicker #dateTimePicker (dateChange)="onDateTimeInputChange($event)"></nb-datepicker>
                <custom-select *ngIf="column.Format == 5" [(ngModel)]="editedValue" [items]="lookupValues" [useItemAsValue]="true" ></custom-select>
              </div>
            </td>
          </tr>
        </tbody>
      </table>
      <!-- SECTION VIEW WHEN CREATING PROPOSAL AND SHOW ALL DETAILS IS TRUE END -->

      <!-- SECTION VIEW WHEN CREATING PROPOSAL AND SHOW ALL DETAILS IS FALSE START -->
      <table class="table" #table *ngIf="hideCell">
        <thead>
          <tr>
            <th *ngIf="!readOnly">
              <input type="checkbox" (change)="selectAll($event)" [(ngModel)]="selectAllChecked" />
            </th>
            <ng-container *ngFor="let column of schemaColumns; let i = index">
              <th (click)="onFilterClick(index)" *ngIf="tableColsVisibility[i + 1]">
                {{ column.Title }}
              </th>
            </ng-container>

          </tr>
        </thead>
        <tbody>
          <tr *ngFor="let site of displayedSites">
            <td *ngIf="!readOnly">
              <input type="checkbox" (change)="onSiteSelectionChanged()" [(ngModel)]="site.isSelected" />
            </td>
            <ng-container *ngFor="let column of schemaColumns; let i = index">
              <td
              *ngIf="formatterService.getColumnValue(site, column)"
              [ngClass]="{
                editable: isEditable(site, column),
                modified: isModified(site, column),
                'under-edit': site.edit === column.PropertyName,
                'invalid-input': ((column.PropertyName === 'AccountNumber' || column.PropertyName === 'ZipCode') && !isValidInputNumber(site, column))}"
              (click)="onCellEdit($event, site, column)"
              [nbTooltip]="getTooltipMessage(column, site)"
              nbTooltipPlacement="right"
              [nbTooltipDisabled]="column.PropertyName !== 'ZipCode' && column.PropertyName !== 'AccountNumber'"
            >
              <span class="displayed-value" *ngIf="site.edit !== column.PropertyName">{{
                formatterService.getColumnValue(site, column)
              }}</span>
              <div
                *ngIf="site.edit === column.PropertyName"
                (clickOutside)="onClickOutsideEditedCell($event, site, column)"
              >
                <input
                  #editor
                  *ngIf="column.Format == 0"
                  class="selected-input"
                  [(ngModel)]="editedValue"
                  (keyup)="isDirty = true"
                  (keypress)="validateInput(column, $event, site)"
                  (paste)="validatePaste(column, $event, site)"
                  (blur)="checkInputValue(column, site)"
                  [attr.maxlength]="column.PropertyName === 'ZipCode' ? 5 : null"
                />
                <numeric-input
                  #editor
                  *ngIf="column.Format == 1"
                  [ngModel]="editedValue"
                  (keyPress)="onNumericInputChange($event)"
                  (input)="validateNumericInput($event, null, null)"
                ></numeric-input>
                <numeric-input
                  #editor
                  *ngIf="column.Format == 2"
                  [ngModel]="editedValue"
                  (keyPress)="onNumericInputChange($event)"
                  prefix="$"
                ></numeric-input>
                <input
                  #editor
                  *ngIf="column.Format == 3"
                  type="text"
                  nbInput
                  fieldSize="tiny"
                  [nbDatepicker]="dateTimePicker"
                  [ngModel]="editedValue"
                />
                <nb-datepicker #dateTimePicker (dateChange)="onDateTimeInputChange($event)"></nb-datepicker>
                <custom-select *ngIf="column.Format == 5" [(ngModel)]="editedValue" [items]="lookupValues" [useItemAsValue]="true" ></custom-select>
              </div>
              </td>
              <td *ngIf="!formatterService.getColumnValue(site, column) && tableColsVisibility[i + 1]">
              </td>
            </ng-container>
          </tr>
        </tbody>
      </table>
      <!-- SECTION VIEW WHEN CREATING PROPOSAL AND SHOW ALL DETAILS IS FALSE END -->
    </div>

    <!-- DEVIED SITES VIEW -->
    <div *ngIf="showSitesInTwoTables && loadDevidedTable">
      <p class="lbl" *ngIf="selectedLeadSites && selectedLeadSites.length > 0">Selected sites</p>
      <table class="table" #table *ngIf="selectedLeadSites && selectedLeadSites.length > 0">
        <thead>
          <tr>
            <th *ngIf="!readOnly">
              <input type="checkbox" (change)="unselectAllSites()" [(ngModel)]="selectedAllDevidedLeadSites" disabled/>
            </th>
            <th *ngFor="let column of schemaColumns; index as index" (click)="onFilterClick(index)">
              {{ column.Title }}
            </th>
          </tr>
        </thead>
        <tbody>
          <tr *ngFor="let site of selectedLeadSites; let i = index">
            <td *ngIf="!readOnly">
              <input type="checkbox" (change)="toggleSiteSelection($event, site.Id)" [(ngModel)]="site.isSelected" disabled/>
            </td>
            <td
              *ngFor="let column of schemaColumns; let j = index"
              [ngClass]="{
                editable: isEditable(site, column),
                modified: isModified(site, column),
                'under-edit': site.edit === column.PropertyName,
                'invalid-input': ((column.PropertyName === 'AccountNumber' || column.PropertyName === 'ZipCode') && !isValidInputNumber(site, column))}"
              (click)="onCellEdit($event, site, column)"
              [nbTooltip]="getTooltipMessage(column, site)"
              nbTooltipPlacement="right"
              [nbTooltipDisabled]="column.PropertyName !== 'ZipCode' && column.PropertyName !== 'AccountNumber'"
            >
              <span class="displayed-value" *ngIf="site.edit !== column.PropertyName">{{
                formatterService.getColumnValue(site, column)
              }}</span>
              <div
                *ngIf="site.edit === column.PropertyName"
                (clickOutside)="onClickOutsideEditedCell($event, site, column)"
              >
                <input
                  #editor
                  *ngIf="column.Format == 0"
                  class="selected-input"
                  [(ngModel)]="editedValue"
                  (keyup)="isDirty = true"
                  (keypress)="validateInput(column, $event, site)"
                  (paste)="validatePaste(column, $event, site)"
                  (ngModelChange)="onInputChange($event)"
                  (blur)="checkInputValue(column, site)"
                  [attr.maxlength]="column.PropertyName === 'ZipCode' ? 5 : null"
                />
                <numeric-input
                  #editor
                  *ngIf="column.Format == 1"
                  [ngModel]="editedValue"
                  (keyPress)="onNumericInputChange($event)"
                  (input)="validateNumericInput($event, i, j)"
                ></numeric-input>
                <numeric-input
                  #editor
                  *ngIf="column.Format == 2"
                  [ngModel]="editedValue"
                  (keyPress)="onNumericInputChange($event)"
                  prefix="$"
                ></numeric-input>
                <input
                  #editor
                  *ngIf="column.Format == 3"
                  type="text"
                  nbInput
                  fieldSize="tiny"
                  [nbDatepicker]="dateTimePicker"
                  [ngModel]="editedValue"
                />
                <nb-datepicker #dateTimePicker (dateChange)="onDateTimeInputChange($event)"></nb-datepicker>
                <custom-select *ngIf="column.Format == 5" [(ngModel)]="editedValue" [items]="lookupValues" [useItemAsValue]="true" ></custom-select>
              </div>
            </td>
          </tr>
        </tbody>
      </table>

      <p class="lbl" *ngIf="unSelectedLeadSites && unSelectedLeadSites.length > 0">Unselected sites</p>
      <table class="table" #table *ngIf="unSelectedLeadSites && unSelectedLeadSites.length > 0">
        <thead>
          <tr>
            <th>
              <input type="checkbox" (change)="selectAllSites()" [(ngModel)]="unselectedAllDevidedLeadSites" disabled/>
            </th>
            <th *ngFor="let column of schemaColumns; index as index">
              {{ column.Title }}
            </th>
          </tr>
        </thead>
        <tbody>
          <tr *ngFor="let site of unSelectedLeadSites">
            <td>
              <input type="checkbox" (change)="toggleSiteSelection($event, site.Id)" [(ngModel)]="site.isSelected" disabled/>
            </td>
            <td
              *ngFor="let column of schemaColumns"
              [ngClass]="{
                editable: isEditable(site, column),
                modified: isModified(site, column),
                'under-edit': site.edit === column.PropertyName,
                'invalid-input': ((column.PropertyName === 'AccountNumber' || column.PropertyName === 'ZipCode') && !isValidInputNumber(site, column))}"
              [nbTooltip]="getTooltipMessage(column, site)"
              nbTooltipPlacement="right"
              [nbTooltipDisabled]="column.PropertyName !== 'ZipCode' && column.PropertyName !== 'AccountNumber'"
            >
              <span class="displayed-value" *ngIf="site.edit !== column.PropertyName">{{
                formatterService.getColumnValue(site, column)
              }}</span>
            </td>
          </tr>
        </tbody>
      </table>
    </div>

    <!-- SECTION VIEW WHEN CREATING PROPOSAL START -->
    <div *ngIf="!readOnly && !waitingForConfirmation && !showSitesInTwoTables" class="field annual-usage">
      <label *ngIf="!openedFromProposal">Effective Annual Usage
        <nb-icon
          class="copy-icon"
          icon="question-mark-circle-outline"
          status="primary"
          nbTooltip="You can select this toggle switch on to override and input the actual annual usage value for the listed property sites."
          nbTooltipPlacement="bottom"
        >
        </nb-icon>
      </label>
      <div class="override-container w-100">
        <nb-toggle *ngIf="!openedFromProposal" labelPosition="right" style="width: auto !important;" [(checked)]="isAnnualUsageOverriden" (checkedChange)="handleToggleEvent($event)">Override</nb-toggle>
        <div class="d-flex flex-column ml-4">
          <span *ngIf="!isLoading" class="">Current Total Annual Usage: {{ pipeTotalAnnualUsage() }}</span>
          <div *ngIf="isAnnualUsageOverriden && !openedFromProposal" class="d-flex align-items-end">
            <numeric-input [(ngModel)]="overridenAnnualUsage" (input)="validateInput($event)"></numeric-input>
            <button
              [disabled]="updLeadAnnUsageBtnSpinner"
              nbButton
              size="tiny"
              status="warning"
              class="pull-right ml-1 mt-2"
              (click)="updateLeadAnnualUsage()"
              [nbSpinner]="updLeadAnnUsageBtnSpinner"
            >
              Update Annual Usage
            </button>
          </div>
        </div>
      </div>
    </div>
    <!-- SECTION VIEW WHEN CREATING PROPOSAL END -->

    <!-- SECTION VIEW WHEN CONFIRMING PROPOSAL START -->
    <div *ngIf="waitingForConfirmation && !showSitesInTwoTables" class="field">
      <label style="flex: 0 0 50%"
        >Please enter the actual annual usage reported by the vendor, if different:
        <nb-icon
          class="copy-icon"
          icon="question-mark-circle-outline"
          status="primary"
          nbTooltip="You can select this toggle switch on to override and input the actual annual usage value for this proposal."
          nbTooltipPlacement="bottom"
        >
        </nb-icon>
      </label>
      <div class="override-container w-100">
        <nb-toggle labelPosition="right" style="width: auto !important;" [(checked)]="_shouldOverrideCheck">Override</nb-toggle>
        <div class="d-flex flex-column ml-4">
          <span *ngIf="!isLoading" class="">Current Total Annual Usage: {{ proposal.AnnualUsage }}</span>
          <div *ngIf="isAnnualUsageOverriden && proposal.Status != proposalStatuses.WaitingForConfirmation" class="d-flex align-items-end">
            <numeric-input [(ngModel)]="proposal.AnnualUsage" (input)="validateInput($event)"></numeric-input>
            <button
              [disabled]="updLeadAnnUsageBtnSpinner"
              nbButton
              size="tiny"
              status="warning"
              class="pull-right ml-1 mt-2"
              (click)="updateLeadAnnualUsage()"
              [nbSpinner]="updLeadAnnUsageBtnSpinner"
            >
              Update Annual Usage
            </button>
          </div>
        </div>
      </div>
    </div>

    <div *ngIf="showSitesInTwoTables && selectedLeadSites.length > 1" class="field">
      <label style="flex: 0 0 50%"
        >Please enter the actual annual usage reported by the vendor, if different:
        <nb-icon
          class="copy-icon"
          icon="question-mark-circle-outline"
          status="primary"
          nbTooltip="You can select this toggle switch on to override and input the actual annual usage value for this proposal."
          nbTooltipPlacement="bottom"
        >
        </nb-icon>
      </label>
      <div class="override-container w-100">
        <nb-toggle labelPosition="right" style="width: auto !important;" [(checked)]="isAnnualUsageOverriden" (checkedChange)="handleToggleEvent($event)">Override</nb-toggle>
        <div class="d-flex flex-column ml-4">
          <span *ngIf="!isLoading" class="">Current Total Annual Usage: {{ proposal.AnnualUsage }}</span>
          <div *ngIf="isAnnualUsageOverriden" class="d-flex align-items-end">
            <numeric-input [(ngModel)]="proposal.AnnualUsage" (input)="validateInput($event)"></numeric-input>
            <button
              [disabled]="updLeadAnnUsageBtnSpinner"
              nbButton
              size="tiny"
              status="warning"
              class="pull-right ml-1 mt-2"
              (click)="updateLeadAnnualUsage()"
              [nbSpinner]="updLeadAnnUsageBtnSpinner"
            >
              Update Annual Usage
            </button>
          </div>
        </div>
      </div>
    </div>
    <!-- SECTION VIEW WHEN CONFIRMING PROPOSAL END -->

  </nb-card-body>
  <nb-card-footer *ngIf="readOnly && !showSitesInTwoTables">
    <button class="cancel" size="tiny" nbButton status="success" (click)="dialogRef.close()">Ok</button>
  </nb-card-footer>

  <nb-card-footer *ngIf="!readOnly && !showSitesInTwoTables">
    <div>
      <button nbButton size="tiny" status="success" class="pull-right" (click)="submit(true)" [nbSpinner]="updateSpinner" [disabled]="updateSpinner || !ifAnySiteIsSelected()">Update</button>
      <button class="pull-right" size="tiny" nbButton status="danger" (click)="waitingForConfirmation ? dialogRef.close() : closeDialog()">Cancel</button>
    </div>
  </nb-card-footer>

  <nb-card-footer *ngIf="showSitesInTwoTables">
    <button class="cancel" size="tiny" nbButton status="success" (click)="updateLeadAnnualUsage()" *ngIf="selectedLeadSites && selectedLeadSites.length == 1">Update and confirm</button>
  </nb-card-footer>

</nb-card>
