<div class="spacing">
    <div class="p-relative" [nbSpinner]="isLoading">
        <nb-icon icon="more-horizontal-outline" class="menu-icon" [matMenuTriggerFor]="menu"></nb-icon>
        <mat-menu #menu="matMenu">
            <button mat-menu-item (click)="markAsReadAll()" *ngIf="(notGroups && notGroups.length > 0) && atLeastOneUnread">
                <mat-icon>checkmark-outline</mat-icon>
                <span>Mark all as read</span>
            </button>
            <button mat-menu-item (click)="markAsUnreadAll()" *ngIf="(notGroups && notGroups.length > 0) && !atLeastOneUnread">
              <mat-icon>checkmark-outline</mat-icon>
              <span>Mark all as unread</span>
          </button>
            <button mat-menu-item (click)="notificationSettings()">
                <mat-icon>settings-outline</mat-icon>
                <span>Notification Settings</span>
            </button>
        </mat-menu>

        <h5>Notifications</h5>
    </div>

    <div class="d-flex justify-content-start mb-2">
        <button nbButton size="tiny" class="mr-15" [status]="activeView === 'All' ? 'info' : null" (click)="seeAll()">All</button>
        <button nbButton size="tiny" [status]="activeView === 'Unread' ? 'info' : null" (click)="seeUnread()">Unread</button>
    </div>

    <div class="notificaiton-content" *ngIf="activeView == 'All'">
        <p class="no-data-msg" *ngIf="!isLoading && (!notGroups || notGroups.length < 1)">There are no new notifications</p>

        <div *ngFor="let notGroup of notGroups; let i = index" class="p-relative">
          <div [ngClass]="notGroup.isRead ? 'read-signal' : 'unread-signal'" class="not-signal"></div>
            <div>
                <nb-icon icon="more-horizontal-outline" class="menu-icon" [matMenuTriggerFor]="menuNotification"></nb-icon>
                <mat-menu #menuNotification="matMenu">
                    <button mat-menu-item (click)="markAsRead(notGroup, i, 'all')" *ngIf="!notGroup.isRead">
                        <mat-icon>checkmark-outline</mat-icon>
                        <span>Mark as read</span>
                    </button>
                    <button mat-menu-item (click)="markAsUnread(notGroup, i, 'all')" *ngIf="notGroup.isRead">
                      <mat-icon>mark_as_unread</mat-icon>
                      <span>Mark as unread</span>
                    </button>
                </mat-menu>
            </div>

            <div class="actions">
              <p class="group-title">{{notGroup.title}}</p>
            </div>
      
            <hr class="mt-5">
      
            <div class="not-bars">
              <div *ngFor="let bar of notGroup.events; let j = index" class="p-relative">
                <div class="not-bar" [style.background]="bar.typeName == 'Docusign' ? '#3366ff' : '#ffffff'">
                  <ngx-notification-bar [bar]="bar" [i]="i" [j]="j" [isAlert]="false" [showHideIcon]="false" (closeAlert)="closeAlert($event)"></ngx-notification-bar>
                </div>
              </div>
            </div>
         </div>
    </div>

    <div class="notificaiton-content" *ngIf="activeView == 'Unread'">
      <p class="no-data-msg" *ngIf="!isLoading && (!notGroupsRead || notGroupsRead.length < 1)">There are no new notifications</p>
        <div *ngFor="let notGroup of notGroupsRead; let i = index" class="p-relative">
          <div [ngClass]="notGroup.isRead ? 'read-signal' : 'unread-signal'" class="not-signal"></div>
            <div>
                <nb-icon icon="more-horizontal-outline" class="menu-icon" [matMenuTriggerFor]="menuNotification"></nb-icon>
                <mat-menu #menuNotification="matMenu">
                    <button mat-menu-item (click)="markAsRead(notGroup, i, 'unread')" *ngIf="!notGroup.isRead">
                        <mat-icon>checkmark-outline</mat-icon>
                        <span>Mark as read</span>
                    </button>
                    <button mat-menu-item (click)="markAsUnread(notGroup, i, 'unread')" *ngIf="notGroup.isRead">
                      <mat-icon>mark_as_unread</mat-icon>
                      <span>Mark as unread</span>
                    </button>
                </mat-menu>
            </div>

            <div class="actions">
              <p class="group-title">{{notGroup.title}}</p>
            </div>
      
            <hr class="mt-5">
      
            <div class="not-bars">
              <div *ngFor="let bar of notGroup.events; let j = index" class="p-relative">
                <div class="not-bar" [style.background]="bar.typeName == 'Docusign' ? '#3366ff' : '#ffffff'">
                  <ngx-notification-bar [bar]="bar" [i]="i" [j]="j" [isAlert]="false" [showHideIcon]="false" (closeAlert)="closeAlert($event)"></ngx-notification-bar>
                </div>
              </div>
            </div>
         </div>
    </div>

    <button nbButton size="tiny" status="primary" (click)="loadMore()" *ngIf="showLoadMore" class="load-more">Load more</button>

</div>
