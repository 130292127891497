import { HttpClient } from '@angular/common/http';
import { Component, Input, OnInit } from '@angular/core'
import { UntypedFormBuilder, UntypedFormGroup, Validators, FormControl } from '@angular/forms'
import { NbDialogRef, NbToastrService } from '@nebular/theme'
import { v4 } from 'uuid'
import { contactSaveObj } from '../../manage/customer-view-settings-form/customer-settings.model';
import { LeadService } from 'app/@core/services/lead.service';
import { BaseComponent } from 'app/@core/base/base.component';
import { TranslateService } from 'app/@core/services';
import { take } from 'rxjs/operators';
import { WhiteSpaceValidatorServiceService } from 'app/@core/utils/white-space-validator-service.service';
import { EmailValidationPipe } from 'app/pages/shared/custom-pipes/email-validation.pipe';

@Component({
  selector: 'ngx-custom-recipient-editor',
  templateUrl: './custom-recipient-editor.component.html',
  styleUrls: ['./custom-recipient-editor.component.scss'],
})
export class CustomRecipientEditorComponent extends BaseComponent implements OnInit {
  @Input() model

  recipientForm: UntypedFormGroup;
  isLoading: boolean = false;

  constructor(private _formBuilder: UntypedFormBuilder,
              private http: HttpClient,
              public dialogRef: NbDialogRef<CustomRecipientEditorComponent>,
              private toastrService: NbToastrService,
              private _leadService: LeadService,
              translateService: TranslateService,
              private emailValidationPipe: EmailValidationPipe,
              private _whiteSpaceValidatorServiceService: WhiteSpaceValidatorServiceService
              ) {
                super()
              }

  ngOnInit() {
    this._initForm();
  }

  private _initForm() {
    this.recipientForm = this._formBuilder.group({
      firstName: [this.model.FirstName, [Validators.required, this._whiteSpaceValidatorServiceService.noWhitespaceValidator()]],
      lastName: [this.model.LastName, [Validators.required, this._whiteSpaceValidatorServiceService.noWhitespaceValidator()]],
      email: [this.model.Email, [Validators.required, Validators.email]],
      phoneNumber: [this.model.PhoneNumber,[this.phoneValidator]],
      position: [this.model.Position]
    })
  }

  emailInputChanged() {
    let val = this.recipientForm.get('email').value;
    this.recipientForm.get('email').setErrors(
      val && this.emailValidationPipe.transform()(new FormControl(val)) ? { invalidFormat: true } : null);
  }

  /**
   * Function to validate phone number
   */

  private phoneValidator(control) {
    if(control.value && String(control.value).replace(/\D/g, '').length !== 10 ){
      control.setErrors(null);
      return { numericLength: true};
    }
    return null;
  }

  submit() {
    this.isLoading = true;
    let newContact: contactSaveObj = {
      Id: this.model.Id,
      LeadId: this.model.LeadId,
      FirstName: this.recipientForm.get('firstName').value,
      LastName: this.recipientForm.get('lastName').value,
      Email: this.recipientForm.get('email').value,
      PhoneNumber: this.recipientForm.get('phoneNumber').value,
      Position: this.recipientForm.get('position').value,
      IsSelected: this.model.IsSelected,
      IsSigner: this.model.IsSigner,
      IsCustom: this.model.IsCustom,
      ContactInfo: [
        {
          Type: 1,
          Value: this.recipientForm.get('email').value,
          Uid: v4()
        },
        {
          Type: 3,
          Value: this.recipientForm.get('phoneNumber').value,
          Uid: v4()
        }
      ]
    };

    this._leadService.createRecipient(this.model.LeadId, newContact).pipe(take(1)).subscribe({
      next: (data: contactSaveObj) => {
        this.toastrService.success("Contact successfully saved");
        this.dialogRef.close(true)
        this.isLoading = false
      },
      error: (error) => {
        this.toastrService.danger(error.error.Content)
        this.isLoading = false
      }
      }
    )
  }
}

