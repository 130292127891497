


<div class="sidenav">
    <div class="sidenav-overlay" [ngClass]="{'overlay-bg' : trigger === 'in'}" (click)="closeSidenav()">
    </div>
    <div class="sidenav-content" [ngClass]="{'content-bg' : trigger === 'in'}">
      <nb-icon status="default" icon="close" class="close" (click)="closeSidenav()"></nb-icon>
  
      <p class="title">Contacts</p>
  
      <hr>
  
      <div *ngFor="let contact of contactList" class="list-item">
        <p class="mb-5"><b>Name: </b> {{contact.firstName}} {{contact.lastName}}</p>
        <p class="mb-5"><b>Email: </b> {{contact.email}}</p>
        <p class="mb-5"><b>Phone: </b> {{contact.phone}}</p>
        </div>
      </div>
</div>
  
  