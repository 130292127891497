import { Component, Input, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators, FormControl } from '@angular/forms';
import { NbDialogRef } from '@nebular/theme';
import { WhiteSpaceValidatorServiceService } from 'app/@core/utils/white-space-validator-service.service';
import { ReminderReceiverObject } from 'app/@core/models/reminder.model';
import { EmailValidationPipe } from '../../custom-pipes/email-validation.pipe';

@Component({
  selector: 'ngx-recipient-form',
  templateUrl: './recipient-form.component.html',
  styleUrls: ['./recipient-form.component.scss']
})
export class RecipientFormComponent implements OnInit {

  @Input() recipient: ReminderReceiverObject;

  /**
   * Public variables
   */
  isLoading: boolean = false;
  recipientForm: FormGroup;

  constructor(public dialogRef: NbDialogRef<RecipientFormComponent>,
              private _formBuilder: FormBuilder,
              private emailValidationPipe: EmailValidationPipe,
              private _whiteSpaceValidatorServiceService: WhiteSpaceValidatorServiceService) {}

  ngOnInit(): void {
    this._initForm();
  }

  /**
   * Function to init form
   */
  private _initForm(): void {
    this.recipientForm = this._formBuilder.group({
      firstName: [this.recipient.FirstName, [Validators.required, this._whiteSpaceValidatorServiceService.noWhitespaceValidator()]],
      lastName: [this.recipient.LastName, [Validators.required, this._whiteSpaceValidatorServiceService.noWhitespaceValidator()]],
      email: [this.recipient.Email, [Validators.required, Validators.email]],
      phoneNumber: [this.recipient.PhoneNumber,[this.phoneValidator]],
      position: [this.recipient.Position]
    })
  }

  emailInputChanged() {
    let val = this.recipientForm.get('email').value;
    this.recipientForm.get('email').setErrors(
      val && this.emailValidationPipe.transform()(new FormControl(val)) ? { invalidFormat: true } : null);
  }

  /**
   * Function to validate phone number
   */
  private phoneValidator(control) {
    if(control.value && String(control.value).replace(/\D/g, '').length !== 10 ){
      control.setErrors(null);
      return { numericLength: true};
    }
    return null;
  }

  /**
   * Function to submit form
   */
  submit(): void {
    this.recipient.FirstName = this.recipientForm.get('firstName').value;
    this.recipient.LastName = this.recipientForm.get('lastName').value;
    this.recipient.Email = this.recipientForm.get('email').value;
    this.recipient.PhoneNumber = this.recipientForm.get('phoneNumber').value;
    this.recipient.Position = this.recipientForm.get('position').value;

    this.dialogRef.close(this.recipient);
  }
}
