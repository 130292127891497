import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'phone'
})
export class PhonePipe implements PipeTransform {

  transform(rawNum: any) {
    rawNum = rawNum ? rawNum.replace(/[^a-zA-Z0-9]/g, '') : 0;

    let areaCodeStr;
    let midSectionStr;
    let lastSectionStr;

    if (rawNum && rawNum.length < 3) {
      return ``;
    } else
    if (rawNum.length == 3) {
      areaCodeStr = rawNum.slice(0,3);
      return `(${areaCodeStr})`
    }
    if (rawNum.length > 3 && rawNum.length < 7) {
      areaCodeStr = rawNum.slice(0,3);
      midSectionStr = rawNum.slice(3,6);
      return `(${areaCodeStr}) ${midSectionStr}`;
    } else
    if (rawNum.length >= 7) {
      areaCodeStr = rawNum.slice(0,3);
      midSectionStr = rawNum.slice(3,6);
      lastSectionStr = rawNum.slice(6,10);
      return `(${areaCodeStr}) ${midSectionStr}-${lastSectionStr}`
    }
  }
}

