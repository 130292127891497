<div class="sidenav" *ngIf="isSidebarVisible" [nbSpinner]="isLoading">
  <div class="sidenav-overlay" (click)="closeSidenav()"></div>
  <div class="sidenav-content">
    <nb-icon status="default" icon="close" class="close" (click)="closeSidenav()"></nb-icon>
    <p class="title">History of Statuses</p>
    <hr>
    <div class="timeline" *ngFor="let status of statuses">
      <div class="timeline-item">
        <div class="timeline-line"></div>
        <div class="symbol">
          <nb-icon [icon]="getStatusIcon(status.EventFields[6].Value)"></nb-icon>
        </div>
        <div class="timeline-content">
          <span class="status-title">{{ status.EventFields[5].Value }}</span>
          <label>Action: <span class="badge mr-1" [ngClass]="getActionBadgeClass(status.EventFields[4].Value)">{{ status.EventFields[4].Value }}</span></label>
          <label>Status: <span class="badge" [ngClass]="getStatusBadgeClass(status.EventFields[6].Value)">{{ status.EventFields[6].Value }}</span></label><br>
          <span>User: <b>{{ status.EventFields[2].Value }} </b><span class="badge badge-dark">[{{status.EventFields[1].Value}}:{{ status.EventFields[7].Value }}]</span></span><br>
          <span class="status-date">{{ status.EventFields[0].Value | date:'M/d/yyyy h:mm:ssa' }}</span>
        </div>
      </div>
    </div>
  </div>
</div>
