import { Component, HostListener, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { BaseComponent } from 'app/@core/base/base.component';
import { NotifEvent, NotificaitonAlert, NotificationApiBar, NotificationApiItem, NotificationRequest } from '../notifications-sidenav/notifications.model';
import { NotificationsDataService } from '../notifications-data.service';
import { takeUntil } from 'rxjs/operators';
import { NbToastrService } from '@nebular/theme'
import { LayoutService } from 'app/@core/utils';
import { NotificationsService } from '../notifications.service';

@Component({
  selector: 'ngx-notifications-all',
  templateUrl: './notifications-all.component.html',
  styleUrls: ['./notifications-all.component.scss']
})
export class NotificationsAllComponent extends BaseComponent implements OnInit {

  activeView: string = 'All';
  notGroups: Array<NotificaitonAlert> = [];
  notGroupsRead: Array<NotificaitonAlert> = [];
  atLeastOneUnread: boolean = false;
  showLoadMore: boolean = false;

  isLoading: boolean = true;
  constructor(
    private router: Router,
    private _notificationsDataService: NotificationsDataService,
    private _notificationService: NotificationsService,
    private _toastrService: NbToastrService,
    private layoutService: LayoutService
  ){
    super()
  }

  ngOnInit(): void {
    this.layoutService.notificationList.pipe(takeUntil(this._unsubscribeAll)).subscribe(
      data => {
        if (data) {
          this.showLoadMore = this._notificationService.showLoadNotifications;
          
          this.notGroups = this._notificationService.notGroups;
          let countUnread = this.notGroups.find(el => !el.isRead);
          this.atLeastOneUnread = countUnread ? true : false;
          this.isLoading = false;
          this.activeView == 'All' ? this.seeAll() : this.seeUnread();
        }
      }
    )
  }

  trackByFn(index, item) {
    return item.id
  }

  updateList() {
    this._notificationService.notGroups = this.notGroups;
    this._notificationService.notGroups = this._notificationService.removeDuplicatesById(this._notificationService.notGroups);
    this.layoutService.notificationList.next(this.notGroups);
  }

  markAsRead(notGroup: NotificaitonAlert, i: number, type: string) {
    this._notificationsDataService.markAsReadNotification(notGroup.id).pipe(takeUntil(this._unsubscribeAll)).subscribe(data => {
      if (type == 'all') {
        this.notGroups[i].isRead = true;
      } else {
        let findGroupIndex = this.notGroups.findIndex(el => el.id === notGroup.id);
        if (findGroupIndex > -1) {
          this.notGroups[findGroupIndex].isRead = true;
        }
      }
      this.updateList();
    }, error => {
      this._toastrService.danger(error.error?.Content);
    })
  }

  markAsUnread(notGroup: NotificaitonAlert, i: number, type: string) {
    this._notificationsDataService.markAsUnreadNotification(notGroup.id).pipe(takeUntil(this._unsubscribeAll)).subscribe(data => {
      if (type == 'all') {
        this.notGroups[i].isRead = false;
      } else {
        let findGroupIndex = this.notGroups.findIndex(el => el.id === notGroup.id);
        if (findGroupIndex > -1) {
          this.notGroups[findGroupIndex].isRead = false;
        }
      }
      this.updateList();
    }, error => {
      this._toastrService.danger(error.error?.Content);
    })
  }

  markAsReadAll() {
    this.isLoading = true;
    this._notificationsDataService.markAllNotificationsAsRead().pipe(takeUntil(this._unsubscribeAll)).subscribe(data => {
      this._notificationService.refreshSidebarNotifications.next(true);
    }, error => {
      this.isLoading = false;
    })
  }

  markAsUnreadAll() {
    this.isLoading = true;
    this._notificationsDataService.markAllNotificationsAsUnread().pipe(takeUntil(this._unsubscribeAll)).subscribe(data => {
      this._notificationService.refreshSidebarNotifications.next(true);
    }, error => {
      this.isLoading = false;
    })
  }

  setUnreadNotificaitons() {
    this.notGroupsRead = [];
    this.notGroups.forEach(el => {
      if (!el.isRead) {
        this.notGroupsRead.push(el);
      }
    });
  }

  seeAll() {
    this.activeView = 'All';
  }

  seeUnread() {
    this.activeView = 'Unread';
    this.setUnreadNotificaitons();
  }

  notificationSettings(){
    this.router.navigate(['./pages/notificaitons-settings']);
  }

  loadMore() {
    this._notificationService.notificaitonsFilter.pageSize = 10;
    this._notificationService.notificaitonsFilter.pageNumber = this._notificationService.notificaitonsFilter.pageNumber + 1;
    this._notificationService.refreshSidebarNotifications.next(false);
  }

}
