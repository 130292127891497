<div class="contacts">
    <div class="contact-list">
        <div *ngFor="let contact of contactList">
            <ngbd-tooltip-basic [contact]="contact"></ngbd-tooltip-basic>
        </div>
    </div>
    <div (click)="addContact()" class="add-contact">
      <nb-icon [ngClass]="activeSubrow === index ? 'colaps-contacts' : 'expans-contacts'" [icon]="activeSubrow === index?'minus-circle':'plus-circle'"></nb-icon>
    </div>
</div>
