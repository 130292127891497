import { Directive, Input, TemplateRef, ElementRef, OnInit, HostListener, ComponentRef, OnDestroy } from '@angular/core';
import { Overlay, OverlayPositionBuilder, OverlayRef } from '@angular/cdk/overlay';
import { ComponentPortal } from '@angular/cdk/portal';
import { TooltipViewComponent } from './tooltip-view/tooltip-view.component';

@Directive({
  selector: '[customToolTip]'
})
export class ToolTipRendererDirective {

  @Input() showToolTip: boolean = true;

  @Input(`customToolTip`) text: string;

  @Input() contentTemplate: TemplateRef<any>;

  private _overlayRef: OverlayRef;
  
  constructor(private _overlay: Overlay,
              private _overlayPositionBuilder: OverlayPositionBuilder,
              private _elementRef: ElementRef) { }


  ngOnInit() {

    if (!this.showToolTip) {
      return;
    }

    const positionStrategy = this._overlayPositionBuilder
                                 .flexibleConnectedTo(this._elementRef)
                                 .withPositions([{
                                                    originX: 'center',
                                                    originY: 'bottom',
                                                    overlayX: 'center',
                                                    overlayY: 'top',
                                                    offsetY: 5,
                                                }]);

    this._overlayRef = this._overlay.create({ positionStrategy});

  }

  @HostListener('mouseenter')
  show() {
    if (this._overlayRef && !this._overlayRef.hasAttached()) {
      const tooltipRef: ComponentRef<TooltipViewComponent> = this._overlayRef.attach(new ComponentPortal(TooltipViewComponent));
      tooltipRef.instance.text = this.text;
      tooltipRef.instance.contentTemplate = this.contentTemplate;
    }    
  }

  @HostListener('mouseleave')
  hide() {
    this.closeToolTip();
  }

  ngOnDestroy() {
    this.closeToolTip();
  }

  private closeToolTip() {
    if (this._overlayRef) {
      this._overlayRef.detach();
    }
  }

}
