import { DecimalPipe } from '@angular/common'
import { Component, ElementRef, EventEmitter, forwardRef, Input, Output, ViewChild } from '@angular/core'
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms'

@Component({
  selector: 'numeric-input',
  template: `<div class="p-relative">
  <span *ngIf="prefix" class="prefix">{{ prefix }}</span>
  <input
    nbInput
    fullWidth
    #editor
    class="pl-0 pt-0 pb-0 pl-15 numeric-input-field"
    [(ngModel)]="value"
    [disabled]="disabled"
    (keypress)="keyPressNumbers($event)"
    (input)="onInput($event)"
    (change)="onInternalChange($event)"
    (keyup)="this.maxLimit?validateMax($event):''"
    (blur)="onblur($event)"
    [placeholder]="placeholder"
  />
</div>`,
  styleUrls: ['./numeric-input.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => NumericInputComponent),
      multi: true,
    },
  ],
})
export class NumericInputComponent implements ControlValueAccessor {
  @ViewChild('editor') editor: ElementRef
  @Input() value: string
  @Input() prefix: string = ''
  @Input() disabled: boolean = false
  @Input() maxLimit: number = null;
  @Input() placeholder: string = '';
  @Output() keyPress: EventEmitter<any> = new EventEmitter();
  @Output() blur: EventEmitter<any> = new EventEmitter();

  DotCharCode: number = 46

  constructor(private decimalPipe: DecimalPipe) {}

  focus() {
    this.editor.nativeElement.focus()
  }

  onBlur = (_) => {}
  onChange: (_) => {}

  keyPressNumbers(event) {

    var charCode = event.which ? event.which : event.keyCode
    // Only Numbers 0-9 or .
    if (
      (charCode >= 48 && charCode <= 57) ||
      (charCode >= 96 && charCode <= 105) ||
      (charCode == this.DotCharCode && this.value.indexOf('.') === -1)
    ) {
      setTimeout(() => {
        this.writeValue(event.srcElement.value)
      })
      return true
    } else {
      event.preventDefault()
      return false
    }
  }

  validateMax(event) {
    if(this.maxLimit && Number(event.srcElement.value.replaceAll(',',''))>this.maxLimit){
      this.writeValue(this.maxLimit)
      event.preventDefault();
      return false;
    }
  }

  onInternalChange(event) {
    this.onChange(this.parseValue(this.value))
  }

  onInput(event) {
    if (event.inputType === 'deleteContentBackward') {
      setTimeout(() => {
        this.writeValue(event.srcElement.value)
      })
    }
  }

  writeValue(obj: any): void {
    if (!obj) {
      this.keyPress.emit(NaN)
      return obj
    }

    let endsWithDot = obj.toString().endsWith('.')
    let decimalPart = !endsWithDot && obj.toString().indexOf('.') == -1 ? '' : obj.toString().split('.').slice(-1)[0]
    this.value = `${this.formatValue(obj)}${endsWithDot ? '.' : ''}`
    if (decimalPart && decimalPart.endsWith('0')) this.value = `${this.value.split('.')[0]}.${decimalPart}`

    this.keyPress.emit(this.parseValue(this.value))
  }

  registerOnChange(fn: any): void {
    this.onChange = fn
  }

  registerOnTouched(fn: any): void {
    this.onBlur = fn
  }

  setDisabledState?(isDisabled: boolean): void {
    this.disabled = isDisabled
  }

  parseValue(value: any) {
    return value? parseFloat(value.toString().replaceAll(',', '')):value;
  }

  formatValue(value: any) {
    if (!value) return value

    return this.decimalPipe.transform(this.parseValue(value), '1.0-10') || ''
  }

  ngOnInit(): void {}

  onblur(event: any) {
    this.blur.emit(event)
  }
}
