import { Component, Input, OnInit } from '@angular/core';
import { ViewCell } from 'ng2-smart-table';

@Component({
  selector: 'ngx-custom-column-table-list-html',
  templateUrl: './custom-column-table-list-html.component.html',
  styleUrls: ['./custom-column-table-list-html.component.scss']
})
export class CustomColumnTableListHtmlComponent  implements ViewCell, OnInit {
  @Input() listItems: any;

  @Input() value: any;
  @Input() rowData: any;

  constructor() { }

  ngOnInit(): void {
    this.listItems=typeof this.value==='string'?String(this.value).split(",") : this.value;
  }
}
