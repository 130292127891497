import { ProposalObject } from "./proposal.model";
import { FieldObject } from "./shared.model";

export class LeadSiteObject {
  constructor(
    public AccountNumber?: string | null,
    public Address?: string | null,
    public AnnualUsage?: number | null,
    public BillingAddress?: string | null,
    public BillingCity?: string | null,
    public BillingState?: string | null,
    public BillingZipCode?: string | null,
    public City?: string | null,
    public CreatedDate?: string | Date | null,
    public CurrentVendorId?: number | null,
    public CurrentVendorName?: string | null,
    public EndDate?: string | Date | null,
    public ExcludedContractsEndDate?: string | Date | null,
    public Fields?: Array<FieldObject> | null,
    public HasCustomBillingAddress?: boolean | null,
    public Id?: number | null,
    public LeadId?: number | null,
    public OngoingProposals?: Array<ProposalObject> | null,
    public ConfirmedProposals?: Array<ProposalObject> | null,
    public Proposals?: Array<ProposalObject> | null,
    public StartDate?: string | Date | null,
    public State?: string | null,
    public ZipCode?: string | null,
    public displayText?: string | null,
    public SiteId?: number | null,
    public PropertyName?: string | null,
    public isSelected?: boolean,
    public IsProposalSite ?: boolean
  ){}
}

export class LeadSiteTableObject extends LeadSiteObject {
  public isSelected?: boolean | null;
  public edit?: any | null;
  public editName ?: any | null;
  public pristineValues?: Array<any> | null;
  public IsProposalSite?: boolean | null;
}

export class ProposalSiteUpdateObject {
  constructor(
    public updateLeadSites?: boolean | false,
    public sites?: Array<LeadSiteObject>
  ){}
}

export class UploadedMapColumn {
  constructor(
    public value: string | number | null | undefined,
    public edit: any,
    public Format: number,
  ){}
}
