import { Injectable } from '@angular/core';
import * as signalR from '@microsoft/signalr';
import { NbAuthJWTToken, NbAuthService } from '@nebular/auth';
import { environment } from 'environments/environment';
import { Subject } from 'rxjs';
import { HttpClient } from '@angular/common/http'
import { NotifEvent, NotificaitonAlert, NotificationRequest, SignedDocumentNotificationAlert } from './notifications-sidenav/notifications.model';
import { NbGlobalPhysicalPosition, NbToastRef, NbToastrService } from '@nebular/theme';
import { da } from 'date-fns/locale';
import { LookupService } from 'app/@core/services/lookup.service';

@Injectable({
  providedIn: 'root'
})
export class NotificationsService {

  private _hub_connection: signalR.HubConnection;
  private _currentUser: any;
  private _lastGroup: string;

  public onNotifiationEventReceived: Subject<any> = new Subject();
  public docusignSignedDocumentNotification: Subject<NotificaitonAlert> = new Subject();
  public formDocumentSendNotification: Subject<any> = new Subject();
  public recipientNotFoundNotification: Subject<any> = new Subject();
  public failedEmailConnectionNotification: Subject<any> = new Subject();
  public docusignEmailDeliveredNotification: Subject<any> = new Subject();
  public missingProposalFeesNotification: Subject<any> = new Subject();
  public docusignTemplatesChangeNotification: Subject<any> = new Subject();
  public sendStartProcessingNotification: Subject<any> = new Subject();
  public sendProposalEmailSuccessfullyProcessedNotification: Subject<any> = new Subject();
  public sendFollowupProposalEmailSuccessfullyProcessedNotification: Subject<any> = new Subject();
  public sendEnvelopeViewedNotification: Subject<any> = new Subject();
  public sendEnvelopeSignerReassignedNotification: Subject<any> = new Subject();
  public sendRequestConfirmationFromVendorNotification: Subject<any> = new Subject();
  public sendNotificaitonFailedEmailDeliveryToAdmin: Subject<any> = new Subject();
  public emailDomainVerificationNotification: Subject<any> = new Subject();
  public refreshSidebarNotifications: Subject<boolean> = new Subject();
  public refreshAllNotificationsPage: Subject<void> = new Subject();
  public notificationsCount: Subject<number> = new Subject();

  user: any;
  notificaitonsFilter: NotificationRequest = new NotificationRequest();
  notGroups: Array<NotificaitonAlert> = [];
  showLoadNotifications: boolean = false;

  constructor(
    private authService: NbAuthService,
    private _toast: NbToastrService,
        private http: HttpClient,
    private _lookupService: LookupService
  ) {
    this.authService.onTokenChange().subscribe((token: NbAuthJWTToken) => {
      if (token.isValid()) {
        this.user = token.getPayload()
      }
    })
  }

  resetNotifications() {
    this.notificaitonsFilter.pageNumber = 1;
    this.notificaitonsFilter.pageSize = 10;
    this.notificaitonsFilter.sortDirection = 'asc';
  }

  /**
   * Function to Setup SignalR
   */
  async SetupSignalr(): Promise<void> {
    let toastRef: NbToastRef = null;

    this._hub_connection = new signalR.HubConnectionBuilder()
      .configureLogging(signalR.LogLevel.Debug)
      .withAutomaticReconnect([0, 1000, 2000, 3000, 1000, 2000, 3000, 1000, 2000, 3000, 1000, 2000, 3000, 1000, 2000, 3000, 1000, 2000, 3000, 1000, 2000])
      .withUrl(`${environment.apiUrl}/notificationhub`, {
        skipNegotiation: true,
        transport: signalR.HttpTransportType.WebSockets
      })
      .build();

    this._hub_connection.onreconnecting((error: Error) => {
      toastRef = this._toast.danger("Your connection to the server was interrupted, we are trying to reconnect.  You can save your work and refresh the page as well.", null, {
        destroyByClick: false,
        duration: 0,
        position: NbGlobalPhysicalPosition.BOTTOM_RIGHT
      })
      console.error(`Notification Hub Connection lost due to error "${error}". Reconnecting...`)
    });

    this._hub_connection.onreconnected(() => {
      try {
        let id = localStorage.getItem('userHubId');
        if (id) {
          this.joinGroup(`user_${id}`);
        } else {
          this.http.get(`${environment.baseApiUrl}/user/id`).subscribe((result: any) => {
            if (result) {
              localStorage.setItem('userHubId', result);
              this.joinGroup(`user_${result}`);
            }
          })
        }
        toastRef.close();
        setTimeout(() => {
          this._toast.success("Successful reconnection. You are connected to the server.", null, {
            destroyByClick: true,
            duration: 5000,
            position: NbGlobalPhysicalPosition.BOTTOM_RIGHT
          })
        }, 1000);
      }
      catch (error) {
        console.error('Error while setting up notifications SignalR Connection: ', error);
      }
    })

    this._hub_connection.onclose((error: Error) => {
      this._hub_connection.stop().then(
        () => {
          this._hub_connection.start()
          let id = localStorage.getItem('userHubId');
          if (id) {
            this.joinGroup(`user_${id}`);
          } else {
            this.http.get(`${environment.baseApiUrl}/user/id`).subscribe((result: any) => {
              if (result) {
                localStorage.setItem('userHubId', result);
                this.joinGroup(`user_${result}`);
              }
            })
          }
        }
      );

    });

    try {
      await this._hub_connection.start();

      let id = localStorage.getItem('userHubId');
      if (id) {
        this.joinGroup(`user_${id}`);
      } else {
        this.http.get(`${environment.baseApiUrl}/user/id`).subscribe((result: any) => {
          if (result) {
            localStorage.setItem('userHubId', result);
            this.joinGroup(`user_${result}`);
          }
        })
      }

      //Connect to group only for admins
      if (this.user && this.user.role === 'Admin') {
        this.joinGroup('adminGroup');
      }
    }
    catch (error) {
      console.error('Error while setting up notifications SignalR Connection: ', error);
    }

    this._hub_connection.on('SendTestMessage',
      (message: string) => {
      }
    );

    this._hub_connection.on('DocusignSignedDocumentNotification',
      (data: any) => {
        this.docusignSignedDocumentNotification.next(data);
      }
    );

    this._hub_connection.on('FormsDocumentSendNotification',
      (data: any) => {
        this.formDocumentSendNotification.next(data);
      }
    );

    this._hub_connection.on('SendEventProcessNotification',
      (data: any) => {
        this.onNotifiationEventReceived.next(data);
      }
    );

    this._hub_connection.on('RecipientNotFoundNotification',
    (data: any) => {
      this.recipientNotFoundNotification.next(data);
    })

    this._hub_connection.on('SendFailedEmailConnectionNotification',
    (data: any) => {
      this.failedEmailConnectionNotification.next(data);
    })

    this._hub_connection.on('SendDeliveredNotification',
    (data: any) => {
      this.docusignEmailDeliveredNotification.next(data);
    })

    this._hub_connection.on('SendMissingProposalFeesNotification',
    (data: any) => {
      this.missingProposalFeesNotification.next(data);
    })

    this._hub_connection.on('SendDocusignTemplatesUpdateNotification',
    (data: any) => {
      this.docusignTemplatesChangeNotification.next(data);
      this._lookupService.is_docusign_templates_called = false;
    })

    this._hub_connection.on('SendStartProcessingNotification',
    (data: any) => {
      this.sendStartProcessingNotification.next(data);
    })

    this._hub_connection.on('SendProposalEmailSuccessfullyProcessedNotification',
    (data: any) => {
      this.sendProposalEmailSuccessfullyProcessedNotification.next(data);
    })

    this._hub_connection.on('SendFollowupProposalEmailSuccessfullyProcessedNotification',
    (data: any) => {
      this.sendFollowupProposalEmailSuccessfullyProcessedNotification.next(data);
    })

    this._hub_connection.on('SendEnvelopeViewedNotification',
    (data: any) => {
      this.sendEnvelopeViewedNotification.next(data);
    })

    this._hub_connection.on('SendEnvelopeSignerReassignedNotification',
    (data: any) => {
      this.sendEnvelopeSignerReassignedNotification.next(data);
    })

    this._hub_connection.on('SendRequestConfirmationFromVendorNotification',
    (data: any) => {
      this.sendRequestConfirmationFromVendorNotification.next(data);
    })

    this._hub_connection.on('SendNotificaitonFailedEmailDeliveryToAdmin',
    (data: any) => {
      this.sendNotificaitonFailedEmailDeliveryToAdmin.next(data);
    })

    this._hub_connection.on('SendEmailDomainVerificationNotificaiton',
    (data: any) => {
      this.emailDomainVerificationNotification.next(data);
    })
  }

  public async joinGroup(groupName: string) {
    this._hub_connection.invoke('JoinGroup', groupName)
      .then(() => {
        this._lastGroup = groupName;
        console.log(`Joined SignalR group ${groupName}`)
      })
      .catch((err) => {
        return console.error(`ERROR WHILE JOINIG GROUP ${groupName} -> `, err.toString());
      });
  }

  public async leaveGroup(groupName: string, closeConnection: boolean = false) {
    await this._hub_connection.invoke('LeaveGroup', groupName).then(() => {
      if(closeConnection){
        this._hub_connection.stop();
      }
      console.log('LEFT SIGNALR GROUP: ', groupName);
    }).catch(function (err) {
      return console.error('ERROR WHILE REMOVING FROM GROUP -> ', err);
    });
  }

  removeDuplicatesById(objects: NotificaitonAlert[]): NotificaitonAlert[] {
    const uniqueObjectsMap: { [key: string]: NotificaitonAlert } = {};
    for (const obj of objects) {
        uniqueObjectsMap[obj.id] = obj;
    }

    const uniqueObjectsArray = Object.values(uniqueObjectsMap);

    return uniqueObjectsArray;
}
}
