import { Injector, ModuleWithProviders, NgModule, Optional, SkipSelf } from '@angular/core'
import { CommonModule, CurrencyPipe, DatePipe, DecimalPipe } from '@angular/common'
import { NbAuthModule } from '@nebular/auth'
import { NbSecurityModule, NbRoleProvider } from '@nebular/security'
import { of as observableOf } from 'rxjs'

import { throwIfAlreadyLoaded } from './module-import-guard'
import {
  LayoutService,
  CoreDataSource,
  NbPopoverContextService,
  InjectorService,
  NbOtpStrategy,
  NumericInputComponent,
  ClickOutsideDirective,
  CoreDataSourceFactory,
  DragScrollDirective
} from './utils'
import { DocumentService, TranslateService, HttpHelperService } from './services'
import { CurrencyViewComponent } from './utils/currency-view.component'
import { DateViewComponent } from './utils/date-view.component'
import { AutocompleteComponent } from './base/auto-complete.component'
import { FormsModule } from '@angular/forms'
import { NbInputModule } from '@nebular/theme'
import { DragColumnDirective } from './utils/drag-column.directive';

export class NbSimpleRoleProvider extends NbRoleProvider {
  getRole() {
    // here you could provide any role based on any auth flow
    return observableOf('guest')
  }
}

export const NB_CORE_PROVIDERS = [
  ...NbSecurityModule.forRoot({
    accessControl: {
      guest: {
        view: '*',
      },
      user: {
        parent: 'guest',
        create: '*',
        edit: '*',
        remove: '*',
      },
    },
  }).providers,

  {
    provide: NbRoleProvider,
    useClass: NbSimpleRoleProvider,
  },
  LayoutService,
  CoreDataSource,
  CoreDataSourceFactory,
  DocumentService,
  HttpHelperService,
  TranslateService,
  NbPopoverContextService,
  NbInputModule,
  InjectorService,
  NbOtpStrategy,
]

@NgModule({
  imports: [CommonModule, FormsModule, NbInputModule],
  declarations: [CurrencyViewComponent, DateViewComponent, AutocompleteComponent, NumericInputComponent,ClickOutsideDirective,DragScrollDirective,DragColumnDirective],
  exports: [NbAuthModule, AutocompleteComponent, NumericInputComponent,ClickOutsideDirective,DragScrollDirective,DragColumnDirective],
})
export class CoreModule {
  constructor(@Optional() @SkipSelf() parentModule: CoreModule, injector: Injector) {
    // throwIfAlreadyLoaded(parentModule, 'CoreModule');
    InjectorService.injector = injector
  }
  static forChild(): ModuleWithProviders<CoreModule> {
    return {
      ngModule: CoreModule,
      providers: [DecimalPipe, CurrencyPipe, DatePipe],
    }
  }

  static forRoot(): ModuleWithProviders<CoreModule> {
    return {
      ngModule: CoreModule,
      providers: [...NB_CORE_PROVIDERS, DecimalPipe],
    }
  }
}
