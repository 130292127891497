import { Component, OnDestroy, OnInit } from '@angular/core'
import { NbAuthJWTToken, NbAuthService } from '@nebular/auth'
import { NbMediaBreakpointsService, NbMenuService, NbSidebarService, NbThemeService } from '@nebular/theme'
import { debounceTime, map, takeUntil } from 'rxjs/operators'
import { async, Subject } from 'rxjs'
import { LayoutService } from 'app/@core/utils'
import { environment } from 'environments/environment'
import { HttpClient } from '@angular/common/http'
import * as Bowser from 'bowser'
import { NotificationsService } from 'app/pages/shared/notifications.service'
import { LookupService } from 'app/@core/services/lookup.service'
import { FormGroup } from '@angular/forms'
import { throwError } from 'rxjs';
import { NotificationsDataService } from 'app/pages/shared/notifications-data.service'
import { Router } from '@angular/router'

@Component({
  selector: 'ngx-header',
  styleUrls: ['./header.component.scss'],
  templateUrl: './header.component.html',
})
export class HeaderComponent implements OnInit, OnDestroy {
  private destroy$: Subject<void> = new Subject<void>()
  userPictureOnly: boolean = false
  user: any

  userMenu = [{ title: 'Profile' }, { title: 'Log out' }]
  ipAddress: any

  notificaitonCount:number = 0;
  countLoaded: boolean = false;
  isModalOpen: boolean = false;
  userForm: FormGroup;
  newPassword: string;
  showSuccess: boolean = false;
  placeholder: string;

  constructor(
    private sidebarService: NbSidebarService,
    private menuService: NbMenuService,
    private themeService: NbThemeService,
    private layoutService: LayoutService,
    private breakpointService: NbMediaBreakpointsService,
    private authService: NbAuthService,
    private http: HttpClient,
    private notificationsService: NotificationsService,
    private notificationsDateService: NotificationsDataService,
    private _lookupService: LookupService,
    private _router: Router
  ) {
    this.authService.onTokenChange().subscribe((token: NbAuthJWTToken) => {
      if (token.isValid()) {
        this.user = token.getPayload()
        this.placeholder = `Logged in as ${this.user.nameid}`;
        let userDataString = this.user['http://schemas.microsoft.com/ws/2008/06/identity/claims/userdata'];

        // If user data string exists and is a valid JSON
        if (userDataString) {
            try {
                let userData = JSON.parse(userDataString);

                // Extract the IsTempPass value
                let isTempPass = userData.IsTempPass;

                console.log("IsTempPass:", isTempPass);

                if (isTempPass) {
                  this.openCustomModal();
                }
            } catch (err) {
                console.error('Error parsing user data:', err);
            }
        }
      }
    })
  }

  ngOnInit() {
    this.getNotificationCount();

    const { xl } = this.breakpointService.getBreakpointsMap()
    this.themeService
      .onMediaQueryChange()
      .pipe(
        map(([, currentBreakpoint]) => currentBreakpoint.width < xl),
        takeUntil(this.destroy$)
      )
      .subscribe((isLessThanXl: boolean) => (this.userPictureOnly = isLessThanXl));

      this.layoutService.notificationList.pipe(
        debounceTime(1000)
      ).subscribe(
        data => {
          if (data) {
            this.getNotificationCount();
          }
        }
      )
  }

  getNotificationCount() {
    this.countLoaded = false;
    this.notificationsDateService.getNotificationCount().subscribe(data => {
      this.notificaitonCount = data;
      this.notificationsService.notificationsCount.next(this.notificaitonCount);
      this.countLoaded = true;
    }, error => {
      this.countLoaded = true;
    })
  }

  ngOnDestroy() {
    this.destroy$.next()
    this.destroy$.complete()
  }

  toggleSidebar(): boolean {
    this.sidebarService.toggle(true, 'menu-sidebar')
    this.layoutService.changeLayoutSize()

    return false
  }

  navigateHome() {
    this.menuService.navigateHome()
    return false
  }

  private externalApiBaseUrl = 'https://api.ipgeolocation.io/ipgeo?apiKey=ec287b25459c44f7bb128e8ca6c337bc';
	async getSearchData() {
		try {
			let response;
			response = await fetch(this.externalApiBaseUrl);
			const data = await response.json();
			return data;
		} catch (error) {
			console.trace(error);
		}
	}

  openCustomModal() {
    this.isModalOpen = true;
  }

  closeCustomModal() {
    this.isModalOpen = false;
  }

  async logOut() {
    var typeBrowser;
    var userAgent = Bowser.parse(window.navigator.userAgent) || null;
    if(userAgent) {
        typeBrowser = userAgent.browser.name + " / " + userAgent.browser.version;
    } else {
        typeBrowser = null;
    }

    var res = await this.getSearchData();

    // Construct the endpoint URL with the data
    const endpoint = `${environment.baseApiUrl}/user/logout?userId=${this.user.name}&typeBrowser=${typeBrowser}&ipAddress=${res.ip}`;

    this.http.get(endpoint).toPromise().then((data: any) => {
      setTimeout(() => {
        localStorage.removeItem('auth_app_token');
        localStorage.removeItem('userHubId');
        localStorage.removeItem('HubrickUserApplicationPages');
        localStorage.removeItem('HubrickUserApplicationRoutes');
        sessionStorage.removeItem('authenticationLogged');
        this._lookupService.is_docusign_templates_called = false;
        // location.href = '/';
        return false;
      }, 200);
    });

    this._router.navigate(['auth/login']);
}

submit() {
  console.log("user:", this.user);

  if (!this.newPassword) {
    console.error("Password is not provided");
    return;
  }

  // Construct the UserDto object
  const userDto = {
    Password: this.newPassword,
    Id: this.user['unique_name']
  };

  // Make a POST request to update the password
  this.http.post(`${environment.baseApiUrl}/user/updatepassword`, userDto)
    .toPromise()
    .then((response: any) => {
      console.log(response);
      if (response === "Password updated successfully.") {
        this.showSuccess = true;

        setTimeout(() => {
                this.showSuccess = false;
                this.closeCustomModal();
            }, 2000);
      } else {
        // Provide feedback to the user about the failure
        alert(response);
      }
    })
    .catch(this.handleError);
}

private handleError(error: any) {
  let errorMsg = 'There was an issue updating the password. Please try again.';
  console.error(error);
  return throwError(errorMsg);
}

  toggleNotificationSidenav() {
    this.layoutService.toggleNotificationSidenav.next()
  }

 openProfile () {
    this._router.navigate(['pages/my-profile']);
 }
}
