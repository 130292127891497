import { Injectable } from '@angular/core';
import { environment } from 'environments/environment';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { map, catchError } from 'rxjs/operators';
import { NotificationRequest, NotificationSettings } from './notifications-sidenav/notifications.model';

@Injectable({
  providedIn: 'root'
})
export class NotificationsDataService {

  /**
   * Private variables
   */
  private _apiUrl: string = environment.baseApiUrl

  constructor(private _httpClient: HttpClient) {}

  // -----------------------------------------------------------------------------------------------------
  // @ Private methods
  // -----------------------------------------------------------------------------------------------------

  /**
  * Function to handle error
  * @param error
  * @returns
  */
  private _handleError(error: HttpErrorResponse) {
    if (error.error instanceof ErrorEvent) {
      // A client-side or network error occurred. Handle it accordingly.
      console.error('An error occurred:', error.error.message)
    } else {
      // The backend returned an unsuccessful response code. The response body may contain clues as to what went wrong,
      console.error(`Backend returned code ${error.status}, ` + `body was: ${error.error}`)
    }
    // return an observable with a user-facing error message
    return throwError(error)
  }

  // -----------------------------------------------------------------------------------------------------
  // @ Public methods
  // -----------------------------------------------------------------------------------------------------

  /**
   * Funciton to get notifications
   */
    public getNotificationsPaged(request: NotificationRequest): Observable<any> {
      return this._httpClient.post<any>(`${environment.baseApiUrl}/Notification`, request).pipe(
        map((data: any) => data)
      );
    }

  /**
   * Funciton to get notification settings
   */
    public getNotificationSettings(): Observable<any> {
      return this._httpClient.get<any>(`${environment.baseApiUrl}/User/notification-settings`).pipe(
        map((data: any) => data)
      );
    }
    
  /**
   * Funciton to update notification settings
   */
  public updateNotificationSettings(request: NotificationSettings): Observable<any> {
    return this._httpClient.put<any>(`${environment.baseApiUrl}/User/notification-settings`, request).pipe(
      map((data: any) => data)
    );
  }

  /**
   * Funciton to set notification as read
   */
  public markAsReadNotification(key: string): Observable<any> {
    return this._httpClient.put<any>(`${environment.baseApiUrl}/Notification/${key}/mark-as-read`, null).pipe(
      map((data: any) => data)
    );
  }

  /**
   * Funciton to set notification as unread
   */
  public markAsUnreadNotification(key: string): Observable<any> {
    return this._httpClient.put<any>(`${environment.baseApiUrl}/Notification/${key}/mark-as-unread`, null).pipe(
      map((data: any) => data)
    );
  }

  /**
   * Funciton to set notification as closed
   */
   public markAsClosedNotification(id: number): Observable<any> {
    return this._httpClient.put<any>(`${environment.baseApiUrl}/Notification/${id}/mark-event-as-closed`, null).pipe(
      map((data: any) => data)
    );
  }

  /**
   * Funciton to mark multiple notifications as read
   */
  public markMultipleNotificationsAsRead(keys: Array<string>): Observable<any> {
    return this._httpClient.put<any>(`${environment.baseApiUrl}/Notification/dismiss-notifications`, keys).pipe(
      map((data: any) => data)
    );
  }

  /**
   * Funciton to get notification count
   */
  public getNotificationCount(): Observable<any> {
    return this._httpClient.get<any>(`${environment.baseApiUrl}/Notification/count`).pipe(
      map((data: any) => data)
    );
  }

  /**
   * Funciton to mark all notifications as read
   */
  public markAllNotificationsAsRead(): Observable<any> {
    return this._httpClient.put<any>(`${environment.baseApiUrl}/Notification/mark-as-read-all`, null).pipe(
      map((data: any) => data)
    );
  }

  /**
   * Funciton to mark all notifications as unread
   */
  public markAllNotificationsAsUnread(): Observable<any> {
    return this._httpClient.put<any>(`${environment.baseApiUrl}/Notification/mark-as-unread-all`, null).pipe(
      map((data: any) => data)
    );
  }
}
