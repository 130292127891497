<div class="header-container">
  <div class="logo-container">
    <a (click)="toggleSidebar()" href="#" class="sidebar-toggle">
      <nb-icon icon="menu-2-outline"></nb-icon>
    </a>
    <a class="logo" href="#" (click)="navigateHome()">Hubrick</a>
  </div>
</div>
<div class="header-container search-all">
  <ngx-search></ngx-search>
</div>

<button nbSuffix nbButton ghost (click)="toggleNotificationSidenav()" class="p-0 p-relative">
  <span class="notif-count" *ngIf="notificaitonCount && notificaitonCount > 0" [ngClass]="{'count-square' : notificaitonCount > 9}">{{notificaitonCount}}</span>
  <img class="notif-icon" src="../../../../assets/images/notif-icon.png">
  <div class="loader" *ngIf="!countLoaded"></div>
</button>

<div class="header-container">
  <nb-actions size="tiny">
    <nb-action>
      <ngx-datetime></ngx-datetime>
    </nb-action>
    <nb-action class="user-action">
      <nb-select [selected]="placeholder" placeholder="Logged in as {{ user.nameid }}">
        <nb-option (click)="openProfile()"><nb-icon icon="person-outline" class="mr-2"></nb-icon> Profile</nb-option>
        <nb-option (click)="logOut()"><nb-icon icon="log-out-outline" class="mr-2"></nb-icon> Logout</nb-option>
      </nb-select>
    </nb-action>
    <!-- <nb-action class="control-item" icon="log-out-outline" (click)="logOut()"></nb-action> -->
  </nb-actions>
</div>

<!-- Custom Modal -->
<div class="custom-modal" *ngIf="isModalOpen">
  <div class="modal-content">
    <h2>Please Reset Your Password for Better Security</h2>
    <p>Enter your new password:</p>
    <input type="password" [(ngModel)]="newPassword" placeholder="New Password" />

    <div class="modal-footer">
      <button nbButton status="primary" (click)="submit()">Update Password</button>
      <button nbButton status="danger" (click)="closeCustomModal()">Cancel</button>
    </div>
    <div class="success-message" *ngIf="showSuccess">
      Password Updated Successfully
    </div>
  </div>
</div>
