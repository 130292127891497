<nb-card>
  <nb-card-header>{{ translate('HELP_SITEUNSAVED_TITLE') }}</nb-card-header>
  <nb-card-body>
      <label>{{ translate('HELP_SITEUNSAVED_DESCRIPTION') }}</label>
  </nb-card-body>
  <nb-card-footer>
      <button nbButton status="success" size="tiny" class="pull-right" (click)="submit(true)">{{ translate('HELP_SITEUNSAVED_SAVECONTINUE') }}</button>
      <button nbButton status="warning" size="tiny" class="pull-right" (click)="submit(false)">{{ translate('HELP_SITEUNSAVED_CONTINUE') }}</button>
  </nb-card-footer>
</nb-card>
