<nb-card class="card-dialog">
    <nb-card-header> Server Group Integration <nb-icon class="close-icon" icon="close" (click)="dialogRef.close()"> </nb-icon></nb-card-header>
    <nb-card-body>
        <p *ngIf="isFormModified && isUpdate" class="text-danger mb-2 mt-2">By changing the account details you would be
            required to update vendor's default template to be used.</p>

        <form class="w-100 m-t dialog-form" [formGroup]="docusignIntegrationForm" *ngIf="!isNordVpn">

            <div class="field">
                <label>Name*</label>
                <mat-form-field appearance="outline" class="w-100">
                    <input matInput formControlName="Name" name="Name" autocomplete="new-password">
                </mat-form-field>
            </div>

            <div class="field">
                <label>Base API URL*</label>
                <mat-form-field appearance="outline" class="w-100">
                    <input matInput formControlName="ApiUrl" name="ApiUrl" autocomplete="new-password">

                </mat-form-field>
            </div>

            <div class="field">
                <label>Account Id*</label>
                <mat-form-field appearance="outline" class="w-100">
                    <input matInput formControlName="AccountIdNew" name="AccountIdNew" autocomplete="new-password">

                </mat-form-field>
            </div>

            <div class="field">
                <label>Integration Key*</label>
                <mat-form-field appearance="outline" class="w-100">
                    <input matInput formControlName="ClientId" name="ClientId" autocomplete="new-password">

                </mat-form-field>
            </div>

            <div class="field">
                <label>Secret Key*</label>
                <mat-form-field appearance="outline" class="w-100">
                    <input matInput formControlName="SecretKey" name="SecretKey" autocomplete="new-password">

                </mat-form-field>
            </div>

            <div class="field">
                <label>Redirect URL* <nb-icon icon="copy-outline" style="margin-left: 8px; cursor: pointer;"
                        (click)="copyToClipboard(originModel.RedirectUri)"></nb-icon></label>
                <mat-form-field appearance="outline" class="w-100">
                    <input matInput formControlName="RedirectURL" name="RedirectURL" autocomplete="new-password">

                </mat-form-field>
            </div>

            <div class="field">
                <label>Email*</label>
                <mat-form-field appearance="outline" class="w-100">
                    <input matInput formControlName="Email" name="Email" type="email" autocomplete="new-password" (input)="emailInputChanged()">
                </mat-form-field>
                <p class="error_message" *ngIf="docusignIntegrationForm.get('Email').invalid && docusignIntegrationForm.get('Email').touched">Invalid email format</p>
            </div>

            <div class="field">
                <label>Password*</label>
                <mat-form-field appearance="outline" class="w-100">
                    <input matInput [type]="showPassword ? 'text' : 'password'" formControlName="Password"
                        name="Password" autocomplete="new-password">
                    <button mat-icon-button matSuffix (click)="togglePasswordVisibility()">
                        <mat-icon>{{ showPassword ? 'visibility_off' : 'visibility' }}</mat-icon>
                    </button>
                </mat-form-field>
            </div>

        </form>
        <form class="w-100 m-t dialog-form" [formGroup]="nordVpnIntegrationForm" *ngIf="isNordVpn">
            <div class="field">
                <label>Access Token
                    <nb-icon class="tooltip-icon" icon="question-mark-circle-outline" nbTooltip="Click for more info"
                        nbTooltipPlacement="top" (click)="openNordVpnGuide()"></nb-icon>
                </label>
                <mat-form-field appearance="outline" class="w-100">
                    <input matInput formControlName="AccessToken" name="AccessToken" autocomplete="new-password">
                </mat-form-field>
            </div>

        </form>
    </nb-card-body>
    <nb-card-footer>
        <button class="cancel" nbButton size="tiny" status="danger" (click)="dialogRef.close()">Cancel</button>
        <button class="ml-1" nbButton size="tiny" status="success"
            [disabled]="isNordVpn ? nordVpnIntegrationForm.invalid || !isFormModified : docusignIntegrationForm.invalid || !isFormModified || isLoading"
            (click)="submit();" [nbSpinner]="isLoading">Submit</button>
    </nb-card-footer>
</nb-card>
