import { DecimalPipe } from '@angular/common';
import { AfterViewInit, ChangeDetectorRef, Component, ElementRef, Input, OnDestroy, OnInit, QueryList, ViewChild, ViewChildren } from '@angular/core';
import { NbDialogRef, NbToastrService } from '@nebular/theme';
import { NgSelectComponent } from '@ng-select/ng-select';
import { BaseComponent } from 'app/@core/base/base.component';
import { EntityType } from 'app/@core/enums/entity-type';
import { FieldType } from 'app/@core/enums/field-type';
import { LeadService } from 'app/@core/services/lead.service';
import { NumericInputComponent } from 'app/@core/utils';
import { parseISO } from 'date-fns';
import { find, take } from 'rxjs/operators';
import { FIELD_PREFIX } from '../../app.constants';
import { CellFormatterService } from '../../cell-formatter.service';
import { ProposalObject, ProposalSiteObject } from 'app/@core/models/proposal.model';
import { ProposalStatus } from 'app/@core/enums/proposal-status';
import { ProposalSiteEditorColumnDefinitionObject } from 'app/@core/models/configuraton.model';
import { LeadSiteService } from 'app/@core/services/lead-site.service';
import { ConfigurationService } from 'app/@core/services/configuration.service';
import { UpdateLeadAnnualUsageObject } from 'app/@core/models/lead.model';
import { LeadSiteObject, LeadSiteTableObject, ProposalSiteUpdateObject} from 'app/@core/models/lead-site.model';
import { FieldObject } from 'app/@core/models/shared.model';
import { NoteService } from 'app/@core/services/note.service';
import { VendorSiteColumnOrder } from '../../../../@core/models/vendor-site-column-order.model';
import { VendorService } from 'app/@core/services/vendor.service';
import { ProposalService } from 'app/@core/services/proposal.service';
import { SharedService } from '../../shared.service';
import { StateLookupObject } from 'app/@core/models/lookup.model';
import { LookupService } from 'app/@core/services/lookup.service';

@Component({
  selector: 'ngx-proposal-site-editor',
  templateUrl: './proposal-site-editor.component.html',
  styleUrls: ['./proposal-site-editor.component.scss']
})
export class ProposalSiteEditorComponent extends BaseComponent implements OnInit, AfterViewInit, OnDestroy {

  /**
   * ViewChild/ViewChildren
   */
  @ViewChildren('editor') editor: QueryList<any>;
  @ViewChild('table') table: ElementRef;

  /**
   * Inputs/Outputs
   */
  @Input() proposal: ProposalObject;
  @Input() leadId: number;
  @Input() vendorId: number;
  @Input() customConvert: boolean;
  @Input() includeCustomFields: boolean = false;
  @Input() editable: boolean = false
  @Input() title: string = this.translate('SITE_ASSOCIATEDSITES');
  @Input() waitingForConfirmation: boolean = false;
  @Input() ongoingSignedProposals: Array<ProposalObject>;
  @Input() isCompletedProposal: boolean = false;
  @Input() loadProposalSites: boolean;
  @Input() isNewProposal: boolean;
  @Input() callInitContentFunction: boolean;
  @Input() attachedDocuments: Array<any> = [];
  @Input() openedFromProposal: boolean;
  @Input() showSitesInTwoTables: boolean = false;

  /**
   * Public variables
   */
  schemaColumns: Array<ProposalSiteEditorColumnDefinitionObject> = new Array<ProposalSiteEditorColumnDefinitionObject>();
  leadSites: Array<LeadSiteTableObject> = new Array<LeadSiteTableObject>();
  proposalSites: Array<LeadSiteObject> = new Array<LeadSiteObject>();
  displayedSites: Array<LeadSiteTableObject> = new Array<LeadSiteTableObject>();
  selectedLeadSites: Array<LeadSiteTableObject> = new Array<LeadSiteTableObject>();
  selectedAllDevidedLeadSites: boolean = true;
  unSelectedLeadSites: Array<LeadSiteTableObject> = new Array<LeadSiteTableObject>();
  unselectedAllDevidedLeadSites: boolean = false;
  leadAnnualUsage: number;
  isAnnualUsageOverriden: boolean = false;
  _shouldOverrideCheck: boolean = false;
  loading: boolean = false;
  readOnly: boolean = false;
  selectAllChecked: boolean = false;
  overrideAnnualUsage: boolean = false;
  customFields: Array<FieldObject> = new Array<FieldObject>();
  editedValue: string | Date | number;
  lookupValues: Array<any> = new Array<any>(); //to be changed, low priority
  pristineValue: string | Date | number;
  isDirty: boolean = false;
  showCustomFields: boolean = false;
  leadCurrentAnnualUsage: number;
  isDataModified: boolean = true;
  updLeadAnnUsageBtnSpinner: boolean = false;
  overridenAnnualUsage: number;
  proposalCurrentAnnualUsage: number;
  isUpdateLead: boolean = false;
  hideCell: boolean = false;
  tableColsVisibility: Array<boolean> = new Array<boolean>();
  savingLeadSiteDetails: boolean = false;
  updateSpinner: boolean = false
  listSelectedSites: Array<LeadSiteTableObject> = new Array<LeadSiteTableObject>();
  listSitesChanged: boolean = false;
  states: Array<StateLookupObject> = [];
  originalStateCode: string;
  loadDevidedTable: boolean = false;

  /**
   * Private variables
   */
  private _allSchemaColumns: Array<ProposalSiteEditorColumnDefinitionObject> = [
    {
      PropertyName: 'PropertyName',
      Title: 'Name',
      Format: FieldType.Text,
      ColumnId: -1,
      IsExcluded: false
    },
    {
      PropertyName: 'Address',
      Title: 'Address',
      Format: FieldType.Text,
      ColumnId: -2,
      IsExcluded: false
    },
    {
      PropertyName: 'City',
      Title: 'City',
      Format: FieldType.Text,
      ColumnId: -3,
      IsExcluded: false
    },
    {
      PropertyName: 'State',
      Title: 'State',
      Format: FieldType.Select,
      ColumnId: -4,
      IsExcluded: false
    },
    {
      PropertyName: 'ZipCode',
      Title: 'Zip Code',
      Format: FieldType.Text,
      ColumnId: -5,
      IsExcluded: false
    },
    {
      PropertyName: 'AccountNumber',
      Title: 'Account Number',
      Format: FieldType.Text,
      ColumnId: -6,
      IsExcluded: false
    },
    {
      PropertyName: 'AnnualUsage',
      Title: 'Annual Usage',
      Format: FieldType.Numeric,
      Precision: 0,
      ReadOnly: this.readOnly,
      ColumnId: -7,
      IsExcluded: false
    },
    {
      PropertyName: 'EndDate',
      Title: 'End Date',
      Format: FieldType.Date,
      ColumnId: -8,
      IsExcluded: false
    }
  ];

  constructor(
    public dialogRef: NbDialogRef<ProposalSiteEditorComponent>,
    public formatterService: CellFormatterService,
    private _configurationService: ConfigurationService,
    private _leadSiteService: LeadSiteService,
    private _proposalService: ProposalService,
    private _leadService: LeadService,
    private _noteService: NoteService,
    private _toastrService: NbToastrService,
    private _vendorService: VendorService,
    private _decimalPipe: DecimalPipe,
    private _cdrRef: ChangeDetectorRef,
    private _sharedService: SharedService,
    private  _lookupService: LookupService,
  ) {
    super()
  }

  // -----------------------------------------------------------------------------------------------------
  // @ Lifecycle hooks
  // -----------------------------------------------------------------------------------------------------

  /**
   * On Init
   */
  ngOnInit(): void {
    this.schemaColumns = this._allSchemaColumns;

    this.readOnly =this.isNewProposal?false:
     (this.proposal.Status == ProposalStatus.Confirmed || this.proposal.Status == ProposalStatus.DeletedVisible);

    this.loadProposalSites ? this.getProposalSites() : this.getLeadSites();

    if (this.isCompletedProposal) {
      return
    }

    this.getCurrentAnnualUsage();
    this._getStatesList();
  }

  /**
   * After View Init
   */
  ngAfterViewInit(): void {
    this.subs.add(
      this.editor.changes.subscribe((d: QueryList<any>) => {
        if (d.length) {
          if (d.first instanceof NumericInputComponent) {
            d.first.focus()
          } else if (d.first instanceof NgSelectComponent) {
            d.first.focus()
          } else if (d.first instanceof ElementRef) {
            d.first.nativeElement.focus()
          }
        }
      })
    )

    this._cdrRef.detectChanges();
  }

  /**
   * On Destroy
   */
  ngOnDestroy(): void {
    this._unsubscribeAll.next();
    this._unsubscribeAll.complete();
  }

  // -----------------------------------------------------------------------------------------------------
  // @ Private methods
  // -----------------------------------------------------------------------------------------------------

  /**
   * Function to init content
   */
  private _initContent() {
    let sitesAnnualUsage = 0;

    //Set sites Annual usage and isSelected properties
    if(!this.isNewProposal){
      this.displayedSites.forEach(site => {
        let proposalSite=this.proposal.ProposalSites.find((x:ProposalSiteObject) => x.SiteId == site.Id);
        site.AnnualUsage=proposalSite? proposalSite.AnnualUsage:site.AnnualUsage;
        sitesAnnualUsage +=proposalSite? proposalSite.AnnualUsage:0;
      })
    }

    if (this.isNewProposal) {
        // Calculate the total annual usage of all displayed sites
        let allSitesAnnualUsage = this.displayedSites.reduce((total, site) => total + (site.AnnualUsage || 0), 0);

        // Check if the lead annual usage is different from the total and individual site annual usages
        this.isAnnualUsageOverriden = this.leadAnnualUsage !== sitesAnnualUsage && this.leadAnnualUsage !== allSitesAnnualUsage;
    }

    // Set the overridden annual usage based on the check
    this.overridenAnnualUsage = this.isAnnualUsageOverriden ? this.leadAnnualUsage : null;

    // Update the lead annual usage based on the check
    this.leadAnnualUsage = this.isAnnualUsageOverriden ? null : sitesAnnualUsage;

    this.overrideAnnualUsage =this.proposal? (this.proposal.AnnualUsage > 0):false;

    this.selectAllChecked = !this.readOnly && this.displayedSites.every((x) => x.isSelected)

    this.loading = false;

    if(this.isNewProposal){
      return
    }

    this.onSiteSelectionChanged();
    this.shouldOverrideCheck();
  }

  /**
   * Function to internal submit dialog data
   */
  private _submitInternal(closeDialog: boolean,updateLeadSites:boolean = false) {
    if(this.isNewProposal){
      this.proposal=Object.assign(new ProposalObject(),{Sites:this.listSelectedSites});
      if(updateLeadSites) {
       this.saveLeadSites();
      }

      this.updateProposalSelectedSites();
      this.dialogRef.close(this.proposal);
      return;
    }

    //If this is update of the lead sites
    this.proposal.CallUsageApi = false;
    this.proposal.updateLeadSites=updateLeadSites;

    this.proposal.Sites = this.displayedSites.filter((x) => x.isSelected);
    if (this.proposal.Status == 6) {
      this.proposal.Status = 7;
    }

    if (this.proposal.Status == 1 && !this.isUpdateLead) {
      this.proposal.AnnualUsage = this.leadSitesSumAnnualUsage();
    }

    if (this.customConvert) {
      this.proposal.AnnualUsage = this.leadCurrentAnnualUsage;
    }

    this.proposal.RemoveSigned = true;
    if (closeDialog) {
      this.updateProposalSelectedSites();
      this.dialogRef.close(this.proposal)
    }

    this.shouldOverrideCheck()
  }

  /**
   * Function to save lead sites
   */
  private saveLeadSites() {
    this._leadSiteService.saveLeadSites(this.leadId, this.proposalSites).pipe(take(1)).subscribe({
      next: (response: string) => {
        this._toastrService.success(response);
      },
      error: (error) => {
        this._toastrService.danger(error.error.Content);
      }
    })
  }

  /**
   * Function to pipe date to string without time zone
   */
  private _toISOStringWithoutTimeZone(date) {
    let tzOffset = date.getTimezoneOffset()
    return new Date(date.getTime() - tzOffset * 60 * 1000).toISOString().replace('.000Z', '')
  }

  /**
   * Function to check is calendar node
   */
  private _isCalendarNode(element) {
    let currentElement = element
    while (currentElement.parentNode) {
      if (currentElement.parentNode.nodeName == 'NB-DATEPICKER-CONTAINER') return true
      currentElement = currentElement.parentNode
    }

    return false
  }

  /**
   * Function to get proposal statuses
   */
  get proposalStatuses(): typeof ProposalStatus {
    return ProposalStatus;
  }

  // -----------------------------------------------------------------------------------------------------
  // @ Public methods
  // -----------------------------------------------------------------------------------------------------

  /**
   * Function to get site custom fields
   */
  getSiteCustomFields() {
    this.loading = true;
    this._configurationService.getCustomFields(EntityType.Site).pipe(take(1)).subscribe({
      next: (response: Array<FieldObject>) => {
        this._allSchemaColumns = this._allSchemaColumns.concat(
          response.map((x) => {
            return {
              PropertyName: `${FIELD_PREFIX}${x.FieldId}`,
              Title: x.Name,
              Format: x.FieldType,
              AdditionalParameters: x.AdditionalParameters,
              ColumnId: x.FieldId,
              IsExcluded: true
            }
          })
        );
        if(this.vendorId){
          this.getVendorSitesTableColumns();
        }
        this.loading = false;
      },
      error: (error) => {
        this._toastrService.danger(error.error.Content);
      }
    })
  }

  /**
   * Function to get lead sites
   */
  getLeadSites() {
    this.loading = true;
    const selectedSites = this._leadSiteService.getSelectedSites();

    this._leadSiteService.getLeadSites(this.leadId || this.proposal.LeadId).pipe(take(1)).subscribe({
      next: (response: Array<LeadSiteTableObject>) => {
        if (this.showSitesInTwoTables) {
          response.forEach(el => {
            let findSite = this.proposal.ProposalSites.find(e => el.Id === e.SiteId);
            findSite ? this.selectedLeadSites.push(findSite) : this.unSelectedLeadSites.push(el);
          })

          this.selectedLeadSites.map(el => el.isSelected = true)

          this.loadDevidedTable = true;
          this.loading = false;
        } 
        else {
          this.leadSites =response.map((x:LeadSiteTableObject) => {
            x.isSelected = this.isNewProposal ? (selectedSites.length > 0 && (selectedSites.some((site: LeadSiteTableObject) => site.LeadId === this.leadId)) ? selectedSites.some((site: LeadSiteTableObject) => site.Id === x.Id) : true) :
            (this.proposal.ProposalSites ? this.proposal.ProposalSites.some((y: ProposalSiteObject) => y.SiteId == x.Id) : false);
            return x;
          });
          //By default displayed sites are the lead sites
          this.displayedSites = this.leadSites;

          if (!this.isNewProposal) {
            this.displayedSites.forEach(site => {
              let findSelected = this.attachedDocuments.find(el => el.Id == site.Id || el.SiteId == site.Id);
              site.isSelected = findSelected ? true : false;
            })
          }

          this.recalculateAnnualUsage();
          this.selectAllChecked = this.displayedSites.every((x) => x.isSelected);

          //In case of new proposal by default all the lead sites are the proposal sites
          this.proposalSites=this.isNewProposal?response:this.proposalSites;


          // // Find preselected sites
          // if (this.attachedDocuments && this.attachedDocuments.length > 0) {
          //   this._leadSiteService.setSelectedSites(this.attachedDocuments);
          //   this.listSelectedSites = this.displayedSites.filter(item1 => this.attachedDocuments.some(item2 => item2.Id === item1.Id));
          // }
          this.loading = false;

          this.recalculateAnnualUsage();

          if (this.callInitContentFunction) {
            this._initContent();
          }

          if (this.includeCustomFields) {
            this.getSiteCustomFields();
            return;
          }

          this._initContent();
        }
      },
      error: (error) => {
        this._toastrService.danger(error.error.Content);
        this.loading = false;
      }
    })
  }

  /**
   * Function to get proposal sites
   */
  getProposalSites(): void {
    this.loading = true;
    this._proposalService.getProposalSites(this.proposal.Id).pipe(take(1)).subscribe({
      next: (response: Array<LeadSiteObject>) => {
        this.proposalSites = response;
        this.getLeadSites();
      },
      error: (error) => {
        this._toastrService.danger(error.error.Content);
        this.loading = false;
      }
    })
  }

  /**
   * Function to get current annual usage
   */
  getCurrentAnnualUsage() {
    this.loading = true;
    this._leadService.getCurrentAnnualUsage(this.leadId).pipe(take(1)).subscribe({
      next: (response: number) => {
        this.leadAnnualUsage = response;
      },
      error: (error) => {
        this._toastrService.danger(error.error.Content);
      }
    })
  }

  /**
   * Function to handle toggle event
   */
  handleToggleEvent(event): void {
    if (!event) {
      this.overridenAnnualUsage = 0;
      this.updateLeadAnnualUsage(false);
    }
  }

  /**
   * Function to select all sites
   */
  selectAll(event) {
    this.displayedSites.forEach((x) => (x.isSelected = this.selectAllChecked))
    this.isDirty = true;
    this.checkSitesLeadChange()
  }

  /**
   * Function to submit Proposal Lead Site Details
   */
  submit(result: boolean) {
    this.checkSitesLeadChange();
    this.updateSpinner = true;
    if(this.isNewProposal){
      this._submitInternal(true, result);
      return;
    }

    (!this.isDirty || !result) && !this.listSitesChanged?this._submitInternal(true,result): this.saveLeadSiteDetails(true, true,result);
  }

  /**
   * Function to handle site selection change
   */
  onSiteSelectionChanged() {
    this.selectAllChecked = this.leadSites.every((site) => site.isSelected);

    this.checkSitesLeadChange()
    this.recalculateAnnualUsage();
  }

  /**
   * Function to check sites lead change
   */
  checkSitesLeadChange() {
    this.listSelectedSites =this.displayedSites.map((x:LeadSiteTableObject) => {
      x.isSelected =x.isSelected || (x.pristineValues && x.pristineValues.length > 0)?true:false;
      x.IsProposalSite=x.isSelected;
      return x;
    }).filter(x => x.isSelected);

    let listSelectedSitesIds=this.listSelectedSites.filter(x => x.isSelected).map(({ Id }) => { return Id; });
    let proposalSiteIds=this.isNewProposal
      ?this._leadSiteService.getSelectedSites().map(({ Id }) => Id)
      :this.proposal.ProposalSites.map(({ SiteId}) => { return SiteId; });


    this.listSitesChanged = JSON.stringify(proposalSiteIds) == JSON.stringify(listSelectedSitesIds)?false:true;
  }

  /**
   * Function to recalculate annual usage
   */
  recalculateAnnualUsage() {
    this.leadCurrentAnnualUsage = 0;
    if (this.isAnnualUsageOverriden) {
      this.leadCurrentAnnualUsage = this.leadAnnualUsage;
    }
    else {
      let selectedSites = this.displayedSites.filter(x => x.isSelected);
      selectedSites.forEach(site => {
        this.leadCurrentAnnualUsage += site.AnnualUsage===null?0:site.AnnualUsage;
      });
    }
  }

  /**
   * Function to sum lead sites annual usage
   */
  leadSitesSumAnnualUsage(): number {
    let sumAnnualUsage = 0;
    let selectedSites = this.displayedSites.filter(x => x.isSelected);
    selectedSites.forEach(site => {
      sumAnnualUsage += site.AnnualUsage
    });

    return sumAnnualUsage;
  }

  /**
   * Function to pipe annual usage
   */
  pipeTotalAnnualUsage() {
    if (!this.leadCurrentAnnualUsage) return this.leadCurrentAnnualUsage

    return this._decimalPipe.transform(this.parseValue(this.leadCurrentAnnualUsage), '1.0-10') || ''
  }

  /**
   * Function to handle on cell edit event
   */
  onCellEdit(event, site, column) {
    if (!this.isEditable(site, column) || site.edit === column.PropertyName) return

    this.saveEditedData()

    this.pristineValue = this.formatterService.getRawColumnValue(site, column)
    this.editedValue = this.pristineValue

    if (this.editedValue && column.Format == FieldType.Date && !(this.editedValue instanceof Date)) {
      this.editedValue = parseISO(this.editedValue.toString())
    }

    if (column.Format == FieldType.Select) {
      this.lookupValues = (column.AdditionalParameters || '').split('\n')
      if(column.PropertyName === "State") {
        this.lookupValues = this.states.map(state => state.Name);
        this.editedValue = this.states.find(state => state.Code === this.editedValue)?.Code;
        this.originalStateCode = this.editedValue;
      }
    }

    site.edit = column.PropertyName;
    site[column.Title] = this.editedValue;
    site.editName = column.Title;
    this.isDataModified = true;
  }

  /**
   * Function to handle click event outside of the edited cell
   */
  onClickOutsideEditedCell(event, site, column) {
    if (event.classList.contains('editable') || this._isCalendarNode(event)) return
    this.saveEditedData()
  }

  /**
   * Functionto update lead annual usage
   */
  updateLeadAnnualUsage(closeDialog: boolean) {
    this.loading = true;
    this.isUpdateLead = true;

    this.updLeadAnnUsageBtnSpinner = true;

    let request: UpdateLeadAnnualUsageObject = new UpdateLeadAnnualUsageObject(
      this.leadId,
      this.proposal.AnnualUsage ? this.overridenAnnualUsage : 0
    );

    if (this.showSitesInTwoTables) {
      request.AnnualUsage = this.proposal.AnnualUsage;
    }

    this._leadService.updateLeadAnnualUsage(request).pipe(take(1)).subscribe({
      next: (response: string) => {
        this._noteService.refreshNotes.next(true);
        this._toastrService.success(response);
        this.updLeadAnnUsageBtnSpinner = false;
        this.overrideAnnualUsage = false;
        this.leadCurrentAnnualUsage = 0;
        if (this.overridenAnnualUsage && this.overridenAnnualUsage != 0) {
          this.leadAnnualUsage = this.overridenAnnualUsage;
          this.isAnnualUsageOverriden = true;
          this.leadCurrentAnnualUsage = this.overridenAnnualUsage;
        }
        else {
          this.isAnnualUsageOverriden = false;
          let selectedSites = this.displayedSites.filter(x => x.isSelected);
          selectedSites.forEach(site => {
            this.leadCurrentAnnualUsage += site.AnnualUsage
          });
        }

        this.shouldOverrideCheck();

        this.loading = false;
        if (closeDialog) {
          this.submit(true);
        }
      },
      error: (error) => {
        this._toastrService.danger(error.error.Content);
        this.updLeadAnnUsageBtnSpinner = false;
        this.loading = false;
      }
    });
  }

  /**
   * Function to save lead site details
   */
  saveLeadSiteDetails(closeDialog: boolean, showAlert: boolean,updateLeadSites:boolean) {
    this.savingLeadSiteDetails = true
    this.isUpdateLead = false;

    this.saveEditedData();
    //Update the list and values of the proposal sites
    let sitesRequest:ProposalSiteUpdateObject=new ProposalSiteUpdateObject(updateLeadSites,this.listSelectedSites);
    this._leadSiteService.saveProposalSites(this.leadId,this.proposal.Id,sitesRequest).pipe(take(1)).subscribe({
      next: (response: string) => {
        if (showAlert) {
          this._toastrService.success(response);
        }
        if (this.isDirty) {
          this.savingLeadSiteDetails = false
          this._submitInternal(false,false);
        }
        else {
          this.getLeadSites();

          this.isDirty = false;
          this.recalculateAnnualUsage();

          this.proposalCurrentAnnualUsage = 0;
          this.displayedSites.forEach(site => {
            this.proposalCurrentAnnualUsage += site.AnnualUsage;
          });
          this.savingLeadSiteDetails = false;
          this.loading = false;
        }

        this._sharedService.refreshManageTable.next();

        if (closeDialog) {
          this.updateProposalSelectedSites();
          this.dialogRef.close(this.proposal);
        }
      },
      error: (error) => {
        this._toastrService.danger(error.error.Content);
        this.savingLeadSiteDetails = false;
        this.loading = false;
      }
    });
  }

  /**
   * Function to save edited data
   */
  saveEditedData() {
    if (this.editedValue instanceof Date) {
      this.editedValue = this.editedValue ? this._toISOStringWithoutTimeZone(this.editedValue) : null;
    }

    this.leadSites.filter((x) => x.edit).forEach((site) => {
      if (site.edit.startsWith(FIELD_PREFIX)) {
        let fieldId = site.edit.replace(FIELD_PREFIX, '')
        let fieldName = site.editName;
        let field = (site.Fields || []).find((x) => x.FieldId == fieldId)
        if (field) {
          field.Value = this.editedValue ? this.editedValue.toString() : null;
          field.FieldName = fieldName;
        } else {
          site.Fields = site.Fields || []
          site.Fields.push({ FieldId: fieldId, Value: this.editedValue ? this.editedValue.toString() : null, FieldName : fieldName })
        }
      } else {
        if (site.edit === 'State') {
          let stateObj = this.states.find(state => state.Name === this.editedValue);
          this.editedValue = stateObj ? stateObj.Code : this.originalStateCode;
        }
        site[site.edit] = this.editedValue;
      }

      if ((this.editedValue || this.pristineValue) && this.editedValue != this.pristineValue) {
        site.pristineValues = site.pristineValues || []
        if (!site.pristineValues.some((x) => x.PropertyName == site.edit))
          site.pristineValues.push({ PropertyName: site.edit, Value: this.pristineValue })

        this.isDirty = true;
        this.isDataModified = true;
      }
      else {
        this.isDataModified = false;
      }

      if (site.edit == 'AnnualUsage') {
        this.recalculateAnnualUsage()
      }

      site.edit = null
    })
  }

  /**
   * Function to handle numeric input change
   */
  onNumericInputChange(event) {
    this.isDirty = true
    this.editedValue = event

    if (!this.editedValue) return this.editedValue

    return this.editedValue ? this._decimalPipe.transform(this.parseValue(this.editedValue), '1.0-10') || '' : null
  }

  /**
   * Function to validate input
   */
  validateNumericInput(event: any, i: any, j: any) {
    const inputValue = event.target.value.replace(/[^\d]/g, ''); // Remove any non-digit characters;
    event.target.value = inputValue;

    if (i != null && j != null && this.showSitesInTwoTables) {
      this.selectedLeadSites[i].AnnualUsage = inputValue;
      this.proposal.AnnualUsage = inputValue;
    }
  }

  /**
   * Function to handle date time input change event
   */
  onDateTimeInputChange(event) {
    this.isDirty = true
    this.editedValue = event
  }

  isEditable(site, column) {
    return this.editable && !column.readOnly
  }

  isModified(site, column) {
    return site.pristineValues && site.pristineValues.some((x) => x.PropertyName == column.PropertyName);
  }

  /**
   * Function to toggle custom fields visibility
   */
  toggleCustomFieldVisibility() {
    this.showCustomFields = !this.showCustomFields
    if (this.showCustomFields) {
      this.schemaColumns = this._allSchemaColumns
    } else {
      this.schemaColumns = this._allSchemaColumns.filter(x => !x.IsExcluded);
      // this.schemaColumns = this._allSchemaColumns.filter((x) => !x.PropertyName.startsWith(FIELD_PREFIX))
    }
  }

  /**
   * Function to close dialog
   */
  closeDialog() {
    if(this.isNewProposal){
      this.dialogRef.close();
      return;
    }

    this.updateProposalSelectedSites();

    this.proposal.CallUsageApi = false;
    if (this.proposal.Status == 6) {
      this.proposal.Status = 7;
    }

    if (this.proposal.Status == 1) {
      this.proposal.AnnualUsage = this.isUpdateLead ? this.leadCurrentAnnualUsage : this.leadSitesSumAnnualUsage();
    }


    this.dialogRef.close(this.proposal)
  }

  updateProposalSelectedSites() {
    this.proposal.ProposalSites = [];
    this.proposal.Sites = [];

    this.displayedSites.forEach(site => {
      if (site.isSelected) {
        this.proposal.Sites.push(site);
        this.proposal.ProposalSites.push(site); 
      }
    })
    this.attachedDocuments = this.proposal.Sites;
    this._leadSiteService.setSelectedSites(this.displayedSites.filter((x) => x.isSelected));
  }

  /**
   * Function to parse value
   */
  parseValue(value: any) {
    return value ? parseFloat(value.toString().replaceAll(',', '')) : value;
  }

  /**
   * Function to get vendor's site table columns order
   */
  getVendorSitesTableColumns(): void {
    this._vendorService.getVendorsSiteTableColumnsOrder(this.vendorId).pipe(take(1)).subscribe({
      next: (data: Array<VendorSiteColumnOrder>) => {
        if (data.length > 0) {
          this._allSchemaColumns = data.map((x) => {
            return {
              PropertyName: x.PropertyName,
              Title: x.Title,
              Format: x.FieldType,
              ColumnId: x.ColumnId,
              IsExcluded: x.IsExcluded,
              AdditionalParameters: x.AdditionalParameters ? x.AdditionalParameters : this.getAdditionalParameters(x.PropertyName),
            }
          });
        }

        this.schemaColumns = this._allSchemaColumns.filter(x => !x.IsExcluded);
        this._initContent();
      },
      error: (error) => {
        this._toastrService.danger(error.error.Content);
      }
    })
  }

  /**
 * Function to get additional parameters from schema columns based on property name
 */

  getAdditionalParameters(propertyName: string): any {
    const index = this._allSchemaColumns.findIndex(x => x.PropertyName === propertyName);
    return index !== -1 ? this._allSchemaColumns[index]?.AdditionalParameters : null;
  }

 /**
 * Function to check if any cell is modified
 */
  isAnyCellModified(): boolean {
    return this.displayedSites.some(site => {
      return this.schemaColumns.some(column => this.isModified(site, column));
    });
  }

   /**
 * Function to check if annual usage should be overriden
 */
  shouldOverrideCheck(){
    this._shouldOverrideCheck=this.isNewProposal?false:
     (this.isAnnualUsageOverriden && this.proposal.Status != this.proposalStatuses.WaitingForConfirmation)
  }

  /**
   * Function to validate entering only numbers
   */
  validateInput(column: any, event: KeyboardEvent, site: LeadSiteTableObject) {
    if(column.PropertyName === 'ZipCode') {
      const pattern = /[0-9]/;
      if (!pattern.test(event.key)) {
        event.preventDefault();
      }
    } else if(column.PropertyName === 'AccountNumber') {
      const state = this.states.find(state => state.Code === site.State);
      if (state && state.AccountNumberLimit) {
        const inputValue = (event.target as HTMLInputElement).value;
        const accountNumberLimit = state.AccountNumberLimit;
        if (inputValue.length >= accountNumberLimit) {
          event.preventDefault();
        }
      }
    }
  }

   /**
   * Function to check input value if it is less than 5 digits set it to empty string
   */
  checkInputValue(column: any, site: LeadSiteTableObject) {
    if(column.PropertyName === 'ZipCode') {
      if (this.editedValue.toString().length != 5) {
        this.editedValue = '';
      }
    } else if (column.PropertyName === 'AccountNumber') {
      const state = this.states.find(state => state.Code === site.State);
      if (state && state.AccountNumberLimit) {
          const accountNumberLimit = state.AccountNumberLimit;
          if (this.editedValue.toString().length != accountNumberLimit) {
              this.editedValue = '';
          }
      }
    }
  }

  /**
   * Function to prevent pasting anything but 5 maximum digits to zipcode input field
   */
   validatePaste(column: any, event: ClipboardEvent, site: LeadSiteTableObject): void {
    if(column.PropertyName === 'ZipCode') {
      event.preventDefault();
      const pastedNumbers = event.clipboardData.getData('text/plain').replace(/[^\d]/g, ''); // Remove non-numeric characters
      const pastedValue = pastedNumbers.substring(0, 5); // Take only the first 5 numbers

      this.editedValue = pastedValue;
    } else if (column.PropertyName === 'AccountNumber') {
      const state = this.states.find(state => state.Code === site.State);
      if (state && state.AccountNumberLimit) {
        event.preventDefault();
        const pastedNumbers = event.clipboardData.getData('text/plain').replace(/[^\d]/g, ''); // Remove non-numeric characters
        const pastedValue = pastedNumbers.substring(0, state.AccountNumberLimit); // Take only the first `AccountNumberLimit` numbers

        this.editedValue = pastedValue;
      }
    }
  }

  /**
   * Function to check if at least one property has been selected
   */
   ifAnySiteIsSelected(): boolean {
    return this.displayedSites.some(site => site.isSelected);
  }

  /**
 * Function to get states list
 */
  _getStatesList(): void {
    this._lookupService.getStates().pipe(take(1)).subscribe({
      next: (response: Array<StateLookupObject>) => {
        this.states = response;
      },
      error: (error) => {
        this._toastrService.danger(error.error.Content);
      }
    })
  }

   /**
 * Function to get tooltip message
 */
  getTooltipMessage(column: any, site: LeadSiteTableObject): string {
    if (column.PropertyName === 'AccountNumber') {
      const state = this.states.find(state => state.Code === site.State);
      if (state && state.AccountNumberLimit) {
        return `Account Number must be ${state.AccountNumberLimit} digits`;
      }
    }

    return column.PropertyName === 'ZipCode' ? 'Zip code must be 5 digits' : '';
  }

 /**
 * Function to check if account number is valid
 */
  isValidInputNumber(site: LeadSiteTableObject, column: any): boolean {
    if (column.PropertyName === 'AccountNumber' || column.PropertyName === 'ZipCode') {
      if (column.PropertyName === 'AccountNumber') {
        const state = this.states.find(state => state.Code === site.State);
        if (state && state.AccountNumberLimit) {
          const accountNumberLimit = state.AccountNumberLimit;
          return (site[column.PropertyName] || '').toString().length === accountNumberLimit;
        }
      } else if (column.PropertyName === 'ZipCode') {
        const zipCode = (site[column.PropertyName] || '').toString();
        return zipCode.length === 5;
      }
    }
    return true; // Return true for other columns
  }

  unselectAllSites(): void {
    this.selectedLeadSites.forEach(site => {
        site.isSelected = false;
        this.unSelectedLeadSites.push(site);
    });
    this.selectedLeadSites = [];
    this.selectedAllDevidedLeadSites = false;
    this.unselectedAllDevidedLeadSites = false;
  }

  selectAllSites(): void {
      this.unSelectedLeadSites.forEach(site => {
          site.isSelected = true;
          this.selectedLeadSites.push(site);
      });
      this.unSelectedLeadSites = [];
      this.selectedAllDevidedLeadSites = true;
      this.unselectedAllDevidedLeadSites = false;
  }

  toggleSiteSelection(isSelected: any, id: number): void {
      let foundSiteIndex = this.selectedLeadSites.findIndex(site => site.Id === id);
      let siteArray = this.selectedLeadSites;

      if (foundSiteIndex === -1) {
          foundSiteIndex = this.unSelectedLeadSites.findIndex(site => site.Id === id);
          siteArray = this.unSelectedLeadSites;
      }

      if (foundSiteIndex !== -1) {
          siteArray[foundSiteIndex].isSelected = isSelected.target.checked;
          if (isSelected.target.checked) {
              this.selectedLeadSites.push(siteArray[foundSiteIndex]);
              this.unSelectedLeadSites.splice(foundSiteIndex, 1);
          } else {
              this.unSelectedLeadSites.push(siteArray[foundSiteIndex]);
              this.selectedLeadSites.splice(foundSiteIndex, 1);
          }
      }

      if (this.selectedLeadSites.length == 0) {
          this.selectedAllDevidedLeadSites = false;
      } else {
          this.selectedAllDevidedLeadSites = true;
      }
      this.unselectedAllDevidedLeadSites = false;
  }

}

