import { NbMenuItem } from "@nebular/theme";
import { ProposalStatus } from "../enums/proposal-status";
import { LeadSiteObject } from "./lead-site.model";
import { ContactObject } from "./lead.model";
import { ProductObject } from "./product.model";
import { DocumentSource } from "../enums/document-source";
import { FieldObject } from "./shared.model";

export class ProposalObject {
  constructor(
    public AnnualUsage?: any,
    public CompanyPrice?: number | null,
    public ConfirmationDate?: string | Date | null,
    public CreatedDate?: string | Date | null,
    public CreatorEmail?: string | null,
    public CreatorId?: number | null,
    public CreatorName?: string | null,
    public CustomConvertDocumentId?: number | null,
    public DisplayProductComparison?: boolean | null,
    public DisplayProductPrice?: boolean | null,
    public EffectiveAnnualUsage?: number | null,
    public Email?: string | null,
    public EndDate?: string | Date | null,
    public EnvelopeId?: string | null,
    public EnvelopeName?: string | null,
    public EnvelopeViewedDate?: string | Date | null,
    public ExistingCharge?: number | null,
    public ExpiryDate?: string | Date | null,
    public ExpressionTypeName?: string | null,
    public FinalPrice?: number | null,
    public FirstName?: string | null,
    public Id?: number | null,
    public LastName?: string | null,
    public LeadId?: number | null,
    public LeadName?: string | null,
    public MonthlyRevenue?: number | null,
    public MonthlyUnit?: number | null,
    public OrderId?: string | null,
    public PricingRequestType?: number | null,
    public PricingRequestTypeName?: number | null,
    public Product?: ProductObject,
    public ProductId?: number | null,
    public ProductName?: string | null,
    public ProposalDate?: string | Date | null,
    public ScheduleDate ?: string | Date | null,
    public SignedDate?: string | Date | null,
    public Signer?: ContactObject,
    public SignerId?: number | null,
    public SiteCount?: number | null,
    public Sites?: Array<LeadSiteObject>,
    public StartDate?: string | Date | null,
    public Status?: ProposalStatus | null,
    public StatusName?: string | null,
    public Term?: number | null,
    public TermRevenue?: number | null,
    public Title?: string | null,
    public VendorId?: number | null,
    public VendorName?: string | null,
    public VendorPrice?: number | null,
    public VpnGroup?: string | null,
    public VpnServerId?: number | null,
    public isQuerySelected?: boolean | null,
    public CallUsageApi?: boolean | null,
    public contextMenu?: Array<NbMenuItem>,
    public Transition?: any,
    public RemoveSigned?: boolean | null,
    public ProposalSites?:Array<LeadSiteObject>,
    public updateLeadSites?: boolean | null,
    public RemindIntervalValue ?: number | null,
    public RemindIntervalType ?: number | null,
    public HasReminder ?: boolean | null,
    public AttachedDocuments ?: string | null,
    public Request ?: any | null,
    public HasReminderFinalDate ?: boolean | null,
    public ReminderFinalDateType ?: number | null,
    public ReminderFinalDateValue ?: number | null,
  ){}
}
export class ProposalSiteObject {
  constructor(
    public AccountNumber?: string | null,
    public Address?: string | null,
    public AnnualUsage?: number | null,
    public BillingAddress?: string | null,
    public BillingCity?: string | null,
    public BillingState?: string | null,
    public BillingZipCode?: string | null,
    public City?: string | null,
    public CreatedDate?: string | Date | null,
    public CurrentVendorId?: number | null,
    public CurrentVendorName?: string | null,
    public EndDate?: string | Date | null,
    public ExcludedContractsEndDate?: string | Date | null,
    public Fields?: Array<FieldObject> | null,
    public HasCustomBillingAddress?: boolean | null,
    public Id?: number | null,
    public ProposalId?: number | null,
    public SiteId?: number | null,
    public OngoingProposals?: Array<ProposalObject> | null,
    public ConfirmedProposals?: Array<ProposalObject> | null,
    public Proposals?: Array<ProposalObject> | null,
    public StartDate?: string | Date | null,
    public State?: string | null,
    public ZipCode?: string | null,
    public displayText?: string | null
  ){}
}
export class UpdateProposalFeesObject {
  constructor(
    public Id?: number | null,
    public CompanyPrice?: number | null,
    public VendorPrice?: number | null
  ){}
}

export class CreateCustomProposalObject {
  constructor(
      public CompanyPrice?: number | null,
      public ConfirmationDate?: string | Date | null,
      public CreatedDate?: string | Date | null,
      public Email?: string | null,
      public EndDate?: string | null,
      public ExcludeFromReporting?: boolean,
      public ContactId?: number | null,
      public FirstName?: string | null,
      public LastName?: string | null,
      public MonthlyRevenue?: number | null,
      public ProductId?: number | null,
      public SignedDate?: string | Date | null,
      public StartDate?: string | Date | null,
      public Term?: number | null,
      public TermRevenue?: number,
      public Title?: string | null,
      public VendorPrice?: number | null,
      public LeadId?: number | null,
      public Sites?: Array<any>,
      public AnnualUsage?: number | null,
      public CustomConvertDocumentId?: number | null,
      public IsCustomConverted?: boolean,
      public DeleteSignedProposals?: boolean,
      public ProposalIdsToDelete: Array<number> = new Array<number>(),
      public RequestInitialFees?: boolean | null,
      public VendorId?: number | null
  ) { }
}

export class VendorConfirmationRequestObject {
  constructor(
    public ProposalId?: number | null,
    public Subject?: string | null,
    public Body?: string | null,
    public HasCustomAttachment?: boolean | null,
    public Attachments?: Array<VendorConfirmationAttachmentObject> | null,
    public Cc?: Array<string> | null
  ){}
}

export class VendorConfirmationAttachmentObject {
  constructor(
    public Id?: number | null,
    public Source?: DocumentSource | null,
  ){}
}

export class ProposalEnvelopeRename {
  constructor(
    public name ?: string | null,
    public envelopeId ?: string | null,
  ){}
}
