import { Component, OnInit, Input, TemplateRef } from '@angular/core';

@Component({
  selector: 'ngx-tooltip-view',
  templateUrl: './tooltip-view.component.html',
  styleUrls: ['./tooltip-view.component.scss']
})
export class TooltipViewComponent {

  @Input() text: string;
  @Input() contentTemplate: TemplateRef<any>;

}
