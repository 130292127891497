import { HttpClient } from '@angular/common/http'
import { Injectable } from '@angular/core'
import { NbDialogService } from '@nebular/theme'
import { ConfirmRefreshDialogComponent } from 'app/pages/shared/confirm-refresh-dialog/confirm-refresh-dialog.component'
import { Subject } from 'rxjs-compat'
import { tap } from 'rxjs/operators'

import { environment } from './../../../environments/environment'

@Injectable()
export class TranslateService {
  dictionaryKey: string = 'user_dictionary'
  allItems: any

  isInitialized: Promise<any>

  inInitFinish: Subject<boolean> = new Subject();

  constructor(
    private http: HttpClient,
    private dialogService: NbDialogService,
    ) {}

  init() {
    this.isInitialized =
      this.isInitialized ||
      new Promise((resolve, reject) => {
        let storedDictionary = localStorage.getItem(this.dictionaryKey)
        if (storedDictionary) {
          this.allItems = JSON.parse(storedDictionary);
          resolve(this.allItems)
        }

        this.http.get(`${environment.baseApiUrl}/user/dictionary`).toPromise().then((result: any) => {
          if (storedDictionary) {
            result.forEach(el => {
              delete el.CreatedDate;
            })
            let _resultStr = JSON.stringify(result)
            if (_resultStr != storedDictionary) {
              this.allItems = result;
              localStorage.setItem(this.dictionaryKey, JSON.stringify(result))

              this.confirmRefresh();
            } else {
              this.inInitFinish.next(false);
              this.allItems = JSON.parse(storedDictionary);
            }
          } else {
            this.allItems = result;

            localStorage.setItem(this.dictionaryKey, JSON.stringify(result))

            this.confirmRefresh();
          }
          resolve(result)
          return this.isInitialized
        })
      })
    return this.isInitialized
  }

  private resetCache(result) {
    this.allItems = result
    localStorage.setItem(this.dictionaryKey, JSON.stringify(result))
    this.isInitialized = undefined;
    this.inInitFinish.next(true);
    this.init();
  }

  factoryReset(prefix = null) {
    return new Promise((resolve, reject) => {
      localStorage.removeItem(this.dictionaryKey)
      this.http.delete(`${environment.baseApiUrl}/user/dictionary?prefix=${prefix}`).subscribe((result: any) => {
        this.resetCache(result)
        resolve(result)
      })
    })
  }

  reInit() {
    localStorage.removeItem(this.dictionaryKey)
    this.isInitialized = null
    return this.init()
  }

  getAll() {
    return JSON.parse(localStorage.getItem(this.dictionaryKey))
  }

  translate(key) {
    if (!this.allItems) {
      this.init()
      return key
    }

    let dictionaryItem = this.allItems.find((di) => di.Key == key)
    return dictionaryItem ? dictionaryItem.Value : key
  }

  update(items) {
    return this.http.put(`${environment.baseApiUrl}/user/dictionary`, items).pipe(
      tap((result) => {
        this.resetCache(result)
      })
    )
  }

  confirmRefresh() {
    window.location.reload();

    // this.dialogService
    // .open(ConfirmRefreshDialogComponent, {
    //   context: {
    //   },
    //   dialogClass: 'model-full'
    // })
    // .onClose.subscribe((model) => {
    //   this.inInitFinish.next(false);
    // })
  }
}
