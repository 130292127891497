import { Component, EventEmitter, OnDestroy, OnInit, Output } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, ValidationErrors, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { NbDialogService, NbToastrService } from '@nebular/theme';
import { BaseComponent } from 'app/@core/base/base.component';
import { OtpType } from 'app/@core/enums/otp-type';
import { IntegrationObject, OAuthStatusObject } from 'app/@core/models/integration.model';
import { LookupObject } from 'app/@core/models/lookup.model';
import { UpdateUserRequestObject, UserResponseObject } from 'app/@core/models/user.model';
import { IntegrationService } from 'app/@core/services/integration.service';
import { LookupService } from 'app/@core/services/lookup.service';
import { UserService } from 'app/@core/services/user.service';
import { IntegrationEditorDialogComponent } from 'app/pages/settings/integration/integration-editor-dialog/integration-editor-dialog.component';
import { Observable, Subscription } from 'rxjs';
import { take, takeUntil } from 'rxjs/operators';
import { EmailValidationPipe } from '../custom-pipes/email-validation.pipe';

@Component({
  selector: 'ngx-my-profile',
  templateUrl: './my-profile.component.html',
  styleUrls: ['./my-profile.component.scss']
})
export class MyProfileComponent extends BaseComponent implements OnInit, OnDestroy{
    @Output() authorised: EventEmitter<any> = new EventEmitter();
    user: UserResponseObject = new UserResponseObject();
    subscribedToQueryParams: boolean = false;
    docusignIntegration: IntegrationObject = new IntegrationObject();
    loadingDocusign: boolean = true;
    isDocusignUpdate: boolean = false;
    deleteTemplates: boolean = false;
    vpnRefreshLoading: boolean = false;
    type: string;
    title: string;
    id: number;
    autoAuthorize: boolean;
    status: OAuthStatusObject = new OAuthStatusObject();
    checkingStatus: boolean = true;
    windowHandle: any;
    windowTimeout: number;
    intervalLength: number = 2000;
    pollingLimit: number = 5;
    pollingData: Subscription = new Subscription();
    userForm: FormGroup;
    otpTypes: Array<LookupObject> = new Array<LookupObject>();
    commonPassList: Array<string> = new Array<string>();
    isFormModified: boolean = false;
    showPasswordInputs: boolean = false;
    originalFormValues: any;
    isLoading: boolean = false;
    saveInProgress: boolean = false;

  constructor(
    private _formBuilder: FormBuilder,
    public router: Router,
    private _dialogService: NbDialogService,
    private _toastrService: NbToastrService,
    private _integrationService: IntegrationService,
    private _route: ActivatedRoute,
    private _userService: UserService,
    private _lookupService: LookupService,
    private _emailValidationPipe: EmailValidationPipe,
  ) {
  super();
  this._initForm();
  }

  ngOnInit(): void {
    this.getDocusignIntegration();
    this._subscribeToAuthorizeDocusignSubject();
    this._getUserDetaills();
    this._getOtpTypeList();
    this._getCommonPasswordsList();
    this._handleIsUserFormModified();
    this._subscribeToEmailFormControlValueChanges();
    this._subscribeToPasswordFormControlValueChanges();
    this._subscribeToEnable2FAFormControlValueChanges();
    this._subscribeToPreferredOTPTypeFormControlValueChanges();
  }

    /**
   * Function to init user form
   */
  private _initForm() {
    this.userForm = this._formBuilder.group({
      firstName: new FormControl(null, Validators.required),
      lastName: new FormControl(null, Validators.required),
      email: new FormControl(null, [Validators.required, Validators.email]),
      password: new FormControl(null, [Validators.minLength(16), this._passwordValidator]),
      confirmPassword: new FormControl(null, this._passwordMatchValidator),
      phoneNumber: new FormControl(null, Validators.pattern(/^\+?\d{1,15}$/)),
      userTypeId: new FormControl(null),
      enable2FA: new FormControl(false),
      preferredOTPType: new FormControl(OtpType.None)
    })
  }

     /**
   * Function to get current user details
   */
     private _getUserDetaills(): void {
      this.isLoading = true;
      this._userService.getCurrentUser().pipe(take(1)).subscribe({
        next: (response: UserResponseObject) => {
          this.user = response;
          this._patchFormValues();
          this.isLoading = false;
        },
        error: (error) => {
          this._toastrService.danger(error.error.Content);
          this.isLoading = false;
        }
      })
    }

    /**
   * Function to patch user form values
   */
    private _patchFormValues() {
      this.userForm.patchValue({
        firstName: this.user.FirstName,
        lastName: this.user.LastName,
        email: this.user.Email,
        phoneNumber: this.user.PhoneNumber,
        userTypeId: this.user.UserTypeId == 1 ? "Administrator" : "Agent",
        enable2FA: this.user.IsMfaEnabled,
        preferredOTPType: this.user.PreferredOTPType
      });
      this.originalFormValues = this.userForm.value;
    }

  /**
   * Function to subscribe to query params
   */
  private _subscribeToQueryParams() {
    this._route.queryParams.subscribe(params => {
      const setupIntegration = params['setupIntegration'];
      if(setupIntegration === "true") {
        this.openIntegrationEditor(this.docusignIntegration)
      }
    })
  }

  /**
   * Function to get docusign integration
   */
  getDocusignIntegration() {
    this._integrationService.getIntegration('docusign').pipe(take(1)).subscribe({
      next: (response: IntegrationObject) => {
        this.docusignIntegration = response;

        this.type = this.docusignIntegration.ServiceType.toString();
        this.title = this.docusignIntegration.Name;
        this.id = this.docusignIntegration.Id;
        this.autoAuthorize = this.docusignIntegration.AccessToken == '' ? false : true;

        if(this.docusignIntegration.AccountId && this.docusignIntegration.ClientId && this.docusignIntegration.SecretKey) {
          this.isDocusignUpdate = true;
          this.deleteTemplates = true;
        }
        else {
          this.isDocusignUpdate = false;
          this.deleteTemplates = false;
        }

        if (!this.subscribedToQueryParams) {
          this._subscribeToQueryParams();
          this.subscribedToQueryParams = true;
        }
        if(this.docusignIntegration.AccessToken != '') {
          this._integrationService.authorizeDocusign.next(true);
        }

        this.checkStatus();

        this.loadingDocusign = false;
      },
      error: (error) => {
        this._toastrService.danger(error.error.Content);
        this.loadingDocusign = false;
      }
    })
  }

  /**
   * Function to open integration editor
   */
  openIntegrationEditor(model: IntegrationObject) {
    this._dialogService.open(IntegrationEditorDialogComponent, {
      context: {
        model: model,
        deleteTemplates: this.deleteTemplates
      },
      closeOnBackdropClick: false,
      closeOnEsc: false
    }).onClose.subscribe((dialogResponse: IntegrationObject) => {
      if (dialogResponse){
        dialogResponse.Name == "DocuSign" ? this.loadingDocusign = true : null;
        this._integrationService.saveIntegration(model).pipe(take(1)).subscribe({
          next: (response: string) => {
            this._toastrService.success(response);
            if (dialogResponse.Name == "DocuSign") {
              this._integrationService.authorizeDocusign.next(true);
              this.getDocusignIntegration();
            }
          },
          error: (error) => {
            this._toastrService.danger(error.error.Content);
            this.loadingDocusign = false;
          }
        })
      }
    })
  }

      /**
   * Function to subscribe to authorize docusign subject
   */
  private _subscribeToAuthorizeDocusignSubject() {
    this._integrationService.authorizeDocusign.pipe(takeUntil(this._unsubscribeAll)).subscribe({
      next: (data: boolean) => {
        if(data) {
          this.authorizeIntegration();
        }
      }
    })
  }

  /**
   * Function to check integration status
   */
  public checkStatus() {
    this._integrationService.checkIntegrationStatus(this.type, this.id).pipe(take(1)).subscribe({
      next: (response: OAuthStatusObject) => {
        this.status = response;
        this.checkingStatus = false;
      },
      error: (error) => {
        if(error.error.Content == "Problem with Docusign service: ") {
          this._toastrService.danger("Unable to process document request: Your docusign integration is not authorized!");
        }
        else {
          this._toastrService.danger(error.error.Content);
        }
      }
    })
  }

  /**
   * Function to reset integration
   */
  public resetAuthorization() {
    this._integrationService.resetIntegration(this.id).pipe(take(1)).subscribe({
      next: (response: string) => {
        this._toastrService.success(response);
        this.checkStatus();
      },
      error: (error) => {
        this._toastrService.danger(error.error.Content);
      }
    })
  }

  /**
   * Function to authorize integration
   */
  public authorizeIntegration() {
    var options = 'left=100,top=10,width=400,height=600'
    this.windowHandle = window.open(this.status.AuthUri, 'OAUTH2', options)

    let limit = this.pollingLimit;
    this.pollingData = Observable.interval(this.intervalLength)
      .switchMap(() => this._integrationService.checkIntegrationStatus(this.type, this.id).map((res: any) => res))
      .subscribe((response: OAuthStatusObject) => {
        this.status = response
        if (response.IsValid) {
          this.authorised.emit({
            provider: this.type,
            isAuthenticated: true,
          })
          this.pollingData.unsubscribe()
          this.windowHandle.close()
        }
        this.checkingStatus = false;
      })
  }

   /**
   * Function for custom password validator
   */
   private _passwordValidator(control: FormControl): ValidationErrors {
    const value = control.value;
    if (!value) return null;

    // Basic regex pattern
    const basicPattern = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)[A-Za-z\d@$!%*?&`~#^()\-_+=[\]{}|\\;:'",<.>?/]{16,}$/;
    if (!basicPattern.test(value)) {
      return { pattern: true };
    }

    // Check for repeated characters (at least 3 times)
    if (/([A-Za-z0-9@$!%*?&])\1\1/.test(value)) {
      return { repeatedCharacters: true };
    }

    // Check for mixed-case repeated patterns (e.g., aaA)
    if (/([a-zA-Z0-9@$!%*?&])\1{2}/i.test(value)) {
      return { mixedRepeatedCharacters: true };
    }

    // Check for consecutive sequences in any case combination
    const sequences = ['0123456789', 'abcdefghijklmnopqrstuvwxyz', 'ABCDEFGHIJKLMNOPQRSTUVWXYZ'];
    for (const seq of sequences) {
      for (let i = 0; i <= seq.length - 3; i++) {
        const subSeq = seq.substring(i, i + 3);
        const subSeqReversed = subSeq.split('').reverse().join('');
        const regex = new RegExp(subSeq.split('').map(char => `[${char}${char.toUpperCase()}]`).join(''), 'i');
        const regexReversed = new RegExp(subSeqReversed.split('').map(char => `[${char}${char.toUpperCase()}]`).join(''), 'i');
        if (regex.test(value) || regexReversed.test(value)) {
          return { sequence: true };
        }
      }
    }

    return null;
  }

  /**
   * Function to check if the passwords match
   */
  private _passwordMatchValidator(control: FormControl): { [s: string]: boolean } | null {
    const password = control.root.get('password')?.value;
    const confirmPassword = control.value;

    return password && password !== confirmPassword ? { 'passwordMismatch': true } : !password && confirmPassword ? { 'passwordEmpty': true } : null;
  }

  /**
   * Function to get common passwords list
   */
  private _getCommonPasswordsList(): void {
    this._lookupService.getCommonPasswordsList().pipe(take(1)).subscribe({
      next: (response: Array<LookupObject>) => {
        this.commonPassList = response.map(item => item.Name.toLowerCase());
      },
      error: (error) => {
        this._toastrService.danger(error.error.Content);
      }

    })
  }

  /**
 * Function to get otp type enums
 */
  get otpTypeEnums(): typeof OtpType {
    return OtpType;
  }

   /**
   * Function to get otp type list
   */
   private _getOtpTypeList(): void {
    this._lookupService.getOtpTypes().pipe(take(1)).subscribe({
      next: (response: Array<LookupObject>) => {
        this.otpTypes = response;
      },
      error: (error) => {
        this._toastrService.danger(error.error.Content);
      }
    })
  }

  /**
   * Function to handle is user form modified
   */
  private _handleIsUserFormModified(): void {
    this.userForm.valueChanges.subscribe(
      value => {
        if (value.firstName == this.user.FirstName &&
            value.lastName == this.user.LastName &&
            value.email == this.user.Email &&
            value.phoneNumber == this.user.PhoneNumber &&
            value.userTypeId == this.user.UserTypeId &&
            value.enable2FA == this.user.IsMfaEnabled &&
            value.preferredOTPType == this.user.PreferredOTPType) {
          this.isFormModified = false
        }
        else {
          this.isFormModified = true
        }
      }
    )
  }

  /**
   * Function to subscribe to password form control value changes
   */
  private _subscribeToEnable2FAFormControlValueChanges() {
    this.userForm.get('enable2FA').valueChanges.subscribe(
      (value: boolean) => {
        if (value && this.userForm.get('preferredOTPType').value == this.otpTypeEnums.Sms) {
          this.userForm.get('phoneNumber').addValidators([ Validators.required ]);
        }
        else {
          this.userForm.get('phoneNumber').removeValidators([ Validators.required ]);
        }
        this.userForm.get('preferredOTPType').setValue(this.otpTypeEnums.Email);
        this.userForm.get('phoneNumber').updateValueAndValidity();
      }
    )
  }

   /**
   * Function to subscribe to password form control value changes
   */
   private _subscribeToPreferredOTPTypeFormControlValueChanges(): void {
    this.userForm.get('preferredOTPType').valueChanges.subscribe(
      (value: OtpType) => {
        if (value == this.otpTypeEnums.Sms) {
          this.userForm.get('phoneNumber').addValidators([ Validators.required ]);
        }
        else {
          this.userForm.get('phoneNumber').removeValidators([ Validators.required ]);
        }
        this.userForm.get('phoneNumber').updateValueAndValidity();
      }
    )
  }

  /**
   * Function to subscribe to email form control value changes
   */
  private _subscribeToEmailFormControlValueChanges() {
    this.userForm.get('email').valueChanges.subscribe(
      (value: string) => {
        this.userForm.get('email').setErrors(value ? (this._emailValidationPipe.transform()(new FormControl(value)) ? { invalidFormat: true } : null) : { required: true });
      }
    )
  }

  /**
   * Function to subscribe to password form control value changes
   */
  private _subscribeToPasswordFormControlValueChanges() {
    this.userForm.get('password').valueChanges.subscribe(
      (value: string) => {
        if (this.commonPassList.some(commonPass => value.toLowerCase().includes(commonPass))) {
          this.userForm.get('password').setErrors({commonPass: true});
        }
        this.userForm.get('confirmPassword').updateValueAndValidity();
      }
    )
  }

    /**
   * Function to check is form control valid
   */
  checkIsFormControlValid(formControlName): boolean {
    return this.userForm.get(formControlName).invalid && (this.userForm.get(formControlName).dirty || this.userForm.get(formControlName).touched);
  }

  togglePasswordInputs(): void {
    this.showPasswordInputs = !this.showPasswordInputs;

    // If hiding the password inputs, reset the password and confirmPassword fields
    if (!this.showPasswordInputs) {
      this.userForm.get('password').reset();
      this.userForm.get('confirmPassword').reset();
    }
  }

  /**
   * Function to update user
   */
  public updateUser(): void {
    this.saveInProgress = true;
    let request: UpdateUserRequestObject = new UpdateUserRequestObject();
    request.Id = this.user.Id;
    request.FirstName = this.userForm.get('firstName').value;
    request.LastName = this.userForm.get('lastName').value;
    request.Email = this.userForm.get('email').value;
    request.Password = this.userForm.get('password').value;
    request.PhoneNumber = this.userForm.get('phoneNumber').value;
    request.IsMfaEnabled = this.userForm.get('enable2FA').value;
    request.PreferredOTPType = this.userForm.get('preferredOTPType').value;
    request.UserTypeId = this.userForm.get('userTypeId').value == "Administrator" ? 1 : 2;
    request.AllowIpWhiteListing = this.user.AllowIpWhiteListing;
    request.CustomPageAccess = false;
    request.UserApplicationPageIds = this.user.UserApplicationPageIds;

    this._userService.updateUser(request).pipe(take(1)).subscribe({
      next: (response: string) => {
        this._toastrService.success(response);
        this.saveInProgress = false;
      },
      error: (error) => {
        this._toastrService.danger(error.error.Content ?? "Unable to update user. Please try again");
        this.saveInProgress = false;
      }
    })
  }

   /**
   * On Destroy
   */
   ngOnDestroy(): void {
    this._unsubscribeAll.next();
    this._unsubscribeAll.complete();
    setTimeout(() => {
      this.pollingData.unsubscribe();
    }, 100)
  }

}
