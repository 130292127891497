<nb-card [nbSpinner]="isLoading" nbSpinnerStatus="primary">
  <nb-card-body>
    <iframe *ngIf="iframeSrc" [src]='iframeSrc' width='100%' height='100%' frameborder='0'></iframe>
  </nb-card-body>
  <nb-card-footer>
    <button class="cancel" size="tiny" nbButton status="danger" (click)="dialogRef.close()">Cancel</button>
    <button nbButton size="tiny" status="success" (click)="downloadDocument()" *ngIf="!isTemplatePreview">
      Download
    </button>
  </nb-card-footer>
</nb-card>
