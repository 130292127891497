<nb-card>
  <nb-card-header>Remove {{proposalStatus < 7 ? 'Estimate Request' : 'Completed Order'}}</nb-card-header>
  <nb-card-body>
    <h6 class="text-danger">This will permanently remove this estimate request, are you sure?</h6>
  </nb-card-body>
  <!-- <nb-card-body *ngIf="proposalStatus >= 7">
    <h6 class="text-danger">Deleting this completed order will be permanently removed including all related financial analytics.</h6>
    <label *ngIf="proposalStatus != 8" for="cbVisible" class="checkbox-field">
      <input id="cbVisible" type="checkbox" [(ngModel)]="keepVisible" />
      <span>Keep displaying this order but still, exclude it from all financial analytics.</span>
    </label>
  </nb-card-body> -->
  <nb-card-footer>
    <button nbButton [nbSpinner]="isDeleting" [disabled]="isDeleting" status="danger" size="tiny" class="pull-right" (click)="delete()">Remove</button>
    <button nbButton size="tiny" status="default" class="pull-right mr-1" (click)="dialogRef.close()">Cancel</button>
  </nb-card-footer>
</nb-card>
