export enum ProposalStatus {
  Requested = 1,
  Returned = 2,
  ProposalSent = 3,
  WaitingForSign = 4,
  Signed = 5,
  WaitingForConfirmation = 6,
  Confirmed = 7,
  DeletedVisible = 8,
  Deleted = 8,
  ProcessingDocument = 10,
  ProposalCreated = 11,
  FollowupRequestProcessing = 12
}
