<ng-select
[appendTo]="'body'"
#select
class="custom"
[ngClass]="customClass"
(change)="onChangeInternal()"
[(ngModel)]="value"
ngDefaultControl
[clearable]="clearable"
[disabled]="disabled"
[notFoundText]="notFoundText"
placeholder="{{ placeHolder }}"
>
<ng-option *ngFor="let i of items" [value]="useItemAsValue ? i : i[keyField]" ngDefaultControl [(ngModel)]="selectedValue">
  {{ useItemAsValue ? i : i[valueField] }}</ng-option
>
</ng-select>
