<div class="not-bar-frame" [style.background]="barBg()">
  <nb-icon icon="close" (click)="closeBar()" class="close-bar" [style.color]="bar.typeName == 'Docusign' ? '#ffffff' : '#000000'" *ngIf="showHideIcon" [nbTooltip]="'Dismiss'"></nb-icon>

  <p class="bar-title" [style.color]="bar.typeName == 'Docusign' ? '#ffffff' : bar.typeName == 'Not Found' || bar.statusName == 'Sent Failed' || bar.typeName == 'Failed Email Connection' || bar.statusName == 'Proposal Fees Missing' ? '#ff333a' : '#000000'">
    <nb-icon [style.background]="iconBg()" [style.color]="iconColor()"[icon]="bar?.icon" class="bar-icon" ></nb-icon>
    {{bar.title}}
  </p>

  <span class="timeago" [style.color]="dateColor()">{{bar.timeAgo}}</span>

  <button *ngIf="bar.typeName == 'Docusign'" nbButton size="tiny" status="light" style="float: right; transform: translate(-15px, -2px);" (click)="viewSignedDocument(bar)">
    View
  </button>

  <button *ngIf="bar.typeName == 'Failed Email Connection'" nbButton size="tiny" status="success" style="float: right; transform: translate(-15px, -2px);" (click)="updateEmailPassword(bar)">
    Update
  </button>

  <button *ngIf="bar.typeName == 'Missing Proposal Fees' || bar.typeName == 'Request Proposal Fees' || bar.typeName == 'Document viewed'" nbButton size="tiny" status="success" style="float: right; transform: translate(-15px, -2px);" (click)="viewProposal(bar)">
    View
  </button>

  <!-- <p class="bar-description">
    {{bar.description}}
  </p> -->
</div>
