<nb-card>
  <nb-card-header>Deleting Signed Proposals<nb-icon class="close-icon" icon="close" (click)="dialogRef.close(null)"></nb-icon></nb-card-header>
  <nb-card-body>
    <label
      >There
      {{
        ongoingSignedProposals.length > 1
          ? 'are ' + ongoingSignedProposals.length + ' signed contracts '
          : 'is a signed contract '
      }}
      below, that {{ ongoingSignedProposals.length > 1 ? 'have' : 'has' }} not been sent to the vendor. Would you like
      to delete the signed {{ ongoingSignedProposals.length > 1 ? 'contracts' : 'contract' }} for this customer?</label
    >
    <table class="table">
      <thead>
        <tr>
          <th>Date Signed</th>
          <th>Vendor Name</th>
          <th>Product Name</th>
          <th>Vendor Price</th>
          <th>Company Price</th>
          <th>Product Price</th>
          <th>Term</th>
          <th>Monthly Revenue</th>
          <th>Total Term Revenue</th>
        </tr>
      </thead>
      <tbody>
        <tr *ngFor="let p of ongoingSignedProposals">
          <td>{{ p.SignedDate | date: 'MM/dd/yyyy' }}</td>
          <td>{{ p.VendorName }}</td>
          <td>{{ p.ProductName }}</td>
          <td>${{ p.VendorPrice | number: '1.0-9' }}</td>
          <td>${{ p.CompanyPrice | number: '1.0-9' }}</td>
          <td>${{ p.FinalPrice | number: '1.0-9' }}</td>
          <td>{{ p.Term }}</td>
          <td>${{ p.MonthlyRevenue | number: '1.0-2'}}</td>
          <td>${{ p.TermRevenue | number: '1.0-2'}}</td>
        </tr>
      </tbody>
    </table>

    <nb-radio-group [(ngModel)]="selected">
      <nb-radio value="0">
        <span>Keep</span>
      </nb-radio>
      <nb-radio value="1">
        <span>Delete</span>
      </nb-radio>
    </nb-radio-group>
  </nb-card-body>
  <nb-card-footer>
    <button nbButton status="success" size="tiny" class="pull-right" (click)="submit()">Submit</button>
  </nb-card-footer>
</nb-card>
