import { HttpEvent, HttpEventType, HttpHandler, HttpInterceptor, HttpRequest, HttpResponse } from '@angular/common/http'
import { Injectable } from '@angular/core'
import { Router } from '@angular/router'

import { Observable, throwError } from 'rxjs'
import { map } from 'rxjs/operators'

@Injectable()
export class ResponseInterceptor implements HttpInterceptor {
  constructor(
    private router: Router,
  ) {}

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return next.handle(req).pipe(
      map((response) => {
        if (response instanceof HttpResponse) {
          // file response
          if (response.body?.type) return response

          if (!response.body?.IsSuccess) throw response.body?.Message

          if (response.body?.IsSuccess) return response.clone({ body: response.body?.Content })

          if (response.ok) return response.clone({ body: response.body })

          //if (response.statusText && response.statusText == 'OK' && response.status && response.status == 200) return response
          return response
        } else if (response.type === 1) {
          return response;
        }
      })
    )
  }
}
