import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { contactSaveObj } from 'app/pages/lead/profile/manage/customer-view-settings-form/customer-settings.model';
import { environment } from 'environments/environment';
import { BehaviorSubject, Observable, Subject, throwError } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { AssignLeadApplicant, AssignLeadHistory, ContactObject, CreateLeadObject, LeadObject, RecipientObject, UpdateLeadAnnualUsageObject, tagEntry } from '../models/lead.model';
import { LookupObject } from '../models/lookup.model';
import { LeadType } from '../enums/lead-type';
import { FieldObject } from '../models/shared.model';
import { ExportLeadsRequest, leadFilter, leadTableResponse } from 'app/pages/shared/shared.model';

@Injectable({
  providedIn: 'root'
})
export class LeadService {

  /**
   * Public variables
   */
  leadId: BehaviorSubject<number> = new BehaviorSubject<number>(null);
  contactId: BehaviorSubject<number> = new BehaviorSubject<number>(null);
  reloadLead: Subject<boolean> = new Subject<boolean>();
  allowContactExpand: boolean = false;

  leadFilter: leadFilter = new leadFilter();
  customerFilter: leadFilter = new leadFilter();

  leadsResponse: Subject<leadTableResponse> = new Subject();
  isTableLoaded: Subject<boolean> = new Subject();
  patchLeadFilterValues: BehaviorSubject<string | null> = new BehaviorSubject(null);
  selectedFilterGroupId: number = -1;
  selectedFilterGroupName: string = 'All Leads'

  /**
   * Private variables
   */
  private _apiUrl: string = environment.baseApiUrl

  constructor(private _httpClient: HttpClient) {}

  // -----------------------------------------------------------------------------------------------------
  // @ Private methods
  // -----------------------------------------------------------------------------------------------------

  /**
   * Function to handle error
   * @param error
   * @returns
   */
  private _handleError(error: HttpErrorResponse) {
    if (error.error instanceof ErrorEvent) {
      // A client-side or network error occurred. Handle it accordingly.
      console.error('An error occurred:', error.error.message)
    } else {
      // The backend returned an unsuccessful response code. The response body may contain clues as to what went wrong,
      console.error(`Backend returned code ${error.status}, ` + `body was: ${error.error}`)
    }
    // return an observable with a user-facing error message
    return throwError(error)
  }

  saveLeadFilterInLC() {
    if (this.leadFilter) {
      localStorage.setItem('leadFilter', JSON.stringify(this.leadFilter));
    }
  }

  getLeadFilterFromLC() {
    if (localStorage.getItem('leadFilter')) {
      this.leadFilter = JSON.parse(localStorage.getItem('leadFilter'));
    }
  }

  saveCustomerFilterInLC() {
    if (this.customerFilter) {
      localStorage.setItem('customerFilter', JSON.stringify(this.customerFilter));
    }
  }

  getCustomerFilterFromLC() {
    if (localStorage.getItem('customerFilter')) {
      this.customerFilter = JSON.parse(localStorage.getItem('customerFilter'));
    }
  }

  /**
   * Function to parse error blob
   */
  private _parseErrorBlob(err: HttpErrorResponse): Observable<any> {
    const reader: FileReader = new FileReader();

    const obs = Observable.create((observer: any) => {
      reader.onloadend = (e) => {
        observer.error(JSON.parse(reader.result.toString()));
        observer.complete();
      }
    });
    reader.readAsText(err.error);
    return obs;
  }

  // -----------------------------------------------------------------------------------------------------
  // @ Public methods
  // -----------------------------------------------------------------------------------------------------

  /**
   * Function to get lead details
   */
  public getLeadDetails(id: number): Observable<any> {
    return this._httpClient.get<any>(`${this._apiUrl}/lead/${id}/new`).pipe(
      map((data: any) => data),
      catchError(this._handleError)
    );
  }

  /**
   * Function to create lead
   */
  createLead(request: CreateLeadObject): Observable<number> {
    return this._httpClient.post<number>(`${this._apiUrl}/lead`, request).pipe(
      map((data: number) => data),
      catchError(this._handleError)
    );
  }

  /**
   * Function to update lead
   */
  updateLead(request: LeadObject): Observable<string> {
    return this._httpClient.put<string>(`${this._apiUrl}/lead/${request.Id}`, request).pipe(
      map((data: string) => data),
      catchError(this._handleError)
    );
  }

  /**
   * Function to get recipients
   */
  public getRecipients(leadId: number): Observable<Array<RecipientObject>> {
    return this._httpClient.get<Array<RecipientObject>>(`${this._apiUrl}/lead/${leadId}/recipients`).pipe(
      map((data: Array<RecipientObject>) => data),
      catchError(this._handleError)
    );
  }

  /**
   * Function to create recipient
   */
  public createRecipient(leadId: number, obj: contactSaveObj): Observable<contactSaveObj> {
    return this._httpClient.post<contactSaveObj>(`${this._apiUrl}/lead/${leadId}/contact`, obj).pipe(
      map((data: contactSaveObj) => data),
      catchError(this._handleError)
    );
  }

  /**
   * Function to get current annual usage
   */
  public getCurrentAnnualUsage(leadId: number): Observable<number> {
    return this._httpClient.get<number>(`${this._apiUrl}/lead/${leadId}/annual-usage`).pipe(
      map((data: number) => data),
      catchError(this._handleError)
    );
  }

  /**
   * Function to update lead name
   */
  public updateLeadName(request: Object): Observable<string> {
    return this._httpClient.put<string>(`${this._apiUrl}/lead/name`, request).pipe(
      map((data: string) => data),
    catchError(this._handleError)
    );
  }

  /**
   * Function to update lead annual usage
   */
  public updateLeadAnnualUsage(request: UpdateLeadAnnualUsageObject): Observable<string> {
    return this._httpClient.put<string>(`${this._apiUrl}/lead/annual-usage`, request).pipe(
      map((data: string) => data),
    catchError(this._handleError)
    );
  }

  /**
   * Function to get lead contacts
   */
  public getLeadContacts(leadId: number): Observable<Array<ContactObject>> {
    return this._httpClient.get<Array<ContactObject>>(`${this._apiUrl}/lead/${leadId}/contacts`).pipe(
      map((data: Array<ContactObject>) => data),
      catchError(this._handleError)
    );
  }

  /**
   * Function to save lead contact
   */
  public saveLeadContact(leadId: number, request: LeadObject): Observable<string> {
    return this._httpClient.post<string>(`${this._apiUrl}/lead/${leadId}/contact`, request).pipe(
      map((data: string) => data),
    catchError(this._handleError)
    );
  }

  /**
   * Function to delete lead contact
   */
  public deleteLeadContact(leadId: number, contactId: number): Observable<string> {
    return this._httpClient.delete<string>(`${this._apiUrl}/lead/${leadId}/contact/${contactId}`).pipe(
      map((data: string) => data),
      catchError(this._handleError)
    );
  }

  /**
   * Function to set lead contact as primery
   */
    public primaryLeadContact(leadId: number, contactId: number): Observable<string> {
      return this._httpClient.put<string>(`${this._apiUrl}/lead/${leadId}/defaultContact/${contactId}`, null).pipe(
        map((data: string) => data),
        catchError(this._handleError)
      );
    }

  /**
   * Function to get lead related customers
   */
  public getLeadRelatedCustomers(leadId: number): Observable<Array<LookupObject>> {
    return this._httpClient.get<Array<LookupObject>>(`${this._apiUrl}/lead/${leadId}/relatedCustomer`).pipe(
      map((data: Array<LookupObject>) => data),
      catchError(this._handleError)
    );
  }

  /**
   * Function to get lead type
   */
  public getLeadType(leadId: number): Observable<LeadType> {
    return this._httpClient.get<LeadType>(`${this._apiUrl}/lead/${leadId}/type`).pipe(
      map((data: LeadType) => data),
      catchError(this._handleError)
    );
  }

  /**
   * Function to get lead type and check agent access
   */
  public getLeadTypeAndCheckAgentAccess(leadId: number): Observable<LeadType> {
    return this._httpClient.get<LeadType>(`${this._apiUrl}/lead/${leadId}/check/type/and/access`).pipe(
      map((data: LeadType) => data),
      catchError(this._handleError)
    );
  }

  /**
   * Function to get current end date
   */
  public getCurrentEndDate(leadId: number): Observable<string | Date> {
    return this._httpClient.get<string | Date>(`${this._apiUrl}/lead/${leadId}/current-end-date`).pipe(
      map((data: string | Date) => data),
      catchError(this._handleError)
    );
  }

  /**
   * Function to update field
   */
  public updateField(leadId: number, request: FieldObject): Observable<string> {
    return this._httpClient.put<string>(`${this._apiUrl}/lead/${leadId}/field`, request).pipe(
      map((data: string) => data),
      catchError(this._handleError)
    );
  }

  /**
   * Function to export leads data to excel file
   */
  public exportLeadsDataToExcelFile(mode: string, request: ExportLeadsRequest): Observable<Blob> {
    return this._httpClient.post(`${this._apiUrl}/${mode}/export`, request, {responseType: 'blob'}).pipe(
      map((data: Blob) => data),
      catchError(this._parseErrorBlob));

  }

  /**
   * Function to get Lead Assign Agents
   */
  public getLeadApplicantsLookup() : Observable<Array<LookupObject>> {
    return this._httpClient.get(`${environment.baseApiUrl}/lookup/agent/list`).pipe(
      map((data: Array<LookupObject>) => data)
    );
  }

  /**
   * Function to reassign Lead Agent
   */
  public saveAssignedLeads(request: AssignLeadApplicant): Observable<any> {
    return this._httpClient.put<any>(`${environment.baseApiUrl}/Lead/reassign`, request).pipe(
      map((data: any) => data)
    );
  }

  /**
   * Function to get Lead Assign History
   */
  public getLeadAssignHistory(leadId: number) : Observable<Array<AssignLeadHistory>> {
    return this._httpClient.get(`${environment.baseApiUrl}/lead/reassign/details/${leadId}`).pipe(
      map((data: Array<AssignLeadHistory>) => data)
    );
  }

  /**
   * Function to delete multiple leads
   */
  public deleteMultipleLeads(request: any): Observable<string> {
    return this._httpClient.delete<string>(`${this._apiUrl}/lead/multiple`, { body: request }).pipe(
      map((data: string) => data),
      catchError(this._handleError)
    );
  }

   /**
   * Function to get tags
   */
  public getTags(leadId:number) : Observable<any> {
    return this._httpClient.get<any>(`${environment.baseApiUrl}/lead/${leadId}/tags`).pipe(
      map((data: any) => data)
    );
  }

  public getBrandLookups(): Observable<any> {
    return this._httpClient.get<any>(`${this._apiUrl}/lookup/Brand`).pipe(
      map((data: any) => data),
      catchError(this._handleError)
    );
  }

  public getVendorLookups(): Observable<any> {
    return this._httpClient.get<any>(`${this._apiUrl}/lookup/Vendor`).pipe(
      map((data: any) => data),
      catchError(this._handleError)
    );
  }

   /**
   * Function to update tags
   */
  public updateTags(leadId:number, list: tagEntry[]): Observable<any> {
    return this._httpClient.put<any>(`${environment.baseApiUrl}/lead/${leadId}/tags`, list).pipe(
      map((data: any) => data)
    );
  }
}
