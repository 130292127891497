import { HttpClient, HttpHeaders } from '@angular/common/http'
import { Injectable } from '@angular/core'
import { saveAs } from 'file-saver'
import { environment } from '../../../environments/environment'
import { Observable } from 'rxjs'

@Injectable()
export class HttpHelperService {
  constructor(private http: HttpClient) {}

  download(url: string, openInNewTab: boolean = false) {
    return new Promise<any>((resolve, reject) => {
      this.http
        .get(`${environment.baseApiUrl}${url}`, {
          responseType: 'blob',
          observe: 'response',
          headers: new HttpHeaders({
            'Content-Type': 'application/x-www-form-urlencoded',
          }),
        })
        .subscribe(
          (response: any) => {
            if (openInNewTab) {
              let fileURL = window.URL.createObjectURL(response.body)
              let tab = window.open()
              tab.location.href = fileURL
            } else {
              saveAs(response.body, response.headers.get('x-filename'))
              // let fileURL = window.URL.createObjectURL(response.body)
              // window.location.assign(fileURL)
            }

            resolve(response)
          },
          (reason) => {
            console.error(reason)
            reject(reason)
          }
        )
    })
  }

  downloadFile(url: string, openInNewTab: boolean = false) {
    return new Promise<any>((resolve, reject) => {
      this.http
        .get(`${url}`, {
          responseType: 'blob',
          observe: 'response',
          headers: new HttpHeaders({
            'Content-Type': 'application/x-www-form-urlencoded',
          }),
        })
        .subscribe(
          (response: any) => {
            if (openInNewTab) {
              let fileURL = window.URL.createObjectURL(response.body)
              let tab = window.open()
              tab.location.href = fileURL
            } else {
              saveAs(response.body, response.headers.get('x-filename'))
            }

            resolve(response)
          },
          (reason) => {
            console.error(reason)
            reject(reason)
          }
        )
    })
  }

  preview(url: string): Observable<any> {
    return this.http
    .get(`${url}`, {
      responseType: 'blob',
      observe: 'response',
      headers: new HttpHeaders({
        'Content-Type': 'application/x-www-form-urlencoded',
      }),
    }).map(data => data)
  }
}
