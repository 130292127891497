import { array } from "@amcharts/amcharts4/core";
import { DocusignTemplateObject } from "app/@core/models/docusign.model";
import { VendorSiteColumnOrder } from "app/@core/models/vendor-site-column-order.model";

export class Vendor {
  constructor(
    public ConfirmationTemplateId ?: number | null,
    public CreatedDate ?: any | null,
    public Id ?: number | null,
    public InitialFeeRequestTemplateId ?: number | null,
    public Name ?: string | null,
    public RenewalRequestTemplateId ?: string | null,
    public RepeatFeeRequestTemplateId ?: string | null,
    public RestrictProposalByIp ?: boolean | null,
    public SectorId ?: number | null,
    public SectorName ?: string | null,
    public Templates ?: Array<DocusignTemplateObject> | null,
    public EventProcessorInstanceId ?: number | null,
    public EventProcessorInstanceName ?: string  | null,
    public SiteTableColumnsOrder?: Array<VendorSiteColumnOrder>
  ){}
}

export class Site {
    constructor(
        public AccountNumber ?: string | null,
        public Address ?: string | null,
        public AnnualUsage ?: number | null,
        public BillingAddress ?: any | string | null,
        public BillingCity ?: any | string | null,
        public BillingState ?: any | string | null,
        public BillingZipCode ?: any | null,
        public City ?: string | null,
        public CreatedDate ?: any | null,
        public CurrentVendor ?: number | null,
        public CurrentVendorName ?: string | null,
        public EndDate ?: any | null,
        public ExcludedContractsEndDate ?: any | null,
        public Fields ?: Array<any> | null,
        public HasCustomBillingAddress ?: boolean | null,
        public Id ?: number | null,
        public LeadId ?: number | null,
        public OngoingProposals ?: any | null,
        public Proposals ?: Array<any> | null,
        public StartDate ?: any | null,
        public State ?: string | null,
        public ZipCode ?: string | null,
        public isSelected ?: boolean | null,
    ) { }
}

export class integrationStatus {
  constructor(
    public IsValid ?: boolean | null,
    public Message ?: string | null,
    public AuthUri ?: string | null,
  ){}
}

export class leadFilter {
  constructor(
    public pageNumber: number = 1,
    public pageSize : number = 15,
    public sortColumn : string = 'createdDate',
    public sortDirection : string = 'desc',
    public type ?: number | null,
    public contactFirstName ?: string | null,
    public contactLastName ?: string | null,
    public contactEmail ?: string | null,
    public contactPhoneNumber ?: string | null,
    public contactTitle ?: string | null,
    public contactsTableExpanded?: boolean | null,
    public filters: Array<CustomFilterRequest> | null = null,
    public isNewFilter: boolean = false,
  ){}
}

export class LeadPipelineFilter {
  constructor(
    public pipelineId ?: number | null,
    public mode ?: number | null,
    public sortColumn ?: string | null,
    public sortDirection ?: string | null,
    public filters: Array<CustomFilterRequest> | null = null,
    public isNewFilter: boolean = false,
    public pageNumber: number = 1,
    public pageSize : number = 15
  ){}
}

export class CustomFilterRequest {
  constructor(
    public column?: string | null,
    public value?: any,
    public secondValue?: any,
    public type ?: number,
    public table ?: any,
    public thirdValue: Array<string> = [],
  ){}
}

export enum CustomFilterRequestTable {
  Lead = 0,
  Contact = 1,
  LeadSite = 2,
  LeadField = 3
}

export enum CustomFilterRequestType {
  Text = 0,
  Select = 1,
  Range = 2
}

export class leadTableResponse {
  constructor(
    public Data : Array<any> = [],
    public PageNumber ?: number | null,
    public PageSize ?: number | null,
    public TotalPages ?: number | null,
    public TotalRecords ?: number | null,
  ){}
}


export class ExportLeadsRequest{
  constructor(
    public complete ?: boolean | null,
    public filter ?: leadFilter | null,
  ){}
}


export class schemaColumn {
  constructor(
    public Format ?: number,
    public IsRequired ?: boolean,
    public LookupName ?: string,
    public LookupValueProperty ?: string,
    public Precision ?: number,
    public PropertyName ?: string,
    public Table ?: number,
    public Title ?: string,
    public Width ?: number,
    public filterActive ?: boolean,
    public filterSecondValue ?: string,
    public filterValue ?: string,
    public originalWidth ?: number,
    public shadowWidth ?: number
  ){}
}

export class TableLookup {
  constructor(

    public CreatedDate ?: string | Date,
    public Id ?: number,
    public LookupTableId ?: number,
    public LookupName ?: string,
    public Name ?: String
  ){}
}
