import { Injectable, EventEmitter } from '@angular/core'

@Injectable({
  providedIn: 'root',
})
export class NbPopoverContextService {
  onCallback: EventEmitter<any> = new EventEmitter()

  currentContext: any

  constructor() {}

  callBack(content) {
    this.onCallback.emit(content)
  }
}
