import { Pipe, PipeTransform } from '@angular/core';
import { FormControl } from '@angular/forms';

@Pipe({
  name: 'emailValidation'
})
export class EmailValidationPipe implements PipeTransform {
  transform(): any {
    return (control: FormControl) => {
      const emailRegex = /^[A-Za-z0-9]+([._-]?[A-Za-z0-9]+)*@[A-Za-z0-9-]+(\.[A-Za-z]{2,})$/;
      const email = control.value;
      if (!emailRegex.test(email)) {
        return { invalidEmail: true };
      }

      const [localPart, domainPart] = email.split('@');

      // Validate Local Part length
      if (localPart.length > 64) {
        return { invalidLocalPart: true };
      }

      // Validate Domain Part length
      if (domainPart.length > 255) {
        return { invalidDomainPart: true };
      }

      // Additional validation for domain format
      const domainParts = domainPart.split('.');
      if (domainParts.length !== 2 || domainParts.some(part => part.length < 2)) {
        return { invalidEmail: true };
      }
    
      return null;
    };
  }
}
