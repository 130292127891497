<nb-card [nbSpinner]="loading || loadingContacts" nbSpinnerStatus="primary" >
  <nb-card-header>{{ title }} <nb-icon class="close-icon" icon="close" (click)="dialogRef.close()"></nb-icon></nb-card-header>
  <nb-card-body [formGroup]="proposalForm">
    <div class="d-flex mt-10">
        <div class="field w-50 flex-column align-items-start pr-40">
          <label>Vendor<span class="lbl-required">*</span></label>
          <nb-select size="tiny" formControlName="vendor" style="width: 100%;" [ngClass]="{'invalid-input': isControlInvalid('vendor')}">
            <nb-option *ngFor="let vendor of vendorList" [value]="vendor.Id">{{ vendor.Name }}</nb-option>
          </nb-select>
          <p *ngIf="isControlInvalid('vendor')" class="error_message">Vendor is required</p>
        </div>
        <div class="field w-50 flex-column align-items-start pl-40">
          <label>Product<span class="lbl-required">*</span></label>
          <nb-select size="tiny" formControlName="product" style="width: 100%;" [disabled]="!filteredProductList.length"
                [placeholder]="this.proposalForm.get('vendor').value ? filteredProductList.length ? '' : 'This vendor does not have any product' : ''" [ngClass]="{'invalid-input': isControlInvalid('product')}" autofocus>
            <nb-option *ngFor="let product of filteredProductList" [value]="product.Id" autofocus>{{ product.Name }}</nb-option>
          </nb-select>
          <p *ngIf="isControlInvalid('product')" class="error_message">Product is required</p>
        </div>
    </div>

    <div class="d-flex mt-10">
      <div class="field flex-column align-items-start w-33 pr-8">
        <label class="text-nowrap">Vendor Price<span class="lbl-required">*</span></label>
        <numeric-input prefix="$" formControlName="vendorPrice" (keyPress)="vendorPriceChanged($event)" (blur)="onNumericInputBlur('vendorPrice', $event)"></numeric-input>
        <p *ngIf="!vendorPriceValid" class="error_message">Vendor Price is required</p>
        <p *ngIf="proposalForm.get('vendorPrice').hasError('zeroFirstChar')" class="error_message">Vendor Price cannot be 0</p>
      </div>
      <div class="field flex-column align-items-start w-33 pl-8 pr-8">
        <label class="text-nowrap">Company Price<span class="lbl-required">*</span></label>
        <numeric-input prefix="$" formControlName="companyPrice" (keyPress)="companyPriceChanges($event)" (blur)="onNumericInputBlur('companyPrice', $event)"></numeric-input>
        <p *ngIf="!companyPriceValid" class="error_message">Company Price is required</p>
        <p *ngIf="proposalForm.get('companyPrice').hasError('zeroFirstChar')" class="error_message">Company Price cannot be 0</p>
      </div>
      <div class="field flex-column align-items-start w-33 pl-8">
        <label class="text-nowrap">Final Product Price</label> <br>
        <span>${{ vendorPrice + companyPrice || 0 | number: '1.0-6' }}</span>
      </div>
    </div>

    <div class="d-flex mt-10">
      <div class="field w-50 flex-column align-items-start pr-40">
        <label>Created At</label>
        <input nbInput fullWidth fieldSize="tiny" [nbDatepicker]="createdDateTimePicker" formControlName="createdDate">
        <nb-datepicker #createdDateTimePicker></nb-datepicker>
      </div>
      <div class="field w-50 flex-column align-items-start pl-40">
        <label>Signed At<span class="lbl-required">*</span></label>
        <input nbInput fullWidth fieldSize="tiny" formControlName="signedDate" (click)="openCustomDateTimePicker()" [ngClass]="{'invalid-input': isControlInvalid('signedDate')}">
        <p *ngIf="isControlInvalid('signedDate')" class="error_message">Signed Date is required</p>
        <!--  [nbDatepicker]="picker" -->
        <!-- <nb-date-timepicker #picker singleColumn [step]="10" twelveHoursFormat format="MM/dd/yyyy h:mm aa"></nb-date-timepicker> -->
      </div>
    </div>

    <div class="d-flex mt-10">
      <div class="w-100 d-flex flex-row justify-content-between align-items-end">
        <div class="d-flex flex-column col-10 p-0">
          <label>Contact</label>
          <nb-select size="tiny" formControlName="contact" style="max-width: 100% !important;" placeholder="Select contact">
            <nb-option *ngFor="let contact of leadContacts" [value]="contact">{{ contact.FirstName }} {{ contact.LastName }}</nb-option>
          </nb-select>
        </div>

        <button nbButton size="tiny" status="primary" class="ml-2" (click)="addContact()">
          Add New
        </button>
      </div>
    </div>

    <div *ngIf="isContactSelected">
      <div class="d-flex mt-10">
        <div class="w-50 pr-8">
          <label>First Name</label>
          <input type="text" nbInput fullWidth fieldSize="tiny" formControlName="firstName"/>
        </div>
        <div class="w-50 pl-8">
          <label>Last Name</label>
          <input type="text" nbInput fullWidth fieldSize="tiny" formControlName="lastName"/>
        </div>
      </div>

      <div class="d-flex mt-10">
        <div class="w-50 pr-8">
          <label>Title</label>
          <input type="text" nbInput fullWidth fieldSize="tiny" formControlName="title"/>
        </div>
        <div class="w-50 pl-8">
          <label>Email</label>
          <input type="text" nbInput fullWidth fieldSize="tiny" formControlName="email" (input)="emailInputChanged()"/>
          <mat-error *ngIf="proposalForm.get('email').invalid && proposalForm.get('email').touched">Invalid email format</mat-error>
        </div>
      </div>
    </div>

    <div class="d-flex mt-10">
      <div class="field w-50 flex-column align-items-start pr-40">
        <label>Confirmation Date</label>
        <input nbInput fullWidth fieldSize="tiny" [nbDatepicker]="confirmationDateTimePicker" formControlName="confirmationDate">
        <nb-datepicker #confirmationDateTimePicker></nb-datepicker>
      </div>
      <div class="field w-50 flex-column align-items-start pl-40">
        <label>Start Date<span class="lbl-required">*</span></label>
        <input nbInput fullWidth fieldSize="tiny" [nbDatepicker]="taskDateTimePicker" formControlName="startDate" [ngClass]="{'invalid-input': isControlInvalid('startDate')}">
        <p *ngIf="isControlInvalid('startDate')" class="error_message">Start Date is required</p>
        <nb-datepicker #taskDateTimePicker></nb-datepicker>
      </div>
    </div>

    <div class="d-flex mt-10">
      <div class="field flex-column align-items-start w-33 pr-8">
        <label>Term<span class="lbl-required">*</span></label>
        <numeric-input formControlName="term" (keyPress)="termChanged($event)" (keydown)="preventLeadingZero($event)" [maxLimit]="10000" (blur)="onNumericInputBlur('term', $event)"></numeric-input>
        <p *ngIf="!termValid" class="error_message">Term is required</p>
      </div>
      <div class="w-33 pl-8 pr-8">
        <label>End Date</label> <br>
        <span>{{ endDate || 'N/A' }}</span>
      </div>
      <div class="w-33 pl-8">
        <label>Annual Usage</label> <br>
        <span>{{ !loading ? (currentAnnualUsage | number: '1.0-2') : null }}</span>
      </div>
    </div>

    <div class="d-flex mt-10">
      <div class="w-33 pr-8">
        <label>Monthly Revenue</label>
        <numeric-input
          prefix="$"
          formControlName="monthlyRevenue"
        ></numeric-input>
      </div>
      <div class="w-33 pl-8 pr-8">
        <label>Total Term Revenue</label>
        <numeric-input
          prefix="$"
          formControlName="termRevenue"
        ></numeric-input>
      </div>
      <div class="w-33 pl-8">
        <label for="cbExcludeFromReporting" class="checkbox-field">
          <input type="checkBox" id="cbExcludeFromReporting" formControlName="excludeFromReporting" />
          <span>Exclude From Financial Reporting</span>
        </label>
      </div>
    </div>

    <hr class="my-15">

    <div class="d-flex flex-column mt-10">
      <label>{{ translate('LEADMANAGE_PROPOSAL_TYPEOFPRICING') }}<span class="lbl-required">*</span></label>
      <nb-radio-group formControlName="requestInitialFees">
        <nb-radio [value]="true">
          <span
            >{{ translate('LEADMANAGE_PROPOSAL_CUSTOMTEXT') }}</span
          >
        </nb-radio>
        <nb-radio [value]="false">
          <span>{{ translate('LEADMANAGE_PROPOSAL_DAILYMATRIX') }}</span>
        </nb-radio>
      </nb-radio-group>
    </div>

    <hr class="mb-15 mt-5">

    <div class="mt-25">
      <div class="d-flex justify-content-between">
        <label>{{translate('SITE_SELECTEDSITES')}}</label>
        <button nbButton class="sites-button pull-right" size="tiny" status="success" (click)="manageSites()" [disabled]="!selectedSites.length">
          {{translate('SITE_CHANGESITELIST')}}
        </button>
      </div>
      <ul class="sites-list custom-scroll">
        <li *ngFor="let site of selectedSites">
          {{ site.Address }} {{ site.City }}, {{ site.State }} {{ site.ZipCode }}
        </li>
      </ul>
    </div>

    <hr class="mb-15 mt-5">

    <!-- upload doc -->
    <div class="field">
      <label>Upload Terms of Service Document<span class="lbl-required">*</span></label>
      <div style="display: flex; align-items: center; justify-content: space-between; flex-grow: 1;">
        <span [style.margin-right]="isDocumentUploaded ? '0' : '0.5rem'" (click)="viewDocument()" style="text-decoration: underline; color: #396e94; cursor: pointer;">
          {{ isDocumentUploaded ? uploadedDocumentName : '' }}
        </span>
        <button *ngIf="!isDocumentUploaded" nbButton size="tiny" status="primary" class="pull-right" (click)="openUploadDocumentEditor(false)">Upload</button>
        <button *ngIf="isDocumentUploaded" nbButton size="tiny" status="danger" class="pull-right" (click)="deleteUploadedDocument(docId, false)">Delete Document</button>
      </div>
    </div>

    <hr class="mb-15 mt-5">

    <!-- upload doc list -->
    <div class="field">
      <label>Upload Additional Documents</label>
      <button nbButton size="tiny" status="primary" class="pull-right mb-10" (click)="openUploadDocumentEditor(true)">Upload</button>
    </div>

    <div class="field border-b pt-5 w-100" *ngFor="let document of uploadedDocList">
      <div class="doc-preview">
        <div>
          <span *ngIf="document.id" [style.margin-right]="document && document.id ? '0' : '0.5rem'" (click)="viewDocumentFromList(document)" style="margin-bottom: 5px">
            Name: <span style="text-decoration: underline; color: #396e94; cursor: pointer;">{{ document.name ? document.name : 'N/A' }}</span>
          </span>
          <p *ngIf="document.id" class="mb-0 h-20">
            Type: {{ document.type ? document.type : 'N/A' }}
          </p>
        </div>
        <button *ngIf="document && document.id" nbButton size="tiny" status="danger" class="pull-right mb-10" (click)="deleteUploadedDocument(document.id, true)">Delete Document</button>
      </div>
    </div>

    <hr class="mb-15 mt-5">

  </nb-card-body>
  <nb-card-footer>
    <button class="cancel" nbButton size="tiny" status="danger" (click)="deleteUploadedDocumentList(); dialogRef.close();">Cancel</button>
    <button
      class="ml-1"
      nbButton
      [nbSpinner]="inProgress"
      size="tiny"
      status="success"
      [disabled]="proposalForm.invalid || !isDocumentUploaded || inProgress"
      (click)="submit()"
    >
      Submit
    </button>
  </nb-card-footer>
</nb-card>
