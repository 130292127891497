<nb-card>
    <nb-card-body  [nbSpinner]="loadingContacts" nbSpinnerStatus="primary">
        <table class="table" [formGroup]="contactFormGroup" #contactsArea>
            <thead class="tbl-head-contacts">
              <tr>
                <th>First Name</th>
                <th>Last Name</th>
                <th>Email</th>
                <th>Phone</th>
                <th>Title</th>
                <th>Actions</th>
              </tr>
            </thead>
            <tbody>
              <tr formArrayName="contactsArray" class="contact" *ngFor="let contact of contactFormGroup.get('contactsArray')['controls']; let i = index;">
                <ng-container [formGroupName]="i">
                    <td class="field">
                        <input class="input-name min-w-55" #contactFirstNameInput
                            (input)="contactInputChange($event, i, 'firstName')"
                            [ngClass]="{ editable: activeContactArray.editFirstName }" (click)="editableField(i, 'editFirstName')"
                            formControlName="firstName" placeholder="First Name" [style.width]="setInputWidth(i, 'c')"/>
                    </td>
                    <td class="field">
                        <input class="input-name min-w-55" #contactLastNameInput
                            (input)="contactInputChange($event, i, 'lastName')"
                            [ngClass]="{ editable: activeContactArray.editLastName }" (click)="editableField(i, 'editLastName')"
                            formControlName="lastName" placeholder="Last Name" [style.width]="setInputWidth(i, 'lastName')"/>
                    </td>
                    <td class="field">
                        <input (input)="contactInputChange($event, i, 'email')"
                            [ngClass]="{ editable: activeContactArray.editemail }" (click)="editableField(i, 'editemail')"
                            formControlName="email" placeholder="..." />
                        <p *ngIf="loadingContactFormArray.controls[i].get('email').errors?.invalidFormat" class="error_message">Invalid email format</p>
                    </td>
                    <td class="field">
                        <input type="tel" [ngClass]="{ editable: activeContactArray.editphone }"
                            (input)="contactInputChange($event, i, 'phone')" mask="(000) 000-0000"
                            (click)="editableField(i, 'editphone')" formControlName="phone" placeholder="..." />
                        <p class="invalid_phone" *ngIf="loadingContactFormArray.controls[i].get('phone').hasError('numericLength')">Invalid phone number, must be <b>10</b> digits. <b>Ex: (123) 456-7890</b>.</p>
                    </td>
                    <td class="field">
                        <input (input)="contactInputChange($event, i, 'title')"
                            [ngClass]="{ editable: activeContactArray.editposition }" (click)="editableField(i, 'editposition')"
                            formControlName="title" placeholder="..." />
                    </td>
                    <td class="field">
                      <button class="primary-btn" nbButton [disabled]="isContactPrimary(contact) || loadingContacts" [ngClass]="isContactPrimary(contact) ? 'success' : 'primary'" size="tiny" (click)="setContactAsPrimary(contact, i)">{{isContactPrimary(contact) ? 'Primary' : 'Set as primary'}}</button>
                      <button class="primary-btn success ml-1" nbButton (click)="saveContact(i)" size="tiny" 
                      [ngClass]="(saveContactInProgress && activeIndex == i) || !loadingContactFormArray.controls[i].get('showSaveBtn').value || !loadingContactFormArray.controls[i].get('email').value || loadingContactFormArray.controls[i].get('email').errors?.invalidFormat ? 'disabled' : 'success'"
                      [disabled]="saveContactInProgress || !loadingContactFormArray.controls[i].get('showSaveBtn').value || !loadingContactFormArray.controls[i].get('email').value || loadingContactFormArray.controls[i].get('email').errors?.invalidFormat" 
                      [nbSpinner]="activeIndex == i && saveContactInProgress" nbSpinnerStatus="primary">
                      SAVE CHANGES
                      </button>
                      <button nbButton ghost size="tiny" class="contact__remove" status="danger" (click)="deleteContact(contact, i)">Delete Contact</button>
                    </td>
                </ng-container>
              </tr>
            </tbody>
          </table>

          <button nbButton size="tiny" status="primary" class="pull-left" (click)="addContact()"
            [disabled]="loadingContacts">
            <nb-icon class="action-icon" icon="plus-circle"></nb-icon> Add Contact
          </button>
      </nb-card-body>
</nb-card>
