import { Injectable, Component, OnDestroy } from '@angular/core'
import { SubSink } from 'subsink'
import { TranslateService } from '../services'
import { Subject } from 'rxjs'
import { AppInjector } from './base-injector.service'

@Component({
  template: '',
  styleUrls: []
})
export class BaseComponent implements OnDestroy {
  subs = new SubSink()
  actionsColumnName: string = 'Actions'
  actionIcons: string = 'Action Icons'
  contactsColumnName: string = 'Contacts'
  assignColumnName: string = 'Checkbox'
  assignedToColumnName: string = 'AssignedTo'
  assigned_ToColumnName: string = 'Assigned To'
  customerKeyColumnName: string = 'Key'

  public _unsubscribeAll: Subject<void> = new Subject<void>();

  public translateService: TranslateService;

  constructor() {
    try {
      const injector = AppInjector.getInjector();
      this.translateService = injector.get(TranslateService);
      this.translateService.init()
    } catch (e) {
      console.log('Failed initializing dependencies', e)
    }
  }
  ngOnDestroy(): void {
    this._unsubscribeAll.next();
    this._unsubscribeAll.complete();
    this._unsubscribeAll.unsubscribe();
  }

  public translate(key) {
    return this.translateService.translate(key)
  }

  public sanitizeSchemaColumns(schemaColumns, allColumns, appendActionsColumn = false) {
    // Necessary to handle (rare, but possible) column format changes in the backend
    schemaColumns = schemaColumns.map((sc) => {
      let column = allColumns.find((c) => c.PropertyName == sc.PropertyName)
      if (column) {
        sc.Format = column.Format
        sc.Title = column.Title
        ;(sc.Precision = column.Precision), (sc.LookupName = column.LookupName)
        sc.LookupValueProperty = column.LookupValueProperty
      }
      return sc
    })

    if (appendActionsColumn && !schemaColumns.some((x) => x.PropertyName == this.actionsColumnName)) {
      schemaColumns.push({
        PropertyName: this.actionsColumnName,
        Title: this.actionsColumnName,
        Width: 50,
        originalWidth: 50,
      })
    }

    return schemaColumns
  }

}
