import { Component, Input, OnInit } from '@angular/core';
import { NbDialogRef } from '@nebular/theme';

@Component({
  selector: 'ngx-signed-proposals-delete-confirmation',
  templateUrl: './signed-proposals-delete-confirmation.component.html',
  styleUrls: ['./signed-proposals-delete-confirmation.component.scss']
})
export class SignedProposalsDeleteConfirmationComponent implements OnInit {
  @Input() ongoingSignedProposals: any = []

  selected: any = '0'

  constructor(public dialogRef: NbDialogRef<SignedProposalsDeleteConfirmationComponent>) {}

  ngOnInit(): void {}

  submit() {
    this.dialogRef.close(this.selected == '1' ? true : false)
  }
}
