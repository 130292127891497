import { AfterContentInit, Component, Input, OnChanges } from '@angular/core'
import * as am4core from '@amcharts/amcharts4/core'
import * as am4charts from '@amcharts/amcharts4/charts'
import amchartsAnimated from '@amcharts/amcharts4/themes/animated'
import { formatISO, lightFormat, parseISO } from 'date-fns'

@Component({
  selector: 'ngx-trend-chart',
  templateUrl: './trend-chart.component.html',
  styleUrls: ['./trend-chart.component.scss'],
})
export class TrendChartComponent implements OnChanges {
  @Input() data: any
  @Input() options: any

  chart: any
  elementId: string
  trendChange: number

  constructor() {
    this.elementId = `chart_${Math.round(Math.random() * 1000000)}`
  }

  ngOnChanges(): void {
    am4core.useTheme(amchartsAnimated)
    if (this.chart) {
      this.chart.dispose()
      this.chart = null
    }

    if (!this.data.length) {
      return
    }

    setTimeout(() => {
      this.options.dateProperty = this.options.dateProperty || 'Date'
      this.options.valueFormat = this.options.valueFormat || '#,###'

      let now = new Date()
      let lastYearDataPoint = this.data.find((x) => x.Date < new Date(now.getFullYear(), 0, 1))
      let latestDataPoint = this.data
        .reverse()
        .find((x) => (typeof x.Date == 'string' ? parseISO(x.Date) : x.Date) < now)

      if (lastYearDataPoint) {
        this.trendChange =
          Math.round(
            (latestDataPoint[this.options.valueProperty] * 10000) / lastYearDataPoint[this.options.valueProperty]
          ) / 100
      }

      if (this.data[0].Date && !this.options.dateDisplayProperty) {
        this.options.dateDisplayProperty = 'DateFormatted'
        this.data.forEach(x=> {
          x.DateFormatted = lightFormat(typeof x.Date == 'string' ? parseISO(x.Date) : x.Date, 'MM/dd/yyyy')
        })
      }

      this.chart = am4core.create(this.elementId, am4charts.XYChart)
      this.chart.data = this.data

      let dateAxis = this.chart.xAxes.push(new am4charts.DateAxis())
      dateAxis.renderer.minGridDistance = 50
      dateAxis.title.text = this.options.dateProperty      
      let valueAxis = this.chart.yAxes.push(new am4charts.ValueAxis())
      valueAxis.title.text = this.options.valueLabel
      valueAxis.numberFormatter.numberFormat = this.options.valueFormat

      let series = this.chart.series.push(new am4charts.LineSeries())
      series.dataFields.dateX = this.options.dateProperty
      series.dataFields.valueY = this.options.valueProperty
      series.strokeWidth = 2
      series.minBulletDistance = 10
      series.tooltipText = `{${this.options.dateDisplayProperty || this.options.dateProperty}}: [bold]{valueY}[/]`
      series.tooltip.pointerOrientation = 'vertical'
      series.tooltip.background.cornerRadius = 20
      series.tooltip.background.fillOpacity = 0.5
      series.tooltip.label.padding(12, 12, 12, 12)

      let scrollbarX = new am4charts.XYChartScrollbar()
      scrollbarX.series.push(series)
      this.chart.scrollbarX = scrollbarX
      this.chart.cursor = new am4charts.XYCursor()
      this.chart.cursor.xAxis = dateAxis
      this.chart.cursor.snapToSeries = series
    })
  }
}
