import { Component, OnInit } from '@angular/core';
import { NbDialogRef } from '@nebular/theme';

@Component({
  selector: 'ngx-confirm-refresh-dialog',
  templateUrl: './confirm-refresh-dialog.component.html',
  styleUrls: ['./confirm-refresh-dialog.component.scss']
})
export class ConfirmRefreshDialogComponent implements OnInit {

  constructor(
    public dialogRef: NbDialogRef<ConfirmRefreshDialogComponent>
  ) { }

  ngOnInit(): void {
  }

  submit() {
    window.location.reload();
  }
}
