import { Component, OnInit} from '@angular/core';
import { NbToastrService } from '@nebular/theme';
import { BaseComponent } from 'app/@core/base/base.component';
import { AuditEvent, EnvelopeStatusResponse } from 'app/@core/models/envelope-status.model';
import { IntegrationObject } from 'app/@core/models/integration.model';
import { DocusignService } from 'app/@core/services/docusign.service';
import { IntegrationService } from 'app/@core/services/integration.service';
import { LayoutService } from 'app/@core/utils';
import { Subscription } from 'rxjs';
import { take } from 'rxjs/operators';

@Component({
  selector: 'ngx-proposal-status-history',
  templateUrl: './proposal-status-history.component.html',
  styleUrls: ['./proposal-status-history.component.scss']
})
export class ProposalStatusHistoryComponent extends BaseComponent implements OnInit {
  public isSidebarVisible: boolean = false;
  public subscription: Subscription;
  public envelopeId: string;
  public statuses: AuditEvent[] = [];
  public accountId: string;
  public isLoading: boolean = true;

    constructor(
      private layoutService: LayoutService,
      private docusignService: DocusignService,
      private _toastrService: NbToastrService,
      private _integrationService: IntegrationService
    ) {
    super();
    }

    ngOnInit() {
      this.subscription = this.layoutService.statusHistorySidenavVisible$.subscribe((visible: boolean) => {
        this.isSidebarVisible = visible
        if (this.isSidebarVisible) {
          this.subscription = this.layoutService.currentEnvelopeId$.subscribe(
            (id: string | null) => (this.envelopeId = id)
          )
          this.getDocusignIntegration();
        }
      })
    }

    getStatusHistory() {
      this.isLoading = true;
      this.docusignService.getEnvelopeStatusesHistory(this.envelopeId, this.accountId).pipe(take(1)).subscribe({
        next: (response: EnvelopeStatusResponse) => {
          this.statuses = response.HistoryData.AuditEvents;
          this.isLoading = false;
        },
        error: (error) => {
          this._toastrService.danger(error.error.Content);
          this.isLoading = false;
        }
        })
    }

    closeSidenav() {
      this.layoutService.closeStatusHistorySidenav();
      this.statuses = [];
    }

       /**
   * Function to get docusign integration
   */
   getDocusignIntegration() {
    this.isLoading = true;
    this._integrationService.getIntegration('docusign').pipe(take(1)).subscribe({
      next: (response: IntegrationObject) => {
        this.accountId = response.AccountId;
        this.getStatusHistory();
      },
      error: (error) => {
        this._toastrService.danger(error.error.Content);
        this.isLoading = false;
      }
    })
  }

  getStatusIcon(statusValue: string): string {
    switch (statusValue) {
      case 'created':
        return 'file-add-outline';
      case 'sent':
        return 'paper-plane-outline';
      case 'delivered':
        return 'checkmark-circle-outline';
      case 'completed':
        return 'checkmark-square-outline';
      case 'voided':
        return 'close-circle-outline';
      case 'declined':
        return 'close-outline';
      case 'correct':
        return 'edit-outline';
      case 'scheduled':
        return 'calendar-outline';
      case 'waiting for others':
        return 'clock-outline';
      case 'acknowledged':
        return 'done-all-outline';
      case 'expired':
        return 'alert-circle-outline';
      case 'template':
        return 'file-text-outline';
      case 'signed':
        return 'edit-2-outline';
      case 'resent':
        return 'refresh-outline';
      case 'deleted':
        return 'trash-2-outline';
      default:
        return '';
    }
  }

  getActionBadgeClass(actionValue: string): string {
    switch (actionValue) {
      case 'Registered':
      case 'Archive Delivered':
      case 'Signed':
      case 'Delivered':
      case 'Completed':
      case 'Completed with Errors':
        return 'badge-success';
      case 'Sent Invitations':
      case 'Printable Copy Attached to Email':
      case 'Reassign':
      case 'Sent':
      case 'Resent':
        return 'badge-primary';
      case 'Opened':
      case 'Authorized':
      case 'Session Started':
        return 'badge-info';
      case 'Viewed In-Session':
      case 'Viewed':
      case 'Created':
      case 'Corrected':
      case 'Draft':
      case 'Document Viewed':
        return 'badge-warning';
      case 'Declined':
      case 'Voided':
      case 'Expired':
      case 'Decline to Sign':
      case 'AutoVoid':
        return 'badge-danger';
      default:
        return 'badge-secondary';
    }
  }

  getStatusBadgeClass(statusValue: string): string {
    switch (statusValue) {
      case 'created':
      case 'correct':
      case 'template':
        return 'badge-light-warning';
      case 'sent':
      case 'resent':
        return 'badge-light-info';
      case 'delivered':
      case 'scheduled':
        return 'badge-light-primary';
      case 'completed':
      case 'acknowledged':
      case 'signed':
        return 'badge-light-success';
      case 'voided':
      case 'expired':
      case 'deleted':
      case 'declined':
        return 'badge-light-danger';
      case 'waiting for others':
        return 'badge-light-secondary';
      default:
        return 'badge-light-secondary';
    }
  }
}
