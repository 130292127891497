<nb-card>
  <nb-card-header>
    Would you like to proceed?
  </nb-card-header>
  <nb-card-body>
    <div class="p-2">
      <p class="m-0" *ngIf="!timeLapsed else timeExpired">{{ message_1 }} <b>{{ minutes > 0 ? (minutes + ' min and') : "" }} {{ seconds }} seconds</b>. {{ message_2 }}</p>
      <p #timeExpired>
        Looks like you are next in line. Click to proceed your request.
      </p>
    </div>
  </nb-card-body>
  <nb-card-footer>
    <button class="cancel" size="tiny" nbButton status="danger" (click)="proceed(false)">Cancel</button>
    <button class="ml-1" nbButton size="tiny" status="success" (click)="proceed(true)">Proceed</button>
  </nb-card-footer>
</nb-card>
