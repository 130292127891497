import { DatePipe } from '@angular/common';
import { Component, Input, OnInit } from '@angular/core';
import { NbDialogRef } from '@nebular/theme';
import { BaseComponent } from 'app/@core/base/base.component';
import { ContactObject } from 'app/@core/models/lead.model';
import { TranslateService } from 'app/@core/services';

@Component({
  selector: 'ngx-proposal-signer-details',
  templateUrl: './proposal-signer-details.component.html',
  styleUrls: ['./proposal-signer-details.component.scss']
})
export class ProposalSignerDetailsComponent extends BaseComponent implements OnInit {

  /**
   * Inputs/Outputs
   */
  @Input() signer: ContactObject;
  @Input() signedDate: string | Date;
  @Input() isCustomConverted: boolean;
  @Input() page: string;

  /**
   * Public varibles
   */
  signerDetails: ContactObject;
  _signedDate: string | Date;

  // -----------------------------------------------------------------------------------------------------
  // @ Lifecycle hooks
  // -----------------------------------------------------------------------------------------------------
  constructor(translateService: TranslateService,
              public dialogRef: NbDialogRef<ProposalSignerDetailsComponent>,
              private _datePipe: DatePipe) {
    super();
  }

  // -----------------------------------------------------------------------------------------------------
  // @ Lifecycle hooks
  // -----------------------------------------------------------------------------------------------------

  /**
   * On Init
   */
  ngOnInit() {
    this._mapSignerDetails();
  }

  // -----------------------------------------------------------------------------------------------------
  // @ Private methods
  // -----------------------------------------------------------------------------------------------------

  /**
   * Function to map signer details
   */
  private _mapSignerDetails() {
    var fullName = this.signer.FullName.split(" ");
    this.signerDetails = this.signer;
    this.signerDetails.FirstName = this.signer.FirstName || fullName[0];
    this.signerDetails.LastName = this.signer.LastName || fullName[1];
    this.signerDetails.Email = this.signer.Email;
    this.signerDetails.Position = this.signer.Position;
    this._signedDate = this._datePipe.transform(this.signedDate, `MM/dd/yyyy 'at' h:mm aa`);
  }
}
